module.exports = [
  -44.8969802856, -105.725852966,
  -64.8287887573, -120.983505249,
  -102.434616089, -121.130126953,
  -122.484794617, -106.028373718,
  -147.183044434, -84.6390609741,
  -164.994232178, -66.9519119263,
  -170.221359253, -29.7108535767,
  -157.967605591, -7.80380868912,
  -157.967605591, 7.80380868912,
  -170.221359253, 29.7108535767,
  -164.994232178, 66.9519119263,
  -147.183044434, 84.6390609741,
  -122.484794617, 106.028373718,
  -102.434616089, 121.130126953,
  -64.8287887573, 120.983505249,
  -44.8969802856, 105.725852966,
  44.8969802856, 105.725852966,
  64.8287963867, 120.983505249,
  102.434616089, 121.130111694,
  122.484794617, 106.028358459,
  147.183044434, 84.6390380859,
  164.994232178, 66.9518966675,
  170.221359253, 29.7108440399,
  157.967605591, 7.80380249023,
  157.967636108, -7.80382871628,
  170.221359253, -29.7108783722,
  164.994232178, -66.9519348145,
  147.183044434, -84.6390762329,
  122.484794617, -106.028373718,
  102.434608459, -121.130126953,
  64.8287811279, -120.983505249,
  44.8969612122, -105.725852966

]
