module.exports = [
  -29.5703468323, -101.380554199,
  -34.8948669434, -118.125267029,
  -55.470413208, -133.875671387,
  -73.0244674683, -134.644332886,
  -94.1326599121, -134.726638794,
  -111.692176819, -134.09487915,
  -132.389923096, -118.505401611,
  -137.844848633, -101.802711487,
  -140.806442261, -99.2378997803,
  -158.117080688, -96.2250137329,
  -176.503494263, -77.9666290283,
  -179.637268066, -60.6774673462,
  -182.571258545, -39.774017334,
  -184.318191528, -22.2902011871,
  -171.668716431, 0.324370503426,
  -155.856384277, 7.98638486862,
  -155.856384277, -7.98638486862,
  -171.668716431, -0.324370503426,
  -184.318191528, 22.2902011871,
  -182.571258545, 39.774017334,
  -179.637268066, 60.6774673462,
  -176.503494263, 77.9666290283,
  -158.117080688, 96.2250137329,
  -140.806442261, 99.2378997803,
  -137.844848633, 101.802711487,
  -132.389923096, 118.505401611,
  -111.692176819, 134.09487915,
  -94.1326599121, 134.726638794,
  -73.0244674683, 134.644332886,
  -55.470413208, 133.875671387,
  -34.8948669434, 118.125267029,
  -29.5703468323, 101.380554199,
  29.5703468323, 101.380554199,
  34.8948669434, 118.125267029,
  55.4704170227, 133.875671387,
  73.0244750977, 134.644332886,
  94.1326599121, 134.726623535,
  111.692184448, 134.094863892,
  132.389923096, 118.505371094,
  137.844848633, 101.802696228,
  140.806442261, 99.2378845215,
  158.117080688, 96.2249908447,
  176.503494263, 77.9666137695,
  179.637268066, 60.6774482727,
  182.571258545, 39.7740058899,
  184.318191528, 22.290189743,
  171.668716431, -0.324375510216,
  155.856384277, -7.98639011383,
  155.856430054, 7.98636484146,
  171.668731689, 0.324347704649,
  184.318222046, -22.2902240753,
  182.571258545, -39.7740440369,
  179.637268066, -60.6774978638,
  176.503494263, -77.9666595459,
  158.117080688, -96.2250289917,
  140.806442261, -99.2379150391,
  137.844833374, -101.802711487,
  132.389923096, -118.505401611,
  111.692176819, -134.09487915,
  94.1326522827, -134.726638794,
  73.0244598389, -134.644332886,
  55.4704017639, -133.875671387,
  34.8948478699, -118.125267029,
  29.5703277588, -101.380554199

]
