module.exports = [
  -83.8091583252, -75.5407028198,
  -122.533172607, -42.0047264099,
  -122.533172607, 42.0047264099,
  -83.8091583252, 75.5407028198,
  83.8091583252, 75.5407028198,
  122.533172607, 42.0047149658,
  122.533172607, -42.004737854,
  83.8091583252, -75.5407104492

]
