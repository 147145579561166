module.exports = [
  -21.9565048218, -92.8297424316,
  -18.6016292572, -104.662971497,
  -24.0448093414, -121.780838013,
  -33.6180114746, -129.503173828,
  -44.8203659058, -138.078491211,
  -54.7740287781, -145.30380249,
  -72.7192764282, -146.089584351,
  -83.2663803101, -139.761947632,
  -83.8511428833, -139.76423645,
  -94.3485946655, -146.173919678,
  -112.299438477, -145.528076172,
  -122.309120178, -138.380599976,
  -133.578018188, -129.89289856,
  -143.211135864, -122.24546051,
  -148.787643433, -105.170555115,
  -145.525131226, -93.3115234375,
  -131.305160522, -105.62638092,
  -142.576477051, -110.549568176,
  -160.272903442, -107.469543457,
  -169.218063354, -99.0277175903,
  -179.228561401, -89.0869293213,
  -187.732681274, -80.2009735107,
  -190.936294556, -62.526512146,
  -186.09197998, -51.2210655212,
  -186.17326355, -50.6419830322,
  -193.942733765, -41.1069984436,
  -195.728591919, -23.2335453033,
  -189.999328613, -12.3497943878,
  -183.112319946, -0.0372834689915,
  -176.836791992, 10.5409173965,
  -160.672103882, 18.3736839294,
  -148.48097229, 16.7436771393,
  -148.48097229, -16.7436771393,
  -160.672103882, -18.3736839294,
  -176.836791992, -10.5409173965,
  -183.112319946, 0.0372834689915,
  -189.999328613, 12.3497943878,
  -195.728591919, 23.2335453033,
  -193.942733765, 41.1069984436,
  -186.17326355, 50.6419830322,
  -186.09197998, 51.2210655212,
  -190.936294556, 62.526512146,
  -187.732681274, 80.2009735107,
  -179.228561401, 89.0869293213,
  -169.218063354, 99.0277175903,
  -160.272903442, 107.469543457,
  -142.576477051, 110.549568176,
  -131.305160522, 105.62638092,
  -145.525131226, 93.3115234375,
  -148.787643433, 105.170555115,
  -143.211135864, 122.24546051,
  -133.578018188, 129.89289856,
  -122.309120178, 138.380599976,
  -112.299438477, 145.528076172,
  -94.3485946655, 146.173919678,
  -83.8511428833, 139.76423645,
  -83.2663803101, 139.761947632,
  -72.7192764282, 146.089584351,
  -54.7740287781, 145.30380249,
  -44.8203659058, 138.078491211,
  -33.6180114746, 129.503173828,
  -24.0448093414, 121.780838013,
  -18.6016292572, 104.662971497,
  -21.9565048218, 92.8297424316,
  21.9565067291, 92.8297424316,
  18.6016292572, 104.662971497,
  24.0448093414, 121.780838013,
  33.6180114746, 129.503173828,
  44.8203735352, 138.078491211,
  54.7740287781, 145.30380249,
  72.7192840576, 146.089584351,
  83.2663955688, 139.761947632,
  83.8511428833, 139.764221191,
  94.3485946655, 146.173904419,
  112.299438477, 145.528076172,
  122.309120178, 138.380584717,
  133.578018188, 129.892883301,
  143.211135864, 122.245445251,
  148.787643433, 105.170539856,
  145.525131226, 93.3115158081,
  131.305160522, 105.626373291,
  142.576477051, 110.549552917,
  160.272903442, 107.469528198,
  169.218063354, 99.0276947021,
  179.228561401, 89.0869064331,
  187.732681274, 80.200958252,
  190.936294556, 62.5264930725,
  186.09197998, 51.2210464478,
  186.17326355, 50.6419639587,
  193.942733765, 41.1069831848,
  195.728591919, 23.2335319519,
  189.999328613, 12.3497810364,
  183.112319946, 0.0372791923583,
  176.836791992, -10.5409212112,
  160.672103882, -18.3736896515,
  148.48097229, -16.7436828613,
  148.481002808, 16.7436580658,
  160.672134399, 18.3736610413,
  176.836837769, 10.5408945084,
  183.112335205, -0.0373085066676,
  189.999359131, -12.3498182297,
  195.728622437, -23.2335720062,
  193.942733765, -41.1070213318,
  186.17326355, -50.6420059204,
  186.09197998, -51.2210884094,
  190.936279297, -62.5265426636,
  187.732681274, -80.2010040283,
  179.228546143, -89.0869674683,
  169.218063354, -99.0277328491,
  160.272903442, -107.469566345,
  142.576477051, -110.549583435,
  131.305160522, -105.626411438,
  145.525131226, -93.3115234375,
  148.787628174, -105.170555115,
  143.211135864, -122.24546051,
  133.578018188, -129.89289856,
  122.309120178, -138.380599976,
  112.299438477, -145.528076172,
  94.3485870361, -146.173904419,
  83.8511352539, -139.76423645,
  83.2663726807, -139.761947632,
  72.7192687988, -146.089584351,
  54.7740097046, -145.30380249,
  44.8203544617, -138.078475952,
  33.6179962158, -129.503173828,
  24.0447921753, -121.780838013,
  18.6016120911, -104.662956238,
  21.956489563, -92.8297424316

]
