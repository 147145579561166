module.exports = [
  -30.6561183929, -77.3625946045,
  -30.4096660614, -75.3101425171,
  -28.6009483337, -72.959022522,
  -26.6803092957, -72.1945343018,
  -24.6395435333, -71.3926620483,
  -22.7122211456, -70.6451873779,
  -19.7867832184, -71.1361160278,
  -18.2090454102, -72.4718017578,
  -18.6047611237, -72.4029159546,
  -16.6685009003, -71.6789093018,
  -13.7492437363, -72.205329895,
  -12.1878423691, -73.5600662231,
  -10.5380506516, -75.0043411255,
  -8.9886932373, -76.3728408813,
  -8.08077812195, -79.1968231201,
  -8.54233074188, -81.2118301392,
  -11.9662895203, -78.1630477905,
  -10.0181016922, -77.4717636108,
  -7.10813045502, -78.0473175049,
  -5.56978225708, -79.4281845093,
  -3.9445669651, -80.900062561,
  -2.41849398613, -82.2944793701,
  -1.5583024025, -85.1333694458,
  -2.0537507534, -87.1403121948,
  -2.16789102554, -86.7552032471,
  -0.658864498138, -88.1680526733,
  0.166787564754, -91.0171737671,
  -0.352997660637, -93.0179595947,
  -0.913708627224, -95.1377029419,
  -1.45117068291, -97.1338043213,
  -3.57755637169, -99.2020568848,
  -5.58778810501, -99.6839981079,
  -8.26595497131, -90.2376556396,
  -6.30190181732, -89.5928344727,
  -3.40641784668, -90.2373199463,
  -1.90129446983, -91.6543273926,
  -0.31148853898, -93.1643753052,
  1.18104255199, -94.5946426392,
  1.97357940674, -97.4531402588,
  1.43061339855, -99.4477539062,
  1.32565021515, -99.0600585938,
  2.80070161819, -100.508331299,
  3.55846476555, -103.376251221,
  2.99131536484, -105.364128113,
  2.38042664528, -107.469963074,
  1.79571628571, -109.452735901,
  -0.379182755947, -111.469917297,
  -2.40029168129, -111.903991699,
  -1.08592998981, -107.511856079,
  0.364502757788, -108.984786987,
  1.07382321358, -111.865081787,
  0.473251253366, -113.843109131,
  -0.17304174602, -115.938354492,
  -0.791086316109, -117.910995483,
  -2.99967312813, -119.891227722,
  -5.02781152725, -120.291168213,
  -4.72712898254, -120.024856567,
  -5.36908435822, -121.989845276,
  -7.60155773163, -123.943115234,
  -9.63440227509, -124.318397522,
  -11.7923583984, -124.706916809,
  -13.8284435272, -125.064201355,
  -16.6019859314, -124.01222229,
  -17.8889732361, -122.394523621,
  -13.2218971252, -107.717399597,
  -11.2369432449, -107.140106201,
  -8.36519241333, -107.883232117,
  -6.90940237045, -109.350883484,
  -5.37216329575, -110.914413452,
  -3.92941451073, -112.394874573,
  -3.23508858681, -115.278816223,
  -3.84594368935, -117.253707886,
  -3.93758821487, -116.862609863,
  -2.51292419434, -118.360496521,
  -1.85367393494, -121.252655029,
  -2.48846793175, -123.219970703,
  -3.17100906372, -125.303688049,
  -3.823179245, -127.265304565,
  -6.06578493118, -129.206939697,
  -8.10055541992, -129.571640015,
  -6.63677024841, -125.227012634,
  -5.2375535965, -126.748695374,
  -4.627140522, -129.651535034,
  -5.29500150681, -131.60786438,
  -6.01256465912, -133.679794312,
  -6.69770240784, -135.630142212,
  -8.97271251678, -137.533691406,
  -11.01334095, -137.864059448,
  -10.7037277222, -137.608184814,
  -11.4125022888, -139.550064087,
  -13.7104625702, -141.425857544,
  -15.7549524307, -141.731399536,
  -17.9249324799, -142.045913696,
  -19.9720458984, -142.333374023,
  -22.7079925537, -141.187164307,
  -23.9389076233, -139.526397705,
  -16.2967128754, -133.361724854,
  -14.9340248108, -134.91619873,
  -14.3927173615, -137.832733154,
  -15.1068439484, -139.772674561,
  -15.8734054565, -141.826950073,
  -16.6046638489, -143.760482788,
  -18.9242343903, -145.609481812,
  -20.9721317291, -145.891281128,
  -20.6565322876, -145.642837524,
  -21.4112186432, -147.567352295,
  -23.7530727386, -149.388031006,
  -25.8042411804, -149.644958496,
  -27.9810791016, -149.907836914,
  -30.0344390869, -150.146606445,
  -32.7423973083, -148.935745239,
  -33.9335250854, -147.24621582,
  -29.377532959, -146.734939575,
  -30.1645469666, -148.646453857,
  -32.5367546082, -150.427429199,
  -34.5919647217, -150.649734497,
  -36.7729225159, -150.875915527,
  -38.8300132751, -151.080032349,
  -41.5171852112, -149.823699951,
  -42.6796722412, -148.114334106,
  -42.3168487549, -148.286651611,
  -44.3762664795, -148.465774536,
  -47.0479812622, -147.1769104,
  -48.1896247864, -145.453567505,
  -49.3924484253, -143.620269775,
  -50.5188064575, -141.886886597,
  -50.637424469, -138.922912598,
  -49.6531867981, -137.105072021,
  -33.2960319519, -124.583808899,
  -31.2842826843, -124.108261108,
  -28.4540538788, -124.996520996,
  -27.0748138428, -126.536323547,
  -25.6191043854, -128.176040649,
  -24.2535362244, -129.727981567,
  -23.7068214417, -132.643508911,
  -24.417350769, -134.584762573,
  -24.4889812469, -134.189529419,
  -23.1423606873, -135.75793457,
  -22.6310939789, -138.679885864,
  -23.3651504517, -140.612365723,
  -24.1528053284, -142.658660889,
  -24.903924942, -144.584564209,
  -27.2423973083, -146.409591675,
  -29.2930850983, -146.670303345,
  -27.6101856232, -142.405776978,
  -26.2901916504, -143.996658325,
  -25.8282413483, -146.926803589,
  -26.5947608948, -148.84664917,
  -27.4167995453, -150.879379272,
  -28.2002696991, -152.792358398,
  -30.5691699982, -154.577713013,
  -32.6239585876, -154.803848267,
  -32.3017272949, -154.564025879,
  -33.1083717346, -156.467346191,
  -35.4987792969, -158.2237854,
  -37.5561714172, -158.424942017,
  -39.7393379211, -158.628646851,
  -41.7984199524, -158.811599731,
  -44.4725151062, -157.527694702,
  -45.6173553467, -155.80645752,
  -37.6714439392, -150.038513184,
  -36.3895988464, -151.660308838,
  -35.9973602295, -154.600616455,
  -36.8092498779, -156.501693726,
  -37.6793251038, -158.514312744,
  -38.5079994202, -160.408157349,
  -40.9186286926, -162.136764526,
  -42.9782104492, -162.314025879,
  -42.6503791809, -162.081939697,
  -43.5019950867, -163.965576172,
  -45.9334373474, -165.664749146,
  -47.9950180054, -165.817001343,
  -50.1824111938, -165.968811035,
  -52.2452583313, -166.102813721,
  -54.8881072998, -164.755767822,
  -55.9917488098, -163.007827759,
  -51.4156532288, -162.729003906,
  -52.2988853455, -164.597991943,
  -54.7586250305, -166.255966187,
  -56.822479248, -166.373443604,
  -59.0121192932, -166.488372803,
  -61.0769309998, -166.587585449,
  -63.6967086792, -165.196182251,
  -64.7707290649, -163.429885864,
  -64.417137146, -163.620437622,
  -66.4830169678, -163.694580078,
  -69.0856933594, -162.271484375,
  -70.1381835938, -160.492279053,
  -71.2462005615, -158.600158691,
  -72.2829208374, -156.811737061,
  -72.2506027222, -153.845535278,
  -71.1751556396, -152.080123901,
  -55.7886123657, -151.406387329,
  -54.5629730225, -153.071060181,
  -54.2715110779, -156.023025513,
  -55.14793396, -157.895248413,
  -56.0863227844, -159.876953125,
  -56.9792709351, -161.741348267,
  -59.4476013184, -163.386489868,
  -61.5120391846, -163.493225098,
  -61.1764602661, -163.272506714,
  -62.0919914246, -165.125900269,
  -64.580116272, -166.740966797,
  -66.6456985474, -166.822616577,
  -68.8370056152, -166.899520874,
  -70.9032287598, -166.962905884,
  -73.4984741211, -165.526275635,
  -74.5416946411, -163.741622925,
  -69.9587249756, -163.619430542,
  -70.9053573608, -165.457122803,
  -73.4203567505, -167.030029297,
  -75.4870223999, -167.076858521,
  -77.6793060303, -167.116836548,
  -79.746307373, -167.145370483,
  -82.3169708252, -165.66519165,
  -83.3299636841, -163.863235474,
  -82.9831085205, -164.065750122,
  -85.0502929688, -164.069213867,
  -87.6027908325, -162.557937622,
  -88.5938339233, -160.743789673,
  -89.6364898682, -158.814910889,
  -90.6114501953, -156.99206543,
  -90.4777145386, -154.028717041,
  -89.3425292969, -152.301101685,
  -80.9228820801, -157.352401733,
  -81.9128875732, -159.167114258,
  -84.464515686, -160.679855347,
  -86.5317077637, -160.677597046,
  -88.7243347168, -160.665496826,
  -90.7914276123, -160.644958496,
  -93.326210022, -159.104156494,
  -94.2961273193, -157.278640747,
  -93.9541778564, -157.489349365,
  -96.0208740234, -157.443695068,
  -98.5367660522, -155.872238159,
  -99.4844436646, -154.035079956,
  -100.481010437, -152.081954956,
  -101.412399292, -150.236480713,
  -101.208343506, -147.277160645,
  -100.032455444, -145.57699585,
  -97.9832382202, -149.678115845,
  -100.048866272, -149.597640991,
  -102.537918091, -147.984008789,
  -103.454490662, -146.131118774,
  -104.417999268, -144.161499023,
  -105.31816864, -142.300582886,
  -105.06426239, -139.345123291,
  -103.85987854, -137.665023804,
  -103.89667511, -138.064987183,
  -104.774177551, -136.193283081,
  -104.484390259, -133.241134644,
  -103.25970459, -131.575759888,
  -101.952896118, -129.815093994,
  -100.713531494, -128.160614014,
  -97.9717636108, -127.028373718,
  -95.9261474609, -127.326263428,
  -71.2887268066, -127.230224609,
  -69.2454986572, -126.916381836,
  -66.4949951172, -128.027206421,
  -65.2427597046, -129.671966553,
  -63.9222602844, -131.422409058,
  -62.6846160889, -133.078170776,
  -62.3718261719, -136.02796936,
  -63.2346954346, -137.906463623,
  -63.2746276855, -137.506774902,
  -62.0571899414, -139.177459717,
  -61.7802467346, -142.130828857,
  -62.6658668518, -143.998718262,
  -63.6139793396, -145.975799561,
  -64.5160903931, -147.835769653,
  -66.992477417, -149.468765259,
  -69.0574188232, -149.565353394,
  -67.0402374268, -145.448364258,
  -65.8511352539, -147.139312744,
  -65.6240081787, -150.096939087,
  -66.540977478, -151.949615479,
  -67.5222854614, -153.910461426,
  -68.4556121826, -155.754974365,
  -70.9591751099, -157.345977783,
  -73.0254440308, -157.407745361,
  -72.6851425171, -157.194366455,
  -73.6408004761, -159.027404785,
  -76.1634902954, -160.587921143,
  -78.2303619385, -160.624588013,
  -80.4228134155, -160.653778076,
  -82.489944458, -160.672149658,
  -85.0532913208, -159.179367065,
  -86.0574111938, -157.372436523,
  -77.6773986816, -152.255645752,
  -76.5287857056, -153.974349976,
  -76.3719558716, -156.936553955,
  -77.3326644897, -158.766937256,
  -78.3602600098, -160.703903198,
  -79.3371124268, -162.525726318,
  -81.8777542114, -164.056854248,
  -83.9449234009, -164.069519043,
  -83.5996398926, -163.864273071,
  -84.5985565186, -165.674087524,
  -87.1576004028, -167.174255371,
  -89.2247543335, -167.161834717,
  -91.4172897339, -167.138961792,
  -93.4842529297, -167.108261108,
  -96.0114364624, -165.555007935,
  -96.9723739624, -163.724761963,
  -92.3885879517, -163.811203003,
  -93.4178695679, -165.603942871,
  -96.0018310547, -167.060775757,
  -98.0684890747, -167.013504028,
  -100.260322571, -166.953689575,
  -102.32648468, -166.888153076,
  -104.827133179, -165.292541504,
  -105.757080078, -163.446334839,
  -105.419784546, -163.664459229,
  -107.484985352, -163.573822021,
  -109.966064453, -161.947967529,
  -110.873535156, -160.090591431,
  -111.827339172, -158.116241455,
  -112.718345642, -156.250915527,
  -112.449905396, -153.296737671,
  -111.237289429, -151.622573853,
  -95.8459625244, -152.176315308,
  -94.7567749023, -153.933288574,
  -94.7013320923, -156.899139404,
  -95.724067688, -158.695602417,
  -96.8172988892, -160.596298218,
  -97.8558883667, -162.383636475,
  -100.447395325, -163.82699585,
  -102.513778687, -163.768981934,
  -102.161689758, -163.575668335,
  -103.221908569, -165.350265503,
  -105.830749512, -166.762054443,
  -107.896270752, -166.678955078,
  -110.086738586, -166.581100464,
  -112.151451111, -166.479736328,
  -114.624046326, -164.840988159,
  -115.521835327, -162.978927612,
  -110.943687439, -163.222091675,
  -112.033668518, -164.978561401,
  -114.665931702, -166.346160889,
  -116.729759216, -166.228271484,
  -118.918266296, -166.093521118,
  -120.980979919, -165.957382202,
  -123.425590515, -164.277191162,
  -124.291862488, -162.400268555,
  -123.962234497, -162.629760742,
  -126.023139954, -162.468566895,
  -128.447174072, -160.758834839,
  -129.290588379, -158.871520996,
  -130.176345825, -156.865707397,
  -131.003036499, -154.97102356,
  -130.633712769, -152.027755737,
  -129.364547729, -150.396011353,
  -121.373924255, -156.101806641,
  -122.505310059, -157.83190918,
  -125.169303894, -159.136657715,
  -127.229766846, -158.96975708,
  -129.41444397, -158.783081055,
  -131.473342896, -158.597991943,
  -133.877380371, -156.860214233,
  -134.69883728, -154.963226318,
  -134.374740601, -155.200531006,
  -136.431243896, -154.990432739,
  -138.813995361, -153.223617554,
  -139.612365723, -151.316802979,
  -140.450210571, -149.290557861,
  -141.231674194, -147.376754761,
  -140.79258728, -144.443099976,
  -139.485046387, -142.841964722,
  -137.768936157, -147.09324646,
  -139.821594238, -146.848526001,
  -142.174224854, -145.041793823,
  -142.940322876, -143.121795654,
  -143.743927002, -141.081710815,
  -144.493026733, -139.155014038,
  -144.004562378, -136.229171753,
  -142.670211792, -134.650299072,
  -142.738769531, -135.046081543,
  -143.464401245, -133.110427856,
  -142.940444946, -130.190734863,
  -141.587036133, -128.628189087,
  -140.144134521, -126.977172852,
  -138.776947021, -125.42665863,
  -135.953735352, -124.516365051,
  -133.938323975, -124.976219177,
  -117.484054565, -137.369552612,
  -116.485671997, -139.179672241,
  -116.581176758, -142.144485474,
  -117.693984985, -143.886581421,
  -118.882469177, -145.729202271,
  -120.010635376, -147.461410522,
  -122.672225952, -148.771072388,
  -124.732971191, -148.608001709,
  -124.371513367, -148.432846069,
  -125.520645142, -150.1512146,
  -128.197937012, -151.428466797,
  -130.256561279, -151.240386963,
  -132.439208984, -151.03125,
  -134.496078491, -150.824966431,
  -136.882095337, -149.062545776,
  -137.684005737, -147.157226562,
  -133.124145508, -147.632980347,
  -134.302078247, -149.331741333,
  -137.000518799, -150.563674927,
  -139.055679321, -150.340927124,
  -141.234481812, -150.095031738,
  -143.287597656, -149.854095459,
  -145.643569946, -148.051727295,
  -146.413238525, -146.133163452,
  -146.095703125, -146.379165649,
  -148.145736694, -146.113327026,
  -150.479675293, -144.282470703,
  -151.225982666, -142.354705811,
  -152.008529663, -140.306442261,
  -152.737762451, -138.372146606,
  -152.219238281, -135.451477051,
  -150.868682861, -133.886428833,
  -143.178665161, -139.99130249,
  -144.396591187, -141.661621094,
  -147.123519897, -142.829116821,
  -149.172805786, -142.557632446,
  -151.345153809, -142.260040283,
  -153.391967773, -141.970458984,
  -155.704483032, -140.11265564,
  -156.428390503, -138.176345825,
  -156.116790771, -138.429794312,
  -158.159927368, -138.115371704,
  -160.449707031, -136.229598999,
  -161.150039673, -134.28465271,
  -161.883743286, -132.218414307,
  -162.566833496, -130.267333984,
  -161.9790802, -127.359809875,
  -160.591766357, -125.827270508,
  -159.094161987, -130.160354614,
  -161.131729126, -129.811523438,
  -163.389373779, -127.887443542,
  -164.05682373, -125.930953979,
  -164.755599976, -123.852622986,
  -165.405731201, -121.890327454,
  -164.769042969, -118.993125916,
  -163.356109619, -117.484176636,
  -163.444717407, -117.875953674,
  -164.070953369, -115.905891418,
  -163.399124146, -113.016639709,
  -161.967956543, -111.524963379,
  -160.442977905, -109.949493408,
  -158.998672485, -108.470535278,
  -156.132797241, -107.705055237,
  -154.1434021, -108.266838074,
  -149.361984253, -122.907112122,
  -150.636322021, -124.534805298,
  -153.401580811, -125.608383179,
  -155.440383911, -125.266975403,
  -157.601318359, -124.895294189,
  -159.637023926, -124.535873413,
  -161.884658813, -122.600067139,
  -162.541915894, -120.640151978,
  -162.239181519, -120.90411377,
  -164.270385742, -120.520004272,
  -166.494338989, -118.557052612,
  -167.127761841, -116.589279175,
  -167.790344238, -114.499137878,
  -168.406326294, -112.525848389,
  -167.719482422, -109.64012146,
  -166.280578613, -108.155914307,
  -164.932022095, -112.537689209,
  -166.956451416, -112.119392395,
  -169.147033691, -110.119232178,
  -169.747177124, -108.141067505,
  -170.374465942, -106.040061951,
  -170.957092285, -104.056671143,
  -170.221710205, -101.182929993,
  -168.757995605, -99.7231826782,
  -168.859954834, -100.111694336,
  -169.418441772, -98.1213760376,
  -168.648223877, -95.2567749023,
  -167.166885376, -93.8149185181,
  -165.588912964, -92.2925186157,
  -164.09487915, -90.8638305664,
  -161.20451355, -90.1967773438,
  -159.235488892, -90.8262634277,
  -161.839920044, -100.29322052,
  -163.85383606, -99.8269729614,
  -165.996292114, -97.7753601074,
  -166.549285889, -95.7835083008,
  -167.12651062, -93.6681900024,
  -167.661895752, -91.6715240479,
  -166.85848999, -88.8160476685,
  -165.360534668, -87.3914794922,
  -165.47164917, -87.7774734497,
  -165.982727051, -85.7744522095,
  -165.144729614, -82.9289550781,
  -163.629562378, -81.5226821899,
  -162.015869141, -80.0381698608,
  -160.488327026, -78.6453552246,
  -157.582931519, -78.0471343994,
  -155.62940979, -78.723197937,
  -159.029510498, -81.798576355,
  -159.506759644, -79.7872238159,
  -158.620895386, -76.9562454224,
  -157.082260132, -75.5756988525,
  -155.443786621, -74.1186218262,
  -153.892990112, -72.7517471313,
  -150.977920532, -72.2025756836,
  -149.036071777, -72.9114685059,
  -149.431243896, -72.9834213257,
  -147.863952637, -71.6354751587,
  -144.942443848, -71.1217575073,
  -143.009338379, -71.8541793823,
  -140.962402344, -72.6400985718,
  -139.035858154, -73.3896026611,
  -137.208862305, -75.7265396118,
  -136.946395874, -77.7770004272,
  -117.155502319, -94.9164199829,
  -115.088569641, -94.8832473755,
  -112.514602661, -96.3576660156,
  -111.497581482, -98.157371521,
  -110.427276611, -100.071052551,
  -109.426216125, -101.879684448,
  -109.517326355, -104.84463501,
  -110.627540588, -106.588394165,
  -110.612754822, -106.187011719,
  -109.633735657, -108.007659912,
  -109.76083374, -110.97127533,
  -110.89214325, -112.701431274,
  -112.100227356, -114.531272888,
  -113.246795654, -116.251350403,
  -115.922172546, -117.532546997,
  -117.98109436, -117.34753418,
  -115.422988892, -113.542984009,
  -114.47479248, -115.379882812,
  -114.651824951, -118.340942383,
  -115.812133789, -120.051773071,
  -117.050880432, -121.860977173,
  -118.226280212, -123.561485291,
  -120.922874451, -124.797424316,
  -122.978363037, -124.577735901,
  -122.612205505, -124.412597656,
  -123.808174133, -126.098701477,
  -126.519584656, -127.301803589,
  -128.572250366, -127.057167053,
  -130.748336792, -126.788047791,
  -132.79876709, -126.525245667,
  -135.135375977, -124.697868347,
  -135.884567261, -122.771217346,
  -126.886779785, -118.841072083,
  -125.982452393, -120.699966431,
  -126.229766846, -123.655982971,
  -127.430358887, -125.338798523,
  -128.711730957, -127.11807251,
  -129.927185059, -128.790206909,
  -132.652374268, -129.961730957,
  -134.702072144, -129.69329834,
  -134.332092285, -129.536911011,
  -135.567749023, -131.194137573,
  -138.306976318, -132.332519531,
  -140.353240967, -132.03918457,
  -142.522323608, -131.718490601,
  -144.565917969, -131.407073975,
  -146.858520508, -129.524719238,
  -147.561721802, -127.580810547,
  -143.03225708, -128.289596558,
  -144.295669556, -129.925750732,
  -147.05368042, -131.017807007,
  -149.094726562, -130.690032959,
  -151.258087158, -130.332824707,
  -153.296142578, -129.987014771,
  -155.556686401, -128.066299438,
  -156.227035522, -126.110794067,
  -155.92250061, -126.37272644,
  -157.956237793, -126.002204895,
  -160.193252563, -124.054161072,
  -160.839828491, -122.090690613,
  -161.516403198, -120.005012512,
  -162.145584106, -118.035888672,
  -161.478042603, -115.145645142,
  -160.049087524, -113.651847839,
  -144.875900269, -116.292701721,
  -144.035690308, -118.181442261,
  -144.383911133, -121.12727356,
  -145.641357422, -122.768043518,
  -146.982803345, -124.502464294,
  -148.254730225, -126.132041931,
  -151.018417358, -127.209732056,
  -153.057723999, -126.871353149,
  -152.682617188, -126.72769928,
  -153.974227905, -128.341705322,
  -156.750762939, -129.385742188,
  -158.785827637, -129.022613525,
  -160.942672729, -128.627929688,
  -162.97442627, -128.246826172,
  -165.201293945, -126.28717041,
  -165.837615967, -124.320350647,
  -161.335037231, -125.18359375,
  -162.653671265, -126.775604248,
  -165.447418213, -127.772712708,
  -167.47605896, -127.375343323,
  -169.625915527, -126.944351196,
  -171.650970459, -126.529052734,
  -173.844497681, -124.532142639,
  -174.44758606, -122.554878235,
  -174.152191162, -122.827079773,
  -176.172058105, -122.387229919,
  -178.341156006, -120.363830566,
  -178.920196533, -118.379371643,
  -179.525054932, -116.27180481,
  -180.086517334, -114.282310486,
  -179.320541382, -111.416572571,
  -177.84135437, -109.97252655,
  -170.700500488, -116.711547852,
  -172.05657959, -118.271812439,
  -174.873214722, -119.202270508,
  -176.891845703, -118.756851196,
  -179.030883789, -118.274940491,
  -181.045501709, -117.811676025,
  -183.191009521, -115.763237,
  -183.74697876, -113.772209167,
  -183.458145142, -114.051338196,
  -185.466995239, -113.563644409,
  -187.58744812, -111.489311218,
  -188.119186401, -109.491661072,
  -188.673812866, -107.370330811,
  -189.187866211, -105.36806488,
  -188.3540802, -102.521324158,
  -186.841003418, -101.112808228,
  -185.71875, -105.557907104,
  -187.719085693, -105.036437988,
  -189.804275513, -102.926651001,
  -190.302276611, -100.920341492,
  -190.821075439, -98.7899398804,
  -191.301315308, -96.7793045044,
  -190.419647217, -93.947013855,
  -188.883071899, -92.5641937256,
  -189.004776001, -92.9469833374,
  -189.46055603, -90.9306640625,
  -188.544555664, -88.1092910767,
  -186.99130249, -86.745223999,
  -185.337387085, -85.3056945801,
  -183.772109985, -83.9554214478,
  -180.851348877, -83.4373703003,
  -178.917160034, -84.1669158936,
  -164.300369263, -98.681930542,
  -163.557312012, -100.610969543,
  -164.054962158, -103.53528595,
  -165.394256592, -105.109947205,
  -166.822189331, -106.773887634,
  -168.175354004, -108.336639404,
  -170.990264893, -109.272338867,
  -173.009735107, -108.830657959,
  -172.627792358, -108.70627594,
  -173.999832153, -110.252479553,
  -176.825897217, -111.153923035,
  -178.83984375, -110.68775177,
  -180.973815918, -110.183853149,
  -182.983551025, -109.699867249,
  -185.107849121, -107.629470825,
  -185.643310547, -105.632827759,
  -181.190429688, -106.724021912,
  -182.588348389, -108.246894836,
  -185.429199219, -109.100563049,
  -187.435012817, -108.600517273,
  -189.560180664, -108.060714722,
  -191.56149292, -107.542945862,
  -193.650588989, -105.437034607,
  -194.152313232, -103.431640625,
  -193.871170044, -103.718513489,
  -195.866043091, -103.176483154,
  -197.929428101, -101.045349121,
  -198.40675354, -99.0340118408,
  -198.903579712, -96.898399353,
  -199.363113403, -94.8829269409,
  -198.452346802, -92.0598602295,
  -196.901611328, -90.6929244995,
  -190.112854004, -97.786491394,
  -191.546524048, -99.2757492065,
  -194.406829834, -100.061729431,
  -196.400192261, -99.5141830444,
  -198.511962891, -98.9240646362,
  -200.50038147, -98.3589172363,
  -202.538894653, -96.203994751,
  -202.992858887, -94.1872711182,
  -202.718597412, -94.4807281494,
  -204.700042725, -93.8914718628,
  -206.712234497, -91.7119522095,
  -207.141662598, -89.6898498535,
  -207.587661743, -87.5430374146,
  -207.999191284, -85.5172195435,
  -207.021652222, -82.7165756226,
  -205.438903809, -81.3868560791,
  -204.544219971, -85.8832931519,
  -206.515426636, -85.2607269287,
  -208.490600586, -83.0476074219,
  -208.885879517, -81.0185623169,
  -209.295639038, -78.8645324707,
  -209.672988892, -76.8320617676,
  -208.648361206, -74.048286438,
  -207.043426514, -72.7454299927,
  -207.184432983, -73.1215286255,
  -207.53704834, -71.0846328735,
  -206.478713989, -68.3135147095,
  -204.858062744, -67.0302352905,
  -203.133071899, -65.6766967773,
  -201.50112915, -64.4078140259,
  -198.557800293, -64.0390014648,
  -196.663253784, -64.8660049438,
  -193.916412354, -80.0203704834,
  -195.400192261, -81.4597167969,
  -198.285705566, -82.1474227905,
  -200.259170532, -81.532043457,
  -202.349517822, -80.8700561523,
  -204.317474365, -80.2372436523,
  -206.281082153, -78.0138702393,
  -206.665817261, -75.9827957153,
  -206.401779175, -76.2854614258,
  -208.361907959, -75.6287918091,
  -210.29838562, -73.3817443848,
  -210.658416748, -71.3461456299,
  -211.030715942, -69.185333252,
  -211.37272644, -67.1466293335,
  -210.300003052, -64.3810501099,
  -208.672668457, -63.1062278748,
  -207.932296753, -67.6306381226,
  -209.881072998, -66.9410247803,
  -211.779418945, -64.6616516113,
  -212.105072021, -62.6202774048,
  -212.440933228, -60.4534912109,
  -212.748535156, -58.4093170166,
  -211.629348755, -55.6622161865,
  -209.980804443, -54.4149971008,
  -210.13458252, -54.7860488892,
  -210.417358398, -52.73828125,
  -209.264862061, -50.0049705505,
  -207.601303101, -48.7778663635,
  -205.831008911, -47.4841079712,
  -204.156646729, -46.2717666626,
  -201.202423096, -46.003818512,
  -199.337249756, -46.8951301575,
  -203.204376221, -55.9201583862,
  -205.136245728, -55.184463501,
  -206.979919434, -52.860660553,
  -207.257034302, -50.812122345,
  -207.541351318, -48.6379852295,
  -207.800323486, -46.5870742798,
  -206.616210938, -43.8673210144,
  -204.938476562, -42.6596031189,
  -205.101043701, -43.0268974304,
  -205.335098267, -40.9729919434,
  -204.118057251, -38.2678222656,
  -202.42578125, -37.0805664062,
  -200.625289917, -35.8292121887,
  -198.922607422, -34.6569709778,
  -195.962844849, -34.4592552185,
  -194.119384766, -35.3945999146,
  -197.905944824, -37.9792289734,
  -198.10534668, -35.921672821,
  -196.84286499, -33.2373962402,
  -195.130844116, -32.078830719,
  -193.309494019, -30.8579998016,
  -191.587310791, -29.7146224976,
  -188.624633789, -29.5668201447,
  -186.797180176, -30.5331020355,
  -187.198455811, -30.5506725311,
  -185.462493896, -29.4282970428,
  -182.498260498, -29.3164787292,
  -180.682678223, -30.3048839569,
  -178.761550903, -31.361782074,
  -176.954788208, -32.3662071228,
  -175.462432861, -34.9298095703,
  -175.481155396, -36.9969177246,
  -172.056594849, -61.3953857422,
  -171.469528198, -63.3774719238,
  -172.198471069, -66.2528533936,
  -173.658905029, -67.7158584595,
  -175.21484375, -69.2607879639,
  -176.688171387, -70.7108306885,
  -179.568649292, -71.4193725586,
  -181.546524048, -70.8182678223,
  -181.155883789, -70.7247009277,
  -182.646713257, -72.1567382812,
  -185.535583496, -72.8302459717,
  -187.505996704, -72.2051696777,
  -189.593078613, -71.532913208,
  -191.557891846, -70.8904342651,
  -193.510574341, -68.6574325562,
  -193.885299683, -66.6244888306,
  -189.533493042, -68.0668182373,
  -191.048248291, -69.473526001,
  -193.948074341, -70.0982513428,
  -195.907669067, -69.4400482178,
  -197.983078003, -68.732711792,
  -199.936798096, -68.0572052002,
  -201.851547241, -65.7916107178,
  -202.191986084, -63.7526359558,
  -201.93460083, -64.0609817505,
  -203.879959106, -63.3618049622,
  -205.767059326, -61.0731315613,
  -206.082687378, -59.0301704407,
  -206.407897949, -56.8617706299,
  -206.705459595, -54.8161010742,
  -205.572753906, -52.0745315552,
  -203.91809082, -50.83543396,
  -197.715774536, -58.4471206665,
  -199.263504028, -59.8174591064,
  -202.177352905, -60.3731498718,
  -204.120773315, -59.6686058044,
  -206.178817749, -58.9121894836,
  -208.115936279, -58.1904830933,
  -209.976364136, -55.8800544739,
  -210.268264771, -53.8335762024,
  -210.018249512, -54.1479492188,
  -211.946456909, -53.4027709961,
  -213.778686523, -51.0699272156,
  -214.045715332, -49.0200500488,
  -214.319335938, -46.8445320129,
  -214.568222046, -44.7923736572,
  -213.370773315, -42.0784835815,
  -211.687103271, -40.8790245056,
  -211.153366089, -45.4324493408,
  -213.068740845, -44.6548690796,
  -214.86138916, -42.2914772034,
  -215.093841553, -40.2373924255,
  -215.330749512, -38.0575790405,
  -215.545028687, -36.0015182495,
  -214.301971436, -33.3081893921,
  -212.598342896, -32.1372795105,
  -212.76890564, -32.5009460449,
  -212.9581604, -30.4424343109,
  -211.682495117, -27.7643985748,
  -209.964813232, -26.6142654419,
  -208.137481689, -25.4024047852,
  -206.409667969, -24.2675094604,
  -203.446304321, -24.1342697144,
  -201.623626709, -25.1095275879,
  -200.092407227, -40.4345016479,
  -201.686096191, -41.7511177063,
  -204.617218018, -42.2068405151,
  -206.535415649, -41.4362487793,
  -208.566390991, -40.6099090576,
  -210.47769165, -39.8223800659,
  -212.258010864, -37.4496879578,
  -212.479782104, -35.3944244385,
  -212.24067688, -35.7171630859,
  -214.142272949, -34.9064788818,
  -215.89364624, -32.512348175,
  -216.090423584, -30.4545383453,
  -216.289505005, -28.2709407806,
  -216.468063354, -26.211473465,
  -215.178497314, -23.5401115417,
  -213.454833984, -22.3989295959,
  -213.077087402, -26.9679336548,
  -214.964767456, -26.1253185272,
  -216.675537109, -23.702009201,
  -216.837615967, -21.6411762238,
  -216.999847412, -19.4545326233,
  -217.143692017, -17.3923492432,
  -215.809265137, -14.7430992126,
  -214.066619873, -13.6311321259,
  -214.24949646, -13.9887590408,
  -214.368255615, -11.9249792099,
  -213.001800537, -9.29213047028,
  -211.245742798, -8.20140838623,
  -209.378082275, -7.05273914337,
  -207.612472534, -5.97759199142,
  -204.646286011, -5.94576501846,
  -202.858032227, -6.98277902603,
  -207.431625366, -15.6711730957,
  -209.298736572, -14.7839698792,
  -210.951507568, -12.3207187653,
  -211.064590454, -10.2566184998,
  -211.174850464, -8.06674098969,
  -211.269683838, -6.00172138214,
  -209.872711182, -3.38490724564,
  -208.104171753, -2.31463360786,
  -208.295471191, -2.66781449318,
  -208.365219116, -0.60179567337,
  -206.936584473, 1.99786043167,
  -205.155166626, 3.04657649994,
  -203.260696411, 4.15057134628,
  -201.470062256, 5.18348932266,
  -198.503982544, 5.14487218857,
  -196.740844727, 4.06568622589,
  -200.721237183, 1.79081940651,
  -200.756134033, 3.85772061348,
  -199.283905029, 6.43292951584,
  -197.485061646, 7.45147323608,
  -195.572280884, 8.52338218689,
  -193.76449585, 9.52597427368,
  -190.799484253, 9.43737316132,
  -189.054763794, 8.32862377167,
  -189.456176758, 8.34305858612,
  -187.636322021, 9.32362270355,
  -184.672607422, 9.19902038574,
  -182.941497803, 8.06916427612,
  -181.110671997, 6.86263036728,
  -179.38961792, 5.7175078392,
  -178.106155396, 3.04320049286,
  -178.289428711, 0.984146177769,
  -188.345535278, -16.9939746857,
  -190.004119873, -18.2278118134,
  -192.954650879, -18.5338363647,
  -194.831161499, -17.6666584015,
  -196.817474365, -16.7380599976,
  -198.686248779, -15.8543186188,
  -200.343566895, -13.3941354752,
  -200.460479736, -11.3302497864,
  -200.238067627, -11.664732933,
  -202.095977783, -10.7583618164,
  -203.72328186, -8.27823352814,
  -203.815109253, -6.21307849884,
  -203.902877808, -4.02218103409,
  -203.976425171, -1.95629489422,
  -202.552627563, 0.646004974842,
  -200.773147583, 1.6980214119,
  -200.628326416, -2.88428544998,
  -202.470687866, -1.9467304945,
  -204.055953979, 0.560472309589,
  -204.112991333, 2.62688159943,
  -204.163787842, 4.81894540787,
  -204.202529907, 6.88577795029,
  -202.735061646, 9.46371173859,
  -200.938095093, 10.4855880737,
  -201.138916016, 10.1377277374,
  -201.152557373, 12.2048788071,
  -199.653900146, 14.7648000717,
  -197.844665527, 15.7647781372,
  -195.920944214, 16.816947937,
  -194.102935791, 17.8008842468,
  -191.139007568, 17.6817722321,
  -189.405792236, 16.5551300049,
  -194.415481567, 8.11065483093,
  -196.235061646, 9.09169387817,
  -197.760345459, 11.6358327866,
  -197.768280029, 13.7030134201,
  -197.76701355, 15.8956680298,
  -197.75668335, 17.9628372192,
  -196.228408813, 20.505197525,
  -194.407684326, 21.4841136932,
  -194.616714478, 21.1411209106,
  -194.581268311, 23.2080135345,
  -193.022232056, 25.731628418,
  -191.189758301, 26.6883602142,
  -189.241592407, 27.6945514679,
  -187.400741577, 28.6350364685,
  -184.440460205, 28.4455795288,
  -182.73449707, 27.2780971527,
  -186.825454712, 25.2086639404,
  -186.755172729, 27.2746620178,
  -185.153823853, 29.7716445923,
  -183.305496216, 30.6973590851,
  -181.340637207, 31.6705703735,
  -179.484191895, 32.5799026489,
  -176.527511597, 32.3405799866,
  -174.84147644, 31.1445102692,
  -175.241638184, 31.1793441772,
  -173.374282837, 32.0660591125,
  -170.420715332, 31.7908477783,
  -168.749343872, 30.5743923187,
  -166.98223877, 29.2762813568,
  -165.321685791, 28.0450897217,
  -164.175918579, 25.3089504242,
  -164.463729858, 23.2618865967,
  -178.323608398, 16.5459575653,
  -180.108581543, 17.5886459351,
  -181.545959473, 20.1834564209,
  -181.483215332, 22.2497005463,
  -181.406951904, 24.4410247803,
  -181.325927734, 26.5066318512,
  -179.711608887, 28.9952468872,
  -177.85848999, 29.9113292694,
  -178.07913208, 29.5756855011,
  -177.973022461, 31.6401557922,
  -176.328582764, 34.1089820862,
  -174.46446228, 35.0024909973,
  -172.483062744, 35.9414749146,
  -170.611114502, 36.8184661865,
  -167.6590271, 36.5278892517,
  -165.993988037, 35.3027534485,
  -170.153335571, 33.374420166,
  -170.012451172, 35.4368095398,
  -168.326660156, 37.8775634766,
  -166.447738647, 38.7395324707,
  -164.450805664, 39.6449966431,
  -162.564346313, 40.4903068542,
  -159.61756897, 40.1500205994,
  -157.973419189, 38.8969955444,
  -158.372146606, 38.9454917908,
  -156.475570679, 39.7678375244,
  -153.533157349, 39.3917884827,
  -151.904342651, 38.118888855,
  -150.182632446, 36.7611083984,
  -148.565155029, 35.4738540649,
  -147.513641357, 32.7001342773,
  -147.871276855, 30.6641120911,
  -157.60333252, 31.9653282166,
  -157.413497925, 34.0237922668,
  -155.670227051, 36.4238319397,
  -153.771392822, 37.240940094,
  -151.753463745, 38.0987167358,
  -149.847457886, 38.8989944458,
  -146.909622192, 38.4888305664,
  -145.295684814, 37.1971206665,
  -145.693145752, 37.2550697327,
  -143.777557373, 38.0321426392,
  -140.844909668, 37.5863304138,
  -139.246795654, 36.2751121521,
  -137.557846069, 34.8768310547,
  -135.971374512, 33.5515289307,
  -134.986022949, 30.7536258698,
  -135.391891479, 28.7266654968,
  -138.898529053, 31.6799716949,
  -136.970123291, 32.4246482849,
  -134.045394897, 31.9294700623,
  -132.469589233, 30.5915050507,
  -130.804458618, 29.1649589539,
  -129.240570068, 27.8131046295,
  -128.302490234, 24.9989929199,
  -128.742492676, 22.9791603088,
  -128.8671875, 23.3609809875,
  -127.319816589, 21.9902362823,
  -126.415992737, 19.1649398804,
  -126.880477905, 17.150598526,
  -127.382583618, 15.0162115097,
  -127.864860535, 13.0060596466,
  -129.93347168, 10.8800191879,
  -131.929672241, 10.3428888321,
  -131.929672241, -10.3428888321,
  -129.93347168, -10.8800191879,
  -127.864860535, -13.0060596466,
  -127.382583618, -15.0162115097,
  -126.880477905, -17.150598526,
  -126.415992737, -19.1649398804,
  -127.319816589, -21.9902362823,
  -128.8671875, -23.3609809875,
  -128.742492676, -22.9791603088,
  -128.302490234, -24.9989929199,
  -129.240570068, -27.8131046295,
  -130.804458618, -29.1649589539,
  -132.469589233, -30.5915050507,
  -134.045394897, -31.9294700623,
  -136.970123291, -32.4246482849,
  -138.898529053, -31.6799716949,
  -135.391891479, -28.7266654968,
  -134.986022949, -30.7536258698,
  -135.971374512, -33.5515289307,
  -137.557846069, -34.8768310547,
  -139.246795654, -36.2751121521,
  -140.844909668, -37.5863304138,
  -143.777557373, -38.0321426392,
  -145.693145752, -37.2550697327,
  -145.295684814, -37.1971206665,
  -146.909622192, -38.4888305664,
  -149.847457886, -38.8989944458,
  -151.753463745, -38.0987167358,
  -153.771392822, -37.240940094,
  -155.670227051, -36.4238319397,
  -157.413497925, -34.0237922668,
  -157.60333252, -31.9653282166,
  -147.871276855, -30.6641120911,
  -147.513641357, -32.7001342773,
  -148.565155029, -35.4738540649,
  -150.182632446, -36.7611083984,
  -151.904342651, -38.118888855,
  -153.533157349, -39.3917884827,
  -156.475570679, -39.7678375244,
  -158.372146606, -38.9454917908,
  -157.973419189, -38.8969955444,
  -159.61756897, -40.1500205994,
  -162.564346313, -40.4903068542,
  -164.450805664, -39.6449966431,
  -166.447738647, -38.7395324707,
  -168.326660156, -37.8775634766,
  -170.012451172, -35.4368095398,
  -170.153335571, -33.374420166,
  -165.993988037, -35.3027534485,
  -167.6590271, -36.5278892517,
  -170.611114502, -36.8184661865,
  -172.483062744, -35.9414749146,
  -174.46446228, -35.0024909973,
  -176.328582764, -34.1089820862,
  -177.973022461, -31.6401557922,
  -178.07913208, -29.5756855011,
  -177.85848999, -29.9113292694,
  -179.711608887, -28.9952468872,
  -181.325927734, -26.5066318512,
  -181.406951904, -24.4410247803,
  -181.483215332, -22.2497005463,
  -181.545959473, -20.1834564209,
  -180.108581543, -17.5886459351,
  -178.323608398, -16.5459575653,
  -164.463729858, -23.2618865967,
  -164.175918579, -25.3089504242,
  -165.321685791, -28.0450897217,
  -166.98223877, -29.2762813568,
  -168.749343872, -30.5743923187,
  -170.420715332, -31.7908477783,
  -173.374282837, -32.0660591125,
  -175.241638184, -31.1793441772,
  -174.84147644, -31.1445102692,
  -176.527511597, -32.3405799866,
  -179.484191895, -32.5799026489,
  -181.340637207, -31.6705703735,
  -183.305496216, -30.6973590851,
  -185.153823853, -29.7716445923,
  -186.755172729, -27.2746620178,
  -186.825454712, -25.2086639404,
  -182.73449707, -27.2780971527,
  -184.440460205, -28.4455795288,
  -187.400741577, -28.6350364685,
  -189.241592407, -27.6945514679,
  -191.189758301, -26.6883602142,
  -193.022232056, -25.731628418,
  -194.581268311, -23.2080135345,
  -194.616714478, -21.1411209106,
  -194.407684326, -21.4841136932,
  -196.228408813, -20.505197525,
  -197.75668335, -17.9628372192,
  -197.76701355, -15.8956680298,
  -197.768280029, -13.7030134201,
  -197.760345459, -11.6358327866,
  -196.235061646, -9.09169387817,
  -194.415481567, -8.11065483093,
  -189.405792236, -16.5551300049,
  -191.139007568, -17.6817722321,
  -194.102935791, -17.8008842468,
  -195.920944214, -16.816947937,
  -197.844665527, -15.7647781372,
  -199.653900146, -14.7648000717,
  -201.152557373, -12.2048788071,
  -201.138916016, -10.1377277374,
  -200.938095093, -10.4855880737,
  -202.735061646, -9.46371173859,
  -204.202529907, -6.88577795029,
  -204.163787842, -4.81894540787,
  -204.112991333, -2.62688159943,
  -204.055953979, -0.560472309589,
  -202.470687866, 1.9467304945,
  -200.628326416, 2.88428544998,
  -200.773147583, -1.6980214119,
  -202.552627563, -0.646004974842,
  -203.976425171, 1.95629489422,
  -203.902877808, 4.02218103409,
  -203.815109253, 6.21307849884,
  -203.72328186, 8.27823352814,
  -202.095977783, 10.7583618164,
  -200.238067627, 11.664732933,
  -200.460479736, 11.3302497864,
  -200.343566895, 13.3941354752,
  -198.686248779, 15.8543186188,
  -196.817474365, 16.7380599976,
  -194.831161499, 17.6666584015,
  -192.954650879, 18.5338363647,
  -190.004119873, 18.2278118134,
  -188.345535278, 16.9939746857,
  -178.289428711, -0.984146177769,
  -178.106155396, -3.04320049286,
  -179.38961792, -5.7175078392,
  -181.110671997, -6.86263036728,
  -182.941497803, -8.06916427612,
  -184.672607422, -9.19902038574,
  -187.636322021, -9.32362270355,
  -189.456176758, -8.34305858612,
  -189.054763794, -8.32862377167,
  -190.799484253, -9.43737316132,
  -193.76449585, -9.52597427368,
  -195.572280884, -8.52338218689,
  -197.485061646, -7.45147323608,
  -199.283905029, -6.43292951584,
  -200.756134033, -3.85772061348,
  -200.721237183, -1.79081940651,
  -196.740844727, -4.06568622589,
  -198.503982544, -5.14487218857,
  -201.470062256, -5.18348932266,
  -203.260696411, -4.15057134628,
  -205.155166626, -3.04657649994,
  -206.936584473, -1.99786043167,
  -208.365219116, 0.60179567337,
  -208.295471191, 2.66781449318,
  -208.104171753, 2.31463360786,
  -209.872711182, 3.38490724564,
  -211.269683838, 6.00172138214,
  -211.174850464, 8.06674098969,
  -211.064590454, 10.2566184998,
  -210.951507568, 12.3207187653,
  -209.298736572, 14.7839698792,
  -207.431625366, 15.6711730957,
  -202.858032227, 6.98277902603,
  -204.646286011, 5.94576501846,
  -207.612472534, 5.97759199142,
  -209.378082275, 7.05273914337,
  -211.245742798, 8.20140838623,
  -213.001800537, 9.29213047028,
  -214.368255615, 11.9249792099,
  -214.24949646, 13.9887590408,
  -214.066619873, 13.6311321259,
  -215.809265137, 14.7430992126,
  -217.143692017, 17.3923492432,
  -216.999847412, 19.4545326233,
  -216.837615967, 21.6411762238,
  -216.675537109, 23.702009201,
  -214.964767456, 26.1253185272,
  -213.077087402, 26.9679336548,
  -213.454833984, 22.3989295959,
  -215.178497314, 23.5401115417,
  -216.468063354, 26.211473465,
  -216.289505005, 28.2709407806,
  -216.090423584, 30.4545383453,
  -215.89364624, 32.512348175,
  -214.142272949, 34.9064788818,
  -212.24067688, 35.7171630859,
  -212.479782104, 35.3944244385,
  -212.258010864, 37.4496879578,
  -210.47769165, 39.8223800659,
  -208.566390991, 40.6099090576,
  -206.535415649, 41.4362487793,
  -204.617218018, 42.2068405151,
  -201.686096191, 41.7511177063,
  -200.092407227, 40.4345016479,
  -201.623626709, 25.1095275879,
  -203.446304321, 24.1342697144,
  -206.409667969, 24.2675094604,
  -208.137481689, 25.4024047852,
  -209.964813232, 26.6142654419,
  -211.682495117, 27.7643985748,
  -212.9581604, 30.4424343109,
  -212.76890564, 32.5009460449,
  -212.598342896, 32.1372795105,
  -214.301971436, 33.3081893921,
  -215.545028687, 36.0015182495,
  -215.330749512, 38.0575790405,
  -215.093841553, 40.2373924255,
  -214.86138916, 42.2914772034,
  -213.068740845, 44.6548690796,
  -211.153366089, 45.4324493408,
  -211.687103271, 40.8790245056,
  -213.370773315, 42.0784835815,
  -214.568222046, 44.7923736572,
  -214.319335938, 46.8445320129,
  -214.045715332, 49.0200500488,
  -213.778686523, 51.0699272156,
  -211.946456909, 53.4027709961,
  -210.018249512, 54.1479492188,
  -210.268264771, 53.8335762024,
  -209.976364136, 55.8800544739,
  -208.115936279, 58.1904830933,
  -206.178817749, 58.9121894836,
  -204.120773315, 59.6686058044,
  -202.177352905, 60.3731498718,
  -199.263504028, 59.8174591064,
  -197.715774536, 58.4471206665,
  -203.91809082, 50.83543396,
  -205.572753906, 52.0745315552,
  -206.705459595, 54.8161010742,
  -206.407897949, 56.8617706299,
  -206.082687378, 59.0301704407,
  -205.767059326, 61.0731315613,
  -203.879959106, 63.3618049622,
  -201.93460083, 64.0609817505,
  -202.191986084, 63.7526359558,
  -201.851547241, 65.7916107178,
  -199.936798096, 68.0572052002,
  -197.983078003, 68.732711792,
  -195.907669067, 69.4400482178,
  -193.948074341, 70.0982513428,
  -191.048248291, 69.473526001,
  -189.533493042, 68.0668182373,
  -193.885299683, 66.6244888306,
  -193.510574341, 68.6574325562,
  -191.557891846, 70.8904342651,
  -189.593078613, 71.532913208,
  -187.505996704, 72.2051696777,
  -185.535583496, 72.8302459717,
  -182.646713257, 72.1567382812,
  -181.155883789, 70.7247009277,
  -181.546524048, 70.8182678223,
  -179.568649292, 71.4193725586,
  -176.688171387, 70.7108306885,
  -175.21484375, 69.2607879639,
  -173.658905029, 67.7158584595,
  -172.198471069, 66.2528533936,
  -171.469528198, 63.3774719238,
  -172.056594849, 61.3953857422,
  -175.481155396, 36.9969177246,
  -175.462432861, 34.9298095703,
  -176.954788208, 32.3662071228,
  -178.761550903, 31.361782074,
  -180.682678223, 30.3048839569,
  -182.498260498, 29.3164787292,
  -185.462493896, 29.4282970428,
  -187.198455811, 30.5506725311,
  -186.797180176, 30.5331020355,
  -188.624633789, 29.5668201447,
  -191.587310791, 29.7146224976,
  -193.309494019, 30.8579998016,
  -195.130844116, 32.078830719,
  -196.84286499, 33.2373962402,
  -198.10534668, 35.921672821,
  -197.905944824, 37.9792289734,
  -194.119384766, 35.3945999146,
  -195.962844849, 34.4592552185,
  -198.922607422, 34.6569709778,
  -200.625289917, 35.8292121887,
  -202.42578125, 37.0805664062,
  -204.118057251, 38.2678222656,
  -205.335098267, 40.9729919434,
  -205.101043701, 43.0268974304,
  -204.938476562, 42.6596031189,
  -206.616210938, 43.8673210144,
  -207.800323486, 46.5870742798,
  -207.541351318, 48.6379852295,
  -207.257034302, 50.812122345,
  -206.979919434, 52.860660553,
  -205.136245728, 55.184463501,
  -203.204376221, 55.9201583862,
  -199.337249756, 46.8951301575,
  -201.202423096, 46.003818512,
  -204.156646729, 46.2717666626,
  -205.831008911, 47.4841079712,
  -207.601303101, 48.7778663635,
  -209.264862061, 50.0049705505,
  -210.417358398, 52.73828125,
  -210.13458252, 54.7860488892,
  -209.980804443, 54.4149971008,
  -211.629348755, 55.6622161865,
  -212.748535156, 58.4093170166,
  -212.440933228, 60.4534912109,
  -212.105072021, 62.6202774048,
  -211.779418945, 64.6616516113,
  -209.881072998, 66.9410247803,
  -207.932296753, 67.6306381226,
  -208.672668457, 63.1062278748,
  -210.300003052, 64.3810501099,
  -211.37272644, 67.1466293335,
  -211.030715942, 69.185333252,
  -210.658416748, 71.3461456299,
  -210.29838562, 73.3817443848,
  -208.361907959, 75.6287918091,
  -206.401779175, 76.2854614258,
  -206.665817261, 75.9827957153,
  -206.281082153, 78.0138702393,
  -204.317474365, 80.2372436523,
  -202.349517822, 80.8700561523,
  -200.259170532, 81.532043457,
  -198.285705566, 82.1474227905,
  -195.400192261, 81.4597167969,
  -193.916412354, 80.0203704834,
  -196.663253784, 64.8660049438,
  -198.557800293, 64.0390014648,
  -201.50112915, 64.4078140259,
  -203.133071899, 65.6766967773,
  -204.858062744, 67.0302352905,
  -206.478713989, 68.3135147095,
  -207.53704834, 71.0846328735,
  -207.184432983, 73.1215286255,
  -207.043426514, 72.7454299927,
  -208.648361206, 74.048286438,
  -209.672988892, 76.8320617676,
  -209.295639038, 78.8645324707,
  -208.885879517, 81.0185623169,
  -208.490600586, 83.0476074219,
  -206.515426636, 85.2607269287,
  -204.544219971, 85.8832931519,
  -205.438903809, 81.3868560791,
  -207.021652222, 82.7165756226,
  -207.999191284, 85.5172195435,
  -207.587661743, 87.5430374146,
  -207.141662598, 89.6898498535,
  -206.712234497, 91.7119522095,
  -204.700042725, 93.8914718628,
  -202.718597412, 94.4807281494,
  -202.992858887, 94.1872711182,
  -202.538894653, 96.203994751,
  -200.50038147, 98.3589172363,
  -198.511962891, 98.9240646362,
  -196.400192261, 99.5141830444,
  -194.406829834, 100.061729431,
  -191.546524048, 99.2757492065,
  -190.112854004, 97.786491394,
  -196.901611328, 90.6929244995,
  -198.452346802, 92.0598602295,
  -199.363113403, 94.8829269409,
  -198.903579712, 96.898399353,
  -198.40675354, 99.0340118408,
  -197.929428101, 101.045349121,
  -195.866043091, 103.176483154,
  -193.871170044, 103.718513489,
  -194.152313232, 103.431640625,
  -193.650588989, 105.437034607,
  -191.56149292, 107.542945862,
  -189.560180664, 108.060714722,
  -187.435012817, 108.600517273,
  -185.429199219, 109.100563049,
  -182.588348389, 108.246894836,
  -181.190429688, 106.724021912,
  -185.643310547, 105.632827759,
  -185.107849121, 107.629470825,
  -182.983551025, 109.699867249,
  -180.973815918, 110.183853149,
  -178.83984375, 110.68775177,
  -176.825897217, 111.153923035,
  -173.999832153, 110.252479553,
  -172.627792358, 108.70627594,
  -173.009735107, 108.830657959,
  -170.990264893, 109.272338867,
  -168.175354004, 108.336639404,
  -166.822189331, 106.773887634,
  -165.394256592, 105.109947205,
  -164.054962158, 103.53528595,
  -163.557312012, 100.610969543,
  -164.300369263, 98.681930542,
  -178.917160034, 84.1669158936,
  -180.851348877, 83.4373703003,
  -183.772109985, 83.9554214478,
  -185.337387085, 85.3056945801,
  -186.99130249, 86.745223999,
  -188.544555664, 88.1092910767,
  -189.46055603, 90.9306640625,
  -189.004776001, 92.9469833374,
  -188.883071899, 92.5641937256,
  -190.419647217, 93.947013855,
  -191.301315308, 96.7793045044,
  -190.821075439, 98.7899398804,
  -190.302276611, 100.920341492,
  -189.804275513, 102.926651001,
  -187.719085693, 105.036437988,
  -185.71875, 105.557907104,
  -186.841003418, 101.112808228,
  -188.3540802, 102.521324158,
  -189.187866211, 105.36806488,
  -188.673812866, 107.370330811,
  -188.119186401, 109.491661072,
  -187.58744812, 111.489311218,
  -185.466995239, 113.563644409,
  -183.458145142, 114.051338196,
  -183.74697876, 113.772209167,
  -183.191009521, 115.763237,
  -181.045501709, 117.811676025,
  -179.030883789, 118.274940491,
  -176.891845703, 118.756851196,
  -174.873214722, 119.202270508,
  -172.05657959, 118.271812439,
  -170.700500488, 116.711547852,
  -177.84135437, 109.97252655,
  -179.320541382, 111.416572571,
  -180.086517334, 114.282310486,
  -179.525054932, 116.27180481,
  -178.920196533, 118.379371643,
  -178.341156006, 120.363830566,
  -176.172058105, 122.387229919,
  -174.152191162, 122.827079773,
  -174.44758606, 122.554878235,
  -173.844497681, 124.532142639,
  -171.650970459, 126.529052734,
  -169.625915527, 126.944351196,
  -167.47605896, 127.375343323,
  -165.447418213, 127.772712708,
  -162.653671265, 126.775604248,
  -161.335037231, 125.18359375,
  -165.837615967, 124.320350647,
  -165.201293945, 126.28717041,
  -162.97442627, 128.246826172,
  -160.942672729, 128.627929688,
  -158.785827637, 129.022613525,
  -156.750762939, 129.385742188,
  -153.974227905, 128.341705322,
  -152.682617188, 126.72769928,
  -153.057723999, 126.871353149,
  -151.018417358, 127.209732056,
  -148.254730225, 126.132041931,
  -146.982803345, 124.502464294,
  -145.641357422, 122.768043518,
  -144.383911133, 121.12727356,
  -144.035690308, 118.181442261,
  -144.875900269, 116.292701721,
  -160.049087524, 113.651847839,
  -161.478042603, 115.145645142,
  -162.145584106, 118.035888672,
  -161.516403198, 120.005012512,
  -160.839828491, 122.090690613,
  -160.193252563, 124.054161072,
  -157.956237793, 126.002204895,
  -155.92250061, 126.37272644,
  -156.227035522, 126.110794067,
  -155.556686401, 128.066299438,
  -153.296142578, 129.987014771,
  -151.258087158, 130.332824707,
  -149.094726562, 130.690032959,
  -147.05368042, 131.017807007,
  -144.295669556, 129.925750732,
  -143.03225708, 128.289596558,
  -147.561721802, 127.580810547,
  -146.858520508, 129.524719238,
  -144.565917969, 131.407073975,
  -142.522323608, 131.718490601,
  -140.353240967, 132.03918457,
  -138.306976318, 132.332519531,
  -135.567749023, 131.194137573,
  -134.332092285, 129.536911011,
  -134.702072144, 129.69329834,
  -132.652374268, 129.961730957,
  -129.927185059, 128.790206909,
  -128.711730957, 127.11807251,
  -127.430358887, 125.338798523,
  -126.229766846, 123.655982971,
  -125.982452393, 120.699966431,
  -126.886779785, 118.841072083,
  -135.884567261, 122.771217346,
  -135.135375977, 124.697868347,
  -132.79876709, 126.525245667,
  -130.748336792, 126.788047791,
  -128.572250366, 127.057167053,
  -126.519584656, 127.301803589,
  -123.808174133, 126.098701477,
  -122.612205505, 124.412597656,
  -122.978363037, 124.577735901,
  -120.922874451, 124.797424316,
  -118.226280212, 123.561485291,
  -117.050880432, 121.860977173,
  -115.812133789, 120.051773071,
  -114.651824951, 118.340942383,
  -114.47479248, 115.379882812,
  -115.422988892, 113.542984009,
  -117.98109436, 117.34753418,
  -115.922172546, 117.532546997,
  -113.246795654, 116.251350403,
  -112.100227356, 114.531272888,
  -110.89214325, 112.701431274,
  -109.76083374, 110.97127533,
  -109.633735657, 108.007659912,
  -110.612754822, 106.187011719,
  -110.627540588, 106.588394165,
  -109.517326355, 104.84463501,
  -109.426216125, 101.879684448,
  -110.427276611, 100.071052551,
  -111.497581482, 98.157371521,
  -112.514602661, 96.3576660156,
  -115.088569641, 94.8832473755,
  -117.155502319, 94.9164199829,
  -136.946395874, 77.7770004272,
  -137.208862305, 75.7265396118,
  -139.035858154, 73.3896026611,
  -140.962402344, 72.6400985718,
  -143.009338379, 71.8541793823,
  -144.942443848, 71.1217575073,
  -147.863952637, 71.6354751587,
  -149.431243896, 72.9834213257,
  -149.036071777, 72.9114685059,
  -150.977920532, 72.2025756836,
  -153.892990112, 72.7517471313,
  -155.443786621, 74.1186218262,
  -157.082260132, 75.5756988525,
  -158.620895386, 76.9562454224,
  -159.506759644, 79.7872238159,
  -159.029510498, 81.798576355,
  -155.62940979, 78.723197937,
  -157.582931519, 78.0471343994,
  -160.488327026, 78.6453552246,
  -162.015869141, 80.0381698608,
  -163.629562378, 81.5226821899,
  -165.144729614, 82.9289550781,
  -165.982727051, 85.7744522095,
  -165.47164917, 87.7774734497,
  -165.360534668, 87.3914794922,
  -166.85848999, 88.8160476685,
  -167.661895752, 91.6715240479,
  -167.12651062, 93.6681900024,
  -166.549285889, 95.7835083008,
  -165.996292114, 97.7753601074,
  -163.85383606, 99.8269729614,
  -161.839920044, 100.29322052,
  -159.235488892, 90.8262634277,
  -161.20451355, 90.1967773438,
  -164.09487915, 90.8638305664,
  -165.588912964, 92.2925186157,
  -167.166885376, 93.8149185181,
  -168.648223877, 95.2567749023,
  -169.418441772, 98.1213760376,
  -168.859954834, 100.111694336,
  -168.757995605, 99.7231826782,
  -170.221710205, 101.182929993,
  -170.957092285, 104.056671143,
  -170.374465942, 106.040061951,
  -169.747177124, 108.141067505,
  -169.147033691, 110.119232178,
  -166.956451416, 112.119392395,
  -164.932022095, 112.537689209,
  -166.280578613, 108.155914307,
  -167.719482422, 109.64012146,
  -168.406326294, 112.525848389,
  -167.790344238, 114.499137878,
  -167.127761841, 116.589279175,
  -166.494338989, 118.557052612,
  -164.270385742, 120.520004272,
  -162.239181519, 120.90411377,
  -162.541915894, 120.640151978,
  -161.884658813, 122.600067139,
  -159.637023926, 124.535873413,
  -157.601318359, 124.895294189,
  -155.440383911, 125.266975403,
  -153.401580811, 125.608383179,
  -150.636322021, 124.534805298,
  -149.361984253, 122.907112122,
  -154.1434021, 108.266838074,
  -156.132797241, 107.705055237,
  -158.998672485, 108.470535278,
  -160.442977905, 109.949493408,
  -161.967956543, 111.524963379,
  -163.399124146, 113.016639709,
  -164.070953369, 115.905891418,
  -163.444717407, 117.875953674,
  -163.356109619, 117.484176636,
  -164.769042969, 118.993125916,
  -165.405731201, 121.890327454,
  -164.755599976, 123.852622986,
  -164.05682373, 125.930953979,
  -163.389373779, 127.887443542,
  -161.131729126, 129.811523438,
  -159.094161987, 130.160354614,
  -160.591766357, 125.827270508,
  -161.9790802, 127.359809875,
  -162.566833496, 130.267333984,
  -161.883743286, 132.218414307,
  -161.150039673, 134.28465271,
  -160.449707031, 136.229598999,
  -158.159927368, 138.115371704,
  -156.116790771, 138.429794312,
  -156.428390503, 138.176345825,
  -155.704483032, 140.11265564,
  -153.391967773, 141.970458984,
  -151.345153809, 142.260040283,
  -149.172805786, 142.557632446,
  -147.123519897, 142.829116821,
  -144.396591187, 141.661621094,
  -143.178665161, 139.99130249,
  -150.868682861, 133.886428833,
  -152.219238281, 135.451477051,
  -152.737762451, 138.372146606,
  -152.008529663, 140.306442261,
  -151.225982666, 142.354705811,
  -150.479675293, 144.282470703,
  -148.145736694, 146.113327026,
  -146.095703125, 146.379165649,
  -146.413238525, 146.133163452,
  -145.643569946, 148.051727295,
  -143.287597656, 149.854095459,
  -141.234481812, 150.095031738,
  -139.055679321, 150.340927124,
  -137.000518799, 150.563674927,
  -134.302078247, 149.331741333,
  -133.124145508, 147.632980347,
  -137.684005737, 147.157226562,
  -136.882095337, 149.062545776,
  -134.496078491, 150.824966431,
  -132.439208984, 151.03125,
  -130.256561279, 151.240386963,
  -128.197937012, 151.428466797,
  -125.520645142, 150.1512146,
  -124.371513367, 148.432846069,
  -124.732971191, 148.608001709,
  -122.672225952, 148.771072388,
  -120.010635376, 147.461410522,
  -118.882469177, 145.729202271,
  -117.693984985, 143.886581421,
  -116.581176758, 142.144485474,
  -116.485671997, 139.179672241,
  -117.484054565, 137.369552612,
  -133.938323975, 124.976219177,
  -135.953735352, 124.516365051,
  -138.776947021, 125.42665863,
  -140.144134521, 126.977172852,
  -141.587036133, 128.628189087,
  -142.940444946, 130.190734863,
  -143.464401245, 133.110427856,
  -142.738769531, 135.046081543,
  -142.670211792, 134.650299072,
  -144.004562378, 136.229171753,
  -144.493026733, 139.155014038,
  -143.743927002, 141.081710815,
  -142.940322876, 143.121795654,
  -142.174224854, 145.041793823,
  -139.821594238, 146.848526001,
  -137.768936157, 147.09324646,
  -139.485046387, 142.841964722,
  -140.79258728, 144.443099976,
  -141.231674194, 147.376754761,
  -140.450210571, 149.290557861,
  -139.612365723, 151.316802979,
  -138.813995361, 153.223617554,
  -136.431243896, 154.990432739,
  -134.374740601, 155.200531006,
  -134.69883728, 154.963226318,
  -133.877380371, 156.860214233,
  -131.473342896, 158.597991943,
  -129.41444397, 158.783081055,
  -127.229766846, 158.96975708,
  -125.169303894, 159.136657715,
  -122.505310059, 157.83190918,
  -121.373924255, 156.101806641,
  -129.364547729, 150.396011353,
  -130.633712769, 152.027755737,
  -131.003036499, 154.97102356,
  -130.176345825, 156.865707397,
  -129.290588379, 158.871520996,
  -128.447174072, 160.758834839,
  -126.023139954, 162.468566895,
  -123.962234497, 162.629760742,
  -124.291862488, 162.400268555,
  -123.425590515, 164.277191162,
  -120.980979919, 165.957382202,
  -118.918266296, 166.093521118,
  -116.729759216, 166.228271484,
  -114.665931702, 166.346160889,
  -112.033668518, 164.978561401,
  -110.943687439, 163.222091675,
  -115.521835327, 162.978927612,
  -114.624046326, 164.840988159,
  -112.151451111, 166.479736328,
  -110.086738586, 166.581100464,
  -107.896270752, 166.678955078,
  -105.830749512, 166.762054443,
  -103.221908569, 165.350265503,
  -102.161689758, 163.575668335,
  -102.513778687, 163.768981934,
  -100.447395325, 163.82699585,
  -97.8558883667, 162.383636475,
  -96.8172988892, 160.596298218,
  -95.724067688, 158.695602417,
  -94.7013320923, 156.899139404,
  -94.7567749023, 153.933288574,
  -95.8459625244, 152.176315308,
  -111.237289429, 151.622573853,
  -112.449905396, 153.296737671,
  -112.718345642, 156.250915527,
  -111.827339172, 158.116241455,
  -110.873535156, 160.090591431,
  -109.966064453, 161.947967529,
  -107.484985352, 163.573822021,
  -105.419784546, 163.664459229,
  -105.757080078, 163.446334839,
  -104.827133179, 165.292541504,
  -102.32648468, 166.888153076,
  -100.260322571, 166.953689575,
  -98.0684890747, 167.013504028,
  -96.0018310547, 167.060775757,
  -93.4178695679, 165.603942871,
  -92.3885879517, 163.811203003,
  -96.9723739624, 163.724761963,
  -96.0114364624, 165.555007935,
  -93.4842529297, 167.108261108,
  -91.4172897339, 167.138961792,
  -89.2247543335, 167.161834717,
  -87.1576004028, 167.174255371,
  -84.5985565186, 165.674087524,
  -83.5996398926, 163.864273071,
  -83.9449234009, 164.069519043,
  -81.8777542114, 164.056854248,
  -79.3371124268, 162.525726318,
  -78.3602600098, 160.703903198,
  -77.3326644897, 158.766937256,
  -76.3719558716, 156.936553955,
  -76.5287857056, 153.974349976,
  -77.6773986816, 152.255645752,
  -86.0574111938, 157.372436523,
  -85.0532913208, 159.179367065,
  -82.489944458, 160.672149658,
  -80.4228134155, 160.653778076,
  -78.2303619385, 160.624588013,
  -76.1634902954, 160.587921143,
  -73.6408004761, 159.027404785,
  -72.6851425171, 157.194366455,
  -73.0254440308, 157.407745361,
  -70.9591751099, 157.345977783,
  -68.4556121826, 155.754974365,
  -67.5222854614, 153.910461426,
  -66.540977478, 151.949615479,
  -65.6240081787, 150.096939087,
  -65.8511352539, 147.139312744,
  -67.0402374268, 145.448364258,
  -69.0574188232, 149.565353394,
  -66.992477417, 149.468765259,
  -64.5160903931, 147.835769653,
  -63.6139793396, 145.975799561,
  -62.6658668518, 143.998718262,
  -61.7802467346, 142.130828857,
  -62.0571899414, 139.177459717,
  -63.2746276855, 137.506774902,
  -63.2346954346, 137.906463623,
  -62.3718261719, 136.02796936,
  -62.6846160889, 133.078170776,
  -63.9222602844, 131.422409058,
  -65.2427597046, 129.671966553,
  -66.4949951172, 128.027206421,
  -69.2454986572, 126.916381836,
  -71.2887268066, 127.230224609,
  -95.9261474609, 127.326263428,
  -97.9717636108, 127.028373718,
  -100.713531494, 128.160614014,
  -101.952896118, 129.815093994,
  -103.25970459, 131.575759888,
  -104.484390259, 133.241134644,
  -104.774177551, 136.193283081,
  -103.89667511, 138.064987183,
  -103.85987854, 137.665023804,
  -105.06426239, 139.345123291,
  -105.31816864, 142.300582886,
  -104.417999268, 144.161499023,
  -103.454490662, 146.131118774,
  -102.537918091, 147.984008789,
  -100.048866272, 149.597640991,
  -97.9832382202, 149.678115845,
  -100.032455444, 145.57699585,
  -101.208343506, 147.277160645,
  -101.412399292, 150.236480713,
  -100.481010437, 152.081954956,
  -99.4844436646, 154.035079956,
  -98.5367660522, 155.872238159,
  -96.0208740234, 157.443695068,
  -93.9541778564, 157.489349365,
  -94.2961273193, 157.278640747,
  -93.326210022, 159.104156494,
  -90.7914276123, 160.644958496,
  -88.7243347168, 160.665496826,
  -86.5317077637, 160.677597046,
  -84.464515686, 160.679855347,
  -81.9128875732, 159.167114258,
  -80.9228820801, 157.352401733,
  -89.3425292969, 152.301101685,
  -90.4777145386, 154.028717041,
  -90.6114501953, 156.99206543,
  -89.6364898682, 158.814910889,
  -88.5938339233, 160.743789673,
  -87.6027908325, 162.557937622,
  -85.0502929688, 164.069213867,
  -82.9831085205, 164.065750122,
  -83.3299636841, 163.863235474,
  -82.3169708252, 165.66519165,
  -79.746307373, 167.145370483,
  -77.6793060303, 167.116836548,
  -75.4870223999, 167.076858521,
  -73.4203567505, 167.030029297,
  -70.9053573608, 165.457122803,
  -69.9587249756, 163.619430542,
  -74.5416946411, 163.741622925,
  -73.4984741211, 165.526275635,
  -70.9032287598, 166.962905884,
  -68.8370056152, 166.899520874,
  -66.6456985474, 166.822616577,
  -64.580116272, 166.740966797,
  -62.0919914246, 165.125900269,
  -61.1764602661, 163.272506714,
  -61.5120391846, 163.493225098,
  -59.4476013184, 163.386489868,
  -56.9792709351, 161.741348267,
  -56.0863227844, 159.876953125,
  -55.14793396, 157.895248413,
  -54.2715110779, 156.023025513,
  -54.5629730225, 153.071060181,
  -55.7886123657, 151.406387329,
  -71.1751556396, 152.080123901,
  -72.2506027222, 153.845535278,
  -72.2829208374, 156.811737061,
  -71.2462005615, 158.600158691,
  -70.1381835938, 160.492279053,
  -69.0856933594, 162.271484375,
  -66.4830169678, 163.694580078,
  -64.417137146, 163.620437622,
  -64.7707290649, 163.429885864,
  -63.6967086792, 165.196182251,
  -61.0769309998, 166.587585449,
  -59.0121192932, 166.488372803,
  -56.822479248, 166.373443604,
  -54.7586250305, 166.255966187,
  -52.2988853455, 164.597991943,
  -51.4156532288, 162.729003906,
  -55.9917488098, 163.007827759,
  -54.8881072998, 164.755767822,
  -52.2452583313, 166.102813721,
  -50.1824111938, 165.968811035,
  -47.9950180054, 165.817001343,
  -45.9334373474, 165.664749146,
  -43.5019950867, 163.965576172,
  -42.6503791809, 162.081939697,
  -42.9782104492, 162.314025879,
  -40.9186286926, 162.136764526,
  -38.5079994202, 160.408157349,
  -37.6793251038, 158.514312744,
  -36.8092498779, 156.501693726,
  -35.9973602295, 154.600616455,
  -36.3895988464, 151.660308838,
  -37.6714439392, 150.038513184,
  -45.6173553467, 155.80645752,
  -44.4725151062, 157.527694702,
  -41.7984199524, 158.811599731,
  -39.7393379211, 158.628646851,
  -37.5561714172, 158.424942017,
  -35.4987792969, 158.2237854,
  -33.1083717346, 156.467346191,
  -32.3017272949, 154.564025879,
  -32.6239585876, 154.803848267,
  -30.5691699982, 154.577713013,
  -28.2002696991, 152.792358398,
  -27.4167995453, 150.879379272,
  -26.5947608948, 148.84664917,
  -25.8282413483, 146.926803589,
  -26.2901916504, 143.996658325,
  -27.6101856232, 142.405776978,
  -29.2930850983, 146.670303345,
  -27.2423973083, 146.409591675,
  -24.903924942, 144.584564209,
  -24.1528053284, 142.658660889,
  -23.3651504517, 140.612365723,
  -22.6310939789, 138.679885864,
  -23.1423606873, 135.75793457,
  -24.4889812469, 134.189529419,
  -24.417350769, 134.584762573,
  -23.7068214417, 132.643508911,
  -24.2535362244, 129.727981567,
  -25.6191043854, 128.176040649,
  -27.0748138428, 126.536323547,
  -28.4540538788, 124.996520996,
  -31.2842826843, 124.108261108,
  -33.2960319519, 124.583808899,
  -49.6531867981, 137.105072021,
  -50.637424469, 138.922912598,
  -50.5188064575, 141.886886597,
  -49.3924484253, 143.620269775,
  -48.1896247864, 145.453567505,
  -47.0479812622, 147.1769104,
  -44.3762664795, 148.465774536,
  -42.3168487549, 148.286651611,
  -42.6796722412, 148.114334106,
  -41.5171852112, 149.823699951,
  -38.8300132751, 151.080032349,
  -36.7729225159, 150.875915527,
  -34.5919647217, 150.649734497,
  -32.5367546082, 150.427429199,
  -30.1645469666, 148.646453857,
  -29.377532959, 146.734939575,
  -33.9335250854, 147.24621582,
  -32.7423973083, 148.935745239,
  -30.0344390869, 150.146606445,
  -27.9810791016, 149.907836914,
  -25.8042411804, 149.644958496,
  -23.7530727386, 149.388031006,
  -21.4112186432, 147.567352295,
  -20.6565322876, 145.642837524,
  -20.9721317291, 145.891281128,
  -18.9242343903, 145.609481812,
  -16.6046638489, 143.760482788,
  -15.8734054565, 141.826950073,
  -15.1068439484, 139.772674561,
  -14.3927173615, 137.832733154,
  -14.9340248108, 134.91619873,
  -16.2967128754, 133.361724854,
  -23.9389076233, 139.526397705,
  -22.7079925537, 141.187164307,
  -19.9720458984, 142.333374023,
  -17.9249324799, 142.045913696,
  -15.7549524307, 141.731399536,
  -13.7104625702, 141.425857544,
  -11.4125022888, 139.550064087,
  -10.7037277222, 137.608184814,
  -11.01334095, 137.864059448,
  -8.97271251678, 137.533691406,
  -6.69770240784, 135.630142212,
  -6.01256465912, 133.679794312,
  -5.29500150681, 131.60786438,
  -4.627140522, 129.651535034,
  -5.2375535965, 126.748695374,
  -6.63677024841, 125.227012634,
  -8.10055541992, 129.571640015,
  -6.06578493118, 129.206939697,
  -3.823179245, 127.265304565,
  -3.17100906372, 125.303688049,
  -2.48846793175, 123.219970703,
  -1.85367393494, 121.252655029,
  -2.51292419434, 118.360496521,
  -3.93758821487, 116.862609863,
  -3.84594368935, 117.253707886,
  -3.23508858681, 115.278816223,
  -3.92941451073, 112.394874573,
  -5.37216329575, 110.914413452,
  -6.90940237045, 109.350883484,
  -8.36519241333, 107.883232117,
  -11.2369432449, 107.140106201,
  -13.2218971252, 107.717399597,
  -17.8889732361, 122.394523621,
  -16.6019859314, 124.01222229,
  -13.8284435272, 125.064201355,
  -11.7923583984, 124.706916809,
  -9.63440227509, 124.318397522,
  -7.60155773163, 123.943115234,
  -5.36908435822, 121.989845276,
  -4.72712898254, 120.024856567,
  -5.02781152725, 120.291168213,
  -2.99967312813, 119.891227722,
  -0.791086316109, 117.910995483,
  -0.17304174602, 115.938354492,
  0.473251253366, 113.843109131,
  1.07382321358, 111.865081787,
  0.364502757788, 108.984786987,
  -1.08592998981, 107.511856079,
  -2.40029168129, 111.903991699,
  -0.379182755947, 111.469917297,
  1.79571628571, 109.452735901,
  2.38042664528, 107.469963074,
  2.99131536484, 105.364128113,
  3.55846476555, 103.376251221,
  2.80070161819, 100.508331299,
  1.32565021515, 99.0600585938,
  1.43061339855, 99.4477539062,
  1.97357940674, 97.4531402588,
  1.18104255199, 94.5946426392,
  -0.31148853898, 93.1643753052,
  -1.90129446983, 91.6543273926,
  -3.40641784668, 90.2373199463,
  -6.30190181732, 89.5928344727,
  -8.26595497131, 90.2376556396,
  -5.58778810501, 99.6839981079,
  -3.57755637169, 99.2020568848,
  -1.45117068291, 97.1338043213,
  -0.913708627224, 95.1377029419,
  -0.352997660637, 93.0179595947,
  0.166787564754, 91.0171737671,
  -0.658864498138, 88.1680526733,
  -2.16789102554, 86.7552032471,
  -2.0537507534, 87.1403121948,
  -1.5583024025, 85.1333694458,
  -2.41849398613, 82.2944793701,
  -3.9445669651, 80.900062561,
  -5.56978225708, 79.4281845093,
  -7.10813045502, 78.0473175049,
  -10.0181016922, 77.4717636108,
  -11.9662895203, 78.1630477905,
  -8.54233074188, 81.2118301392,
  -8.08077812195, 79.1968231201,
  -8.9886932373, 76.3728408813,
  -10.5380506516, 75.0043411255,
  -12.1878423691, 73.5600662231,
  -13.7492437363, 72.205329895,
  -16.6685009003, 71.6789093018,
  -18.6047611237, 72.4029159546,
  -18.2090454102, 72.4718017578,
  -19.7867832184, 71.1361160278,
  -22.7122211456, 70.6451873779,
  -24.6395435333, 71.3926620483,
  -26.6803092957, 72.1945343018,
  -28.6009483337, 72.959022522,
  -30.4096660614, 75.3101425171,
  -30.6561183929, 77.3625946045,
  30.6561183929, 77.3625946045,
  30.4096698761, 75.3101425171,
  28.6009483337, 72.959022522,
  26.6803131104, 72.1945343018,
  24.639547348, 71.3926620483,
  22.712223053, 70.6451873779,
  19.7867851257, 71.1361160278,
  18.2090473175, 72.4718017578,
  18.604763031, 72.4029159546,
  16.6685028076, 71.6789093018,
  13.7492456436, 72.205329895,
  12.1878442764, 73.5600662231,
  10.5380525589, 75.0043411255,
  8.98869514465, 76.3728408813,
  8.08078098297, 79.1968231201,
  8.54233360291, 81.2118301392,
  11.9662914276, 78.1630477905,
  10.0181026459, 77.4717636108,
  7.10813188553, 78.0473175049,
  5.56978464127, 79.4281845093,
  3.94456934929, 80.900062561,
  2.4184961319, 82.2944793701,
  1.55830442905, 85.1333694458,
  2.05375313759, 87.1403121948,
  2.16789245605, 86.7552032471,
  0.658865869045, 88.1680526733,
  -0.166786178946, 91.0171737671,
  0.352999061346, 93.0179595947,
  0.913709759712, 95.1377029419,
  1.45117175579, 97.1338043213,
  3.57755661011, 99.2020568848,
  5.58778810501, 99.6839981079,
  8.26595401764, 90.2376556396,
  6.30190038681, 89.5928344727,
  3.40641736984, 90.2373199463,
  1.90129387379, 91.6543273926,
  0.311487227678, 93.1643753052,
  -1.18104374409, 94.5946426392,
  -1.97358059883, 97.4531402588,
  -1.43061470985, 99.4477539062,
  -1.32565033436, 99.0600585938,
  -2.80070161819, 100.508331299,
  -3.5584654808, 103.376251221,
  -2.99131631851, 105.364128113,
  -2.38042759895, 107.469963074,
  -1.79571759701, 109.452735901,
  0.379180550575, 111.469917297,
  2.4002892971, 111.903991699,
  1.08592998981, 107.511856079,
  -0.364502757788, 108.984786987,
  -1.07382321358, 111.865081787,
  -0.473251253366, 113.843109131,
  0.17304174602, 115.938354492,
  0.791086316109, 117.910995483,
  2.99967312813, 119.891227722,
  5.02781152725, 120.291168213,
  4.72712755203, 120.024856567,
  5.36908340454, 121.989845276,
  7.60155534744, 123.943115234,
  9.63440132141, 124.318397522,
  11.7923583984, 124.706916809,
  13.8284435272, 125.064201355,
  16.6019859314, 124.01222229,
  17.8889732361, 122.394523621,
  13.2218971252, 107.717399597,
  11.2369432449, 107.140106201,
  8.36519241333, 107.883232117,
  6.90940237045, 109.350883484,
  5.37216329575, 110.914413452,
  3.92941451073, 112.394874573,
  3.23508858681, 115.278816223,
  3.84594368935, 117.253707886,
  3.93758821487, 116.862609863,
  2.51292419434, 118.360496521,
  1.85367393494, 121.252655029,
  2.48846793175, 123.219970703,
  3.17100906372, 125.303688049,
  3.823179245, 127.265304565,
  6.06578493118, 129.206939697,
  8.10055541992, 129.571640015,
  6.63677024841, 125.227012634,
  5.2375535965, 126.748695374,
  4.627140522, 129.651535034,
  5.29500150681, 131.60786438,
  6.01256465912, 133.679794312,
  6.69770240784, 135.630142212,
  8.97271251678, 137.533691406,
  11.01334095, 137.864059448,
  10.7037277222, 137.608184814,
  11.4125022888, 139.550064087,
  13.7104625702, 141.425857544,
  15.7549524307, 141.731399536,
  17.9249324799, 142.045913696,
  19.9720458984, 142.333374023,
  22.7079925537, 141.187164307,
  23.9389076233, 139.526397705,
  16.2967128754, 133.361724854,
  14.9340248108, 134.91619873,
  14.3927173615, 137.832733154,
  15.1068439484, 139.772674561,
  15.8734054565, 141.826950073,
  16.6046638489, 143.760482788,
  18.9242343903, 145.609481812,
  20.9721317291, 145.891281128,
  20.6565322876, 145.642837524,
  21.4112186432, 147.567352295,
  23.7530727386, 149.388031006,
  25.8042411804, 149.644958496,
  27.9810791016, 149.907836914,
  30.0344390869, 150.146606445,
  32.7423973083, 148.935745239,
  33.9335250854, 147.24621582,
  29.377532959, 146.734939575,
  30.1645469666, 148.646453857,
  32.5367546082, 150.427429199,
  34.5919647217, 150.649734497,
  36.7729225159, 150.875915527,
  38.8300132751, 151.080032349,
  41.5171852112, 149.823699951,
  42.6796722412, 148.114334106,
  42.3168487549, 148.286651611,
  44.3762664795, 148.465774536,
  47.0479812622, 147.1769104,
  48.1896247864, 145.453567505,
  49.3924484253, 143.620269775,
  50.5188064575, 141.886886597,
  50.637424469, 138.922912598,
  49.6531867981, 137.105072021,
  33.2960395813, 124.583808899,
  31.2842903137, 124.108261108,
  28.4540576935, 124.996520996,
  27.0748157501, 126.536323547,
  25.6191082001, 128.176040649,
  24.2535400391, 129.727981567,
  23.7068271637, 132.643508911,
  24.4173564911, 134.584762573,
  24.488986969, 134.189529419,
  23.1423683167, 135.75793457,
  22.6311016083, 138.679885864,
  23.3651580811, 140.612365723,
  24.1528148651, 142.658660889,
  24.9039325714, 144.584564209,
  27.2424087524, 146.409591675,
  29.2931003571, 146.670303345,
  27.6101856232, 142.405776978,
  26.2901954651, 143.996673584,
  25.828245163, 146.926818848,
  26.5947647095, 148.846664429,
  27.4168014526, 150.879379272,
  28.2002716064, 152.792358398,
  30.5691738129, 154.577713013,
  32.623966217, 154.803848267,
  32.3017349243, 154.564025879,
  33.1083755493, 156.467346191,
  35.4987869263, 158.2237854,
  37.5561714172, 158.424942017,
  39.7393493652, 158.628646851,
  41.7984352112, 158.811599731,
  44.4725227356, 157.527694702,
  45.6173667908, 155.80645752,
  37.6714515686, 150.038543701,
  36.3896102905, 151.660324097,
  35.9973716736, 154.600616455,
  36.8092575073, 156.501708984,
  37.6793365479, 158.514358521,
  38.5080108643, 160.408172607,
  40.918636322, 162.136764526,
  42.9782218933, 162.314056396,
  42.6503944397, 162.081970215,
  43.5020065308, 163.965606689,
  45.9334526062, 165.664794922,
  47.9950294495, 165.81703186,
  50.1824188232, 165.968841553,
  52.2452697754, 166.102813721,
  54.8881187439, 164.755767822,
  55.9917678833, 163.007843018,
  51.4156570435, 162.729003906,
  52.2988929749, 164.597991943,
  54.7586288452, 166.255966187,
  56.8224830627, 166.373443604,
  59.0121269226, 166.488372803,
  61.0769348145, 166.587585449,
  63.6967124939, 165.196182251,
  64.7707366943, 163.429885864,
  64.4171447754, 163.620437622,
  66.4830169678, 163.694580078,
  69.0857009888, 162.271484375,
  70.1381912231, 160.492279053,
  71.2462005615, 158.600158691,
  72.2829208374, 156.811737061,
  72.2506027222, 153.845535278,
  71.1751556396, 152.080123901,
  55.7886123657, 151.406387329,
  54.5629730225, 153.071060181,
  54.2715110779, 156.02305603,
  55.14793396, 157.895248413,
  56.0863227844, 159.876953125,
  56.9792709351, 161.741348267,
  59.4476013184, 163.386489868,
  61.5120391846, 163.493225098,
  61.1764717102, 163.272506714,
  62.091999054, 165.125900269,
  64.5801239014, 166.740966797,
  66.6457061768, 166.822616577,
  68.8370056152, 166.899520874,
  70.9032363892, 166.962905884,
  73.4984741211, 165.526275635,
  74.5417098999, 163.741622925,
  69.958732605, 163.619430542,
  70.9053726196, 165.457122803,
  73.4203643799, 167.030029297,
  75.4870300293, 167.076858521,
  77.6793136597, 167.116836548,
  79.746307373, 167.145370483,
  82.3169708252, 165.66519165,
  83.3299713135, 163.863235474,
  82.9831161499, 164.065750122,
  85.0503005981, 164.069213867,
  87.6027984619, 162.557937622,
  88.5938415527, 160.743774414,
  89.6364974976, 158.814910889,
  90.6114578247, 156.99206543,
  90.4777297974, 154.028717041,
  89.3425369263, 152.301101685,
  80.9228897095, 157.352401733,
  81.9128952026, 159.167114258,
  84.4645233154, 160.679855347,
  86.5317153931, 160.677597046,
  88.7243423462, 160.665496826,
  90.7914352417, 160.644958496,
  93.3262176514, 159.104156494,
  94.2961349487, 157.278640747,
  93.9541854858, 157.489318848,
  96.0208816528, 157.44367981,
  98.5367660522, 155.872207642,
  99.4844512939, 154.035049438,
  100.481018066, 152.081954956,
  101.412414551, 150.236480713,
  101.208358765, 147.277160645,
  100.032470703, 145.57699585,
  97.9832458496, 149.678115845,
  100.048873901, 149.597640991,
  102.537918091, 147.984008789,
  103.45450592, 146.131118774,
  104.418014526, 144.161499023,
  105.31816864, 142.300582886,
  105.06426239, 139.345123291,
  103.859893799, 137.665023804,
  103.896690369, 138.064987183,
  104.774177551, 136.193283081,
  104.484390259, 133.241134644,
  103.259719849, 131.575759888,
  101.952903748, 129.815093994,
  100.713539124, 128.160614014,
  97.9717712402, 127.028388977,
  95.9261550903, 127.326278687,
  71.2887268066, 127.230194092,
  69.2454986572, 126.916366577,
  66.4949951172, 128.027191162,
  65.2427597046, 129.671951294,
  63.9222602844, 131.42237854,
  62.6846160889, 133.078140259,
  62.3718261719, 136.027938843,
  63.2346954346, 137.906433105,
  63.2746276855, 137.506759644,
  62.0571899414, 139.177429199,
  61.7802467346, 142.130813599,
  62.6658668518, 143.998687744,
  63.6139793396, 145.975753784,
  64.5160903931, 147.835739136,
  66.992477417, 149.468734741,
  69.0574188232, 149.565307617,
  67.0402374268, 145.448348999,
  65.8511352539, 147.139282227,
  65.6240081787, 150.096908569,
  66.540977478, 151.949615479,
  67.5222854614, 153.910446167,
  68.4556121826, 155.754943848,
  70.9591751099, 157.345977783,
  73.0254440308, 157.407730103,
  72.6851425171, 157.194351196,
  73.6408004761, 159.027389526,
  76.1634902954, 160.587905884,
  78.2303619385, 160.624557495,
  80.4228134155, 160.653747559,
  82.489944458, 160.672134399,
  85.0532836914, 159.179351807,
  86.0574111938, 157.372406006,
  77.6773986816, 152.255645752,
  76.5287857056, 153.974349976,
  76.3719558716, 156.936553955,
  77.3326644897, 158.766937256,
  78.3602523804, 160.703903198,
  79.3371124268, 162.525726318,
  81.8777542114, 164.056854248,
  83.9449234009, 164.069519043,
  83.5996398926, 163.864273071,
  84.5985565186, 165.674087524,
  87.1576004028, 167.174255371,
  89.2247543335, 167.161834717,
  91.4172897339, 167.138961792,
  93.4842529297, 167.108261108,
  96.0114364624, 165.555007935,
  96.9723739624, 163.724761963,
  92.3885879517, 163.811203003,
  93.4178695679, 165.603912354,
  96.0018310547, 167.060760498,
  98.0684890747, 167.01348877,
  100.260322571, 166.953643799,
  102.32648468, 166.888122559,
  104.827133179, 165.292526245,
  105.757080078, 163.446304321,
  105.419784546, 163.664413452,
  107.484985352, 163.573776245,
  109.966064453, 161.947921753,
  110.873535156, 160.090545654,
  111.827339172, 158.116226196,
  112.718345642, 156.250915527,
  112.449905396, 153.296722412,
  111.237289429, 151.622543335,
  95.8459701538, 152.176315308,
  94.7567825317, 153.933288574,
  94.7013473511, 156.899139404,
  95.7240829468, 158.695602417,
  96.8173065186, 160.596298218,
  97.8558959961, 162.383636475,
  100.447402954, 163.82699585,
  102.513786316, 163.768981934,
  102.161697388, 163.575668335,
  103.221908569, 165.350265503,
  105.830757141, 166.762054443,
  107.896286011, 166.678955078,
  110.086753845, 166.581100464,
  112.15146637, 166.479736328,
  114.624061584, 164.840988159,
  115.521842957, 162.978927612,
  110.943710327, 163.222091675,
  112.033676147, 164.978561401,
  114.66595459, 166.346160889,
  116.729782104, 166.228271484,
  118.918289185, 166.093521118,
  120.980987549, 165.957382202,
  123.425613403, 164.277175903,
  124.291893005, 162.400253296,
  123.962257385, 162.629760742,
  126.023162842, 162.468566895,
  128.44720459, 160.758834839,
  129.290618896, 158.871520996,
  130.176345825, 156.865707397,
  131.003036499, 154.97102356,
  130.633743286, 152.027755737,
  129.364578247, 150.396011353,
  121.373924255, 156.101791382,
  122.505310059, 157.831893921,
  125.169303894, 159.136642456,
  127.229766846, 158.969741821,
  129.41444397, 158.783065796,
  131.473342896, 158.597961426,
  133.877380371, 156.860198975,
  134.69883728, 154.963226318,
  134.374740601, 155.200515747,
  136.431243896, 154.990402222,
  138.813995361, 153.223602295,
  139.612365723, 151.31678772,
  140.450210571, 149.290527344,
  141.231674194, 147.376724243,
  140.79258728, 144.443069458,
  139.485046387, 142.841949463,
  137.768936157, 147.093231201,
  139.821594238, 146.848526001,
  142.174224854, 145.041778564,
  142.940322876, 143.121795654,
  143.743927002, 141.081695557,
  144.493026733, 139.155014038,
  144.004562378, 136.229156494,
  142.670211792, 134.650299072,
  142.738769531, 135.046066284,
  143.464401245, 133.110427856,
  142.940444946, 130.190719604,
  141.587036133, 128.628189087,
  140.144134521, 126.977157593,
  138.776947021, 125.426651001,
  135.953735352, 124.516357422,
  133.938323975, 124.976211548,
  117.484054565, 137.369552612,
  116.485671997, 139.179656982,
  116.581176758, 142.144454956,
  117.693984985, 143.886581421,
  118.882469177, 145.729187012,
  120.010635376, 147.461395264,
  122.672225952, 148.77104187,
  124.732971191, 148.60798645,
  124.371513367, 148.432830811,
  125.520645142, 150.151199341,
  128.197937012, 151.428451538,
  130.256561279, 151.240371704,
  132.439208984, 151.031234741,
  134.496078491, 150.824951172,
  136.882095337, 149.062545776,
  137.684005737, 147.157211304,
  133.124145508, 147.632965088,
  134.302078247, 149.331726074,
  137.000518799, 150.563659668,
  139.055679321, 150.340927124,
  141.234481812, 150.095001221,
  143.287597656, 149.854095459,
  145.643569946, 148.051712036,
  146.413238525, 146.133148193,
  146.095703125, 146.379135132,
  148.145736694, 146.113296509,
  150.479675293, 144.282455444,
  151.225982666, 142.354675293,
  152.008529663, 140.306427002,
  152.737762451, 138.372131348,
  152.219238281, 135.451461792,
  150.868682861, 133.886413574,
  143.178665161, 139.991271973,
  144.396591187, 141.661590576,
  147.123519897, 142.829101562,
  149.172805786, 142.557617188,
  151.345153809, 142.260040283,
  153.391967773, 141.970428467,
  155.704483032, 140.112640381,
  156.428390503, 138.176315308,
  156.116790771, 138.429794312,
  158.159927368, 138.115341187,
  160.449707031, 136.229598999,
  161.150039673, 134.28465271,
  161.883743286, 132.218383789,
  162.566833496, 130.267318726,
  161.9790802, 127.359786987,
  160.591766357, 125.82724762,
  159.094161987, 130.160339355,
  161.131729126, 129.811508179,
  163.389373779, 127.887420654,
  164.05682373, 125.930938721,
  164.755599976, 123.852615356,
  165.405731201, 121.890304565,
  164.769042969, 118.993103027,
  163.356109619, 117.484169006,
  163.444717407, 117.875938416,
  164.070953369, 115.905883789,
  163.399124146, 113.016616821,
  161.967956543, 111.524940491,
  160.442962646, 109.949478149,
  158.998657227, 108.470527649,
  156.132781982, 107.705032349,
  154.143386841, 108.266822815,
  149.361984253, 122.907104492,
  150.636322021, 124.534797668,
  153.401580811, 125.60836792,
  155.44039917, 125.266967773,
  157.601333618, 124.895278931,
  159.637023926, 124.535873413,
  161.884674072, 122.600067139,
  162.541946411, 120.640136719,
  162.239181519, 120.904098511,
  164.270385742, 120.519989014,
  166.494338989, 118.557029724,
  167.127761841, 116.589271545,
  167.790344238, 114.49912262,
  168.406326294, 112.525840759,
  167.719482422, 109.640106201,
  166.280578613, 108.155914307,
  164.932022095, 112.53767395,
  166.956451416, 112.119384766,
  169.147033691, 110.119232178,
  169.747177124, 108.141059875,
  170.374465942, 106.040046692,
  170.957092285, 104.056655884,
  170.221710205, 101.182922363,
  168.757995605, 99.7231750488,
  168.859954834, 100.111686707,
  169.418441772, 98.1213684082,
  168.648223877, 95.2567672729,
  167.166885376, 93.8149108887,
  165.588912964, 92.2925186157,
  164.09487915, 90.863822937,
  161.20451355, 90.1967697144,
  159.235488892, 90.8262634277,
  161.839920044, 100.293212891,
  163.85383606, 99.826965332,
  165.996292114, 97.7753601074,
  166.549285889, 95.7835083008,
  167.12651062, 93.6681900024,
  167.661895752, 91.6715240479,
  166.85848999, 88.8160400391,
  165.360534668, 87.3914642334,
  165.47164917, 87.7774658203,
  165.982727051, 85.7744522095,
  165.144729614, 82.9289474487,
  163.629562378, 81.5226669312,
  162.015869141, 80.0381622314,
  160.488327026, 78.6453475952,
  157.582931519, 78.04712677,
  155.62940979, 78.7231903076,
  159.029510498, 81.7985687256,
  159.506759644, 79.7872161865,
  158.620895386, 76.956237793,
  157.082260132, 75.5756988525,
  155.443786621, 74.1186141968,
  153.892990112, 72.7517318726,
  150.977920532, 72.2025680542,
  149.036071777, 72.9114608765,
  149.431243896, 72.9834136963,
  147.863952637, 71.6354675293,
  144.942443848, 71.1217422485,
  143.009338379, 71.8541717529,
  140.962402344, 72.6400985718,
  139.035858154, 73.3895874023,
  137.208862305, 75.726524353,
  136.946395874, 77.7769927979,
  117.155502319, 94.9164199829,
  115.088569641, 94.8832473755,
  112.514602661, 96.3576660156,
  111.497581482, 98.157371521,
  110.427276611, 100.071052551,
  109.426216125, 101.879692078,
  109.517326355, 104.84463501,
  110.627540588, 106.588394165,
  110.612754822, 106.187011719,
  109.633735657, 108.007659912,
  109.76083374, 110.97127533,
  110.89214325, 112.701431274,
  112.100227356, 114.531272888,
  113.246795654, 116.251350403,
  115.922172546, 117.532546997,
  117.98109436, 117.34753418,
  115.422988892, 113.542991638,
  114.47479248, 115.379890442,
  114.651824951, 118.340950012,
  115.812133789, 120.051780701,
  117.050880432, 121.860992432,
  118.226280212, 123.561500549,
  120.922874451, 124.797424316,
  122.978363037, 124.577735901,
  122.612205505, 124.412590027,
  123.808174133, 126.098701477,
  126.519584656, 127.301795959,
  128.572250366, 127.057151794,
  130.748336792, 126.788032532,
  132.79876709, 126.525245667,
  135.135375977, 124.697853088,
  135.884567261, 122.771202087,
  126.886779785, 118.841056824,
  125.982452393, 120.699958801,
  126.229766846, 123.655967712,
  127.430358887, 125.338783264,
  128.711730957, 127.118057251,
  129.927185059, 128.79019165,
  132.652374268, 129.961715698,
  134.702072144, 129.693283081,
  134.332092285, 129.536895752,
  135.567749023, 131.194137573,
  138.306976318, 132.332504272,
  140.353240967, 132.03918457,
  142.522323608, 131.718475342,
  144.565933228, 131.407073975,
  146.858520508, 129.524719238,
  147.561737061, 127.580795288,
  143.03225708, 128.289581299,
  144.295669556, 129.925750732,
  147.05368042, 131.017791748,
  149.094726562, 130.6900177,
  151.258087158, 130.332794189,
  153.296142578, 129.986984253,
  155.556686401, 128.066253662,
  156.227035522, 126.110771179,
  155.92250061, 126.372711182,
  157.956237793, 126.002182007,
  160.193252563, 124.054153442,
  160.839828491, 122.090660095,
  161.516403198, 120.005004883,
  162.145584106, 118.035865784,
  161.478042603, 115.145614624,
  160.049087524, 113.651817322,
  144.875900269, 116.292694092,
  144.035690308, 118.181427002,
  144.383911133, 121.12726593,
  145.641357422, 122.768035889,
  146.982803345, 124.502456665,
  148.254730225, 126.132026672,
  151.018417358, 127.209701538,
  153.057723999, 126.871330261,
  152.682617188, 126.727684021,
  153.974227905, 128.341690063,
  156.750762939, 129.385726929,
  158.785827637, 129.022613525,
  160.942672729, 128.62789917,
  162.97442627, 128.246795654,
  165.201293945, 126.287155151,
  165.837615967, 124.320327759,
  161.335037231, 125.183586121,
  162.653671265, 126.775596619,
  165.447418213, 127.772689819,
  167.47605896, 127.375328064,
  169.625915527, 126.944328308,
  171.650970459, 126.529029846,
  173.844497681, 124.53213501,
  174.44758606, 122.554862976,
  174.152191162, 122.827056885,
  176.172058105, 122.387199402,
  178.341156006, 120.363792419,
  178.920196533, 118.379348755,
  179.525054932, 116.271781921,
  180.086517334, 114.282302856,
  179.320541382, 111.416557312,
  177.84135437, 109.972503662,
  170.700500488, 116.711540222,
  172.05657959, 118.271789551,
  174.873214722, 119.202255249,
  176.891845703, 118.756835938,
  179.030883789, 118.274917603,
  181.045501709, 117.811660767,
  183.191009521, 115.76322937,
  183.74697876, 113.772186279,
  183.458145142, 114.051315308,
  185.466995239, 113.563621521,
  187.58744812, 111.489295959,
  188.119186401, 109.491645813,
  188.673812866, 107.370307922,
  189.187866211, 105.368057251,
  188.3540802, 102.521316528,
  186.841003418, 101.112792969,
  185.71875, 105.557891846,
  187.719085693, 105.036407471,
  189.804275513, 102.926635742,
  190.302276611, 100.920318604,
  190.821075439, 98.7899246216,
  191.301315308, 96.7792816162,
  190.419647217, 93.9469985962,
  188.883071899, 92.5641708374,
  189.004776001, 92.9469680786,
  189.46055603, 90.9306411743,
  188.544555664, 88.1092605591,
  186.99130249, 86.7452011108,
  185.337387085, 85.3056716919,
  183.772109985, 83.955406189,
  180.851348877, 83.4373474121,
  178.917160034, 84.1669006348,
  164.300369263, 98.6819152832,
  163.557312012, 100.610954285,
  164.054962158, 103.535255432,
  165.394256592, 105.109924316,
  166.822189331, 106.773872375,
  168.175354004, 108.336631775,
  170.990264893, 109.272315979,
  173.009735107, 108.8306427,
  172.627792358, 108.706253052,
  173.999832153, 110.252464294,
  176.825897217, 111.153907776,
  178.83984375, 110.687728882,
  180.973815918, 110.183830261,
  182.983551025, 109.699859619,
  185.107849121, 107.629455566,
  185.643310547, 105.632820129,
  181.190429688, 106.723999023,
  182.588348389, 108.246887207,
  185.429199219, 109.10055542,
  187.435012817, 108.600494385,
  189.560180664, 108.060691833,
  191.56149292, 107.542915344,
  193.650588989, 105.437011719,
  194.152313232, 103.431625366,
  193.871170044, 103.718505859,
  195.866043091, 103.176452637,
  197.929428101, 101.045333862,
  198.40675354, 99.033996582,
  198.903579712, 96.8983840942,
  199.363113403, 94.8829116821,
  198.452346802, 92.0598449707,
  196.901611328, 90.6929168701,
  190.112854004, 97.7864761353,
  191.546524048, 99.2757263184,
  194.406829834, 100.061714172,
  196.400192261, 99.5141677856,
  198.511962891, 98.9240493774,
  200.50038147, 98.3589019775,
  202.538894653, 96.2039642334,
  202.992858887, 94.1872406006,
  202.718597412, 94.4807128906,
  204.700042725, 93.891456604,
  206.712234497, 91.7119369507,
  207.141662598, 89.6898269653,
  207.587661743, 87.5430145264,
  207.999191284, 85.5172042847,
  207.021652222, 82.7165527344,
  205.438903809, 81.3868331909,
  204.544219971, 85.8832778931,
  206.515426636, 85.2607116699,
  208.490600586, 83.0475921631,
  208.885879517, 81.0185317993,
  209.295639038, 78.8645172119,
  209.672988892, 76.8320465088,
  208.648361206, 74.0482711792,
  207.043426514, 72.7454071045,
  207.184463501, 73.1215133667,
  207.537078857, 71.0846099854,
  206.478713989, 68.3134918213,
  204.858062744, 67.0302200317,
  203.133071899, 65.6766815186,
  201.50112915, 64.4077987671,
  198.557800293, 64.0389862061,
  196.663253784, 64.8659896851,
  193.916412354, 80.0203475952,
  195.400192261, 81.4596939087,
  198.285705566, 82.1474075317,
  200.259170532, 81.5320205688,
  202.349517822, 80.8700408936,
  204.317474365, 80.2372283936,
  206.281082153, 78.0138626099,
  206.665817261, 75.9827804565,
  206.401779175, 76.285446167,
  208.361907959, 75.6287765503,
  210.29838562, 73.381729126,
  210.658416748, 71.3461227417,
  211.030715942, 69.1853179932,
  211.37272644, 67.1466140747,
  210.300003052, 64.3810348511,
  208.672668457, 63.1062088013,
  207.932296753, 67.6306152344,
  209.881072998, 66.9410095215,
  211.779418945, 64.6616363525,
  212.105072021, 62.6202583313,
  212.440933228, 60.4534759521,
  212.748535156, 58.4092979431,
  211.629348755, 55.662197113,
  209.980804443, 54.4149780273,
  210.13458252, 54.7860298157,
  210.417358398, 52.7382621765,
  209.264862061, 50.0049514771,
  207.601303101, 48.77784729,
  205.831008911, 47.4840927124,
  204.156646729, 46.2717475891,
  201.202423096, 46.0038032532,
  199.337249756, 46.895111084,
  203.204376221, 55.9201393127,
  205.136245728, 55.1844444275,
  206.979919434, 52.8606414795,
  207.257034302, 50.8121032715,
  207.541351318, 48.637966156,
  207.800323486, 46.5870552063,
  206.616210938, 43.8673019409,
  204.938476562, 42.6595840454,
  205.101043701, 43.0268783569,
  205.335098267, 40.9729728699,
  204.118057251, 38.2678031921,
  202.42578125, 37.0805473328,
  200.625289917, 35.8291931152,
  198.922607422, 34.656955719,
  195.962844849, 34.459236145,
  194.119384766, 35.3945808411,
  197.905944824, 37.9792060852,
  198.10534668, 35.9216499329,
  196.84286499, 33.2373733521,
  195.130844116, 32.0788116455,
  193.309494019, 30.8579788208,
  191.587310791, 29.7146015167,
  188.624633789, 29.5667953491,
  186.797180176, 30.53307724,
  187.198455811, 30.550655365,
  185.462493896, 29.4282817841,
  182.498260498, 29.3164634705,
  180.682678223, 30.3048686981,
  178.761550903, 31.3617668152,
  176.954788208, 32.366191864,
  175.462432861, 34.9297943115,
  175.481155396, 36.9969100952,
  172.056594849, 61.3953704834,
  171.469528198, 63.3774528503,
  172.198471069, 66.2528305054,
  173.658905029, 67.7158432007,
  175.21484375, 69.2607803345,
  176.688171387, 70.7108154297,
  179.568649292, 71.4193572998,
  181.546524048, 70.8182525635,
  181.155883789, 70.7246780396,
  182.646713257, 72.1567153931,
  185.535583496, 72.8302307129,
  187.505996704, 72.2051467896,
  189.593078613, 71.5328903198,
  191.557891846, 70.8904037476,
  193.510574341, 68.6574020386,
  193.885299683, 66.624458313,
  189.533493042, 68.0668029785,
  191.048248291, 69.4734954834,
  193.948074341, 70.0982284546,
  195.907669067, 69.4400253296,
  197.983078003, 68.7326889038,
  199.936798096, 68.057182312,
  201.851547241, 65.7915878296,
  202.191986084, 63.7526168823,
  201.93460083, 64.0609664917,
  203.879974365, 63.3617858887,
  205.767059326, 61.0731048584,
  206.082687378, 59.0301513672,
  206.407897949, 56.861743927,
  206.705459595, 54.8160743713,
  205.572753906, 52.074508667,
  203.91809082, 50.8354148865,
  197.715774536, 58.447101593,
  199.263504028, 59.817440033,
  202.177352905, 60.3731307983,
  204.120773315, 59.6685791016,
  206.178817749, 58.9121704102,
  208.115936279, 58.1904640198,
  209.976364136, 55.8800392151,
  210.268264771, 53.8335571289,
  210.018249512, 54.1479263306,
  211.946456909, 53.4027404785,
  213.778686523, 51.0699005127,
  214.045715332, 49.0200271606,
  214.319335938, 46.8445129395,
  214.568222046, 44.7923545837,
  213.370773315, 42.078453064,
  211.687103271, 40.8790016174,
  211.153366089, 45.4324264526,
  213.068740845, 44.6548500061,
  214.86138916, 42.2914581299,
  215.093841553, 40.2373695374,
  215.330749512, 38.0575523376,
  215.545013428, 36.0014915466,
  214.301971436, 33.3081626892,
  212.598342896, 32.1372566223,
  212.76890564, 32.5009231567,
  212.9581604, 30.4424095154,
  211.682495117, 27.7643737793,
  209.964813232, 26.6142406464,
  208.137481689, 25.4023857117,
  206.409667969, 24.267490387,
  203.446304321, 24.1342487335,
  201.623626709, 25.1095066071,
  200.092407227, 40.4344902039,
  201.686096191, 41.7511024475,
  204.617218018, 42.206829071,
  206.535415649, 41.4362373352,
  208.566390991, 40.6098899841,
  210.47769165, 39.8223609924,
  212.258010864, 37.4496765137,
  212.479782104, 35.3944091797,
  212.24067688, 35.7171478271,
  214.142272949, 34.906463623,
  215.89364624, 32.5123291016,
  216.090423584, 30.4545230865,
  216.289505005, 28.2709255219,
  216.468063354, 26.2114562988,
  215.178497314, 23.5400924683,
  213.454833984, 22.3989143372,
  213.077087402, 26.967918396,
  214.964767456, 26.1253032684,
  216.675537109, 23.7019939423,
  216.837615967, 21.641160965,
  216.999847412, 19.4545173645,
  217.143692017, 17.3923301697,
  215.809265137, 14.7430830002,
  214.066619873, 13.6311149597,
  214.24949646, 13.9887399673,
  214.368255615, 11.9249582291,
  213.001800537, 9.29211139679,
  211.245742798, 8.20139026642,
  209.378082275, 7.05272102356,
  207.612472534, 5.97757387161,
  204.646286011, 5.94574689865,
  202.858032227, 6.98276090622,
  207.431625366, 15.6711559296,
  209.298751831, 14.7839517593,
  210.951507568, 12.3207006454,
  211.064590454, 10.2566003799,
  211.174850464, 8.0667219162,
  211.269683838, 6.00170278549,
  209.872711182, 3.38488888741,
  208.104171753, 2.31461620331,
  208.295471191, 2.66779732704,
  208.365219116, 0.601778209209,
  206.936584473, -1.99787759781,
  205.155166626, -3.04659366608,
  203.260696411, -4.15058755875,
  201.470062256, -5.18350458145,
  198.503982544, -5.14488744736,
  196.740844727, -4.06570100784,
  200.721237183, -1.79083228111,
  200.756134033, -3.85773348808,
  199.283905029, -6.43294239044,
  197.485061646, -7.45148563385,
  195.572265625, -8.52339458466,
  193.764465332, -9.52598762512,
  190.799468994, -9.43738365173,
  189.054748535, -8.32863521576,
  189.456176758, -8.34307098389,
  187.636322021, -9.32363510132,
  184.672607422, -9.19903182983,
  182.941497803, -8.06917667389,
  181.110671997, -6.86264181137,
  179.38961792, -5.71751928329,
  178.106155396, -3.04321122169,
  178.289428711, -0.984156668186,
  188.345535278, 16.9939727783,
  190.004119873, 18.2278079987,
  192.954650879, 18.5338344574,
  194.831161499, 17.6666564941,
  196.817474365, 16.7380580902,
  198.686248779, 15.8543167114,
  200.343566895, 13.3941345215,
  200.460479736, 11.330247879,
  200.238067627, 11.6647319794,
  202.095977783, 10.7583608627,
  203.72328186, 8.27823257446,
  203.815109253, 6.21307754517,
  203.902877808, 4.02217960358,
  203.976425171, 1.95629370213,
  202.552627563, -0.646005690098,
  200.773147583, -1.69802236557,
  200.628326416, 2.88428211212,
  202.470687866, 1.94672739506,
  204.055953979, -0.560475647449,
  204.112991333, -2.62688469887,
  204.163787842, -4.8189496994,
  204.202529907, -6.88578176498,
  202.735061646, -9.46371555328,
  200.938095093, -10.4855909348,
  201.138916016, -10.1377315521,
  201.152557373, -12.2048816681,
  199.653900146, -14.7648038864,
  197.844665527, -15.7647809982,
  195.920944214, -16.8169498444,
  194.102935791, -17.8008861542,
  191.139007568, -17.6817760468,
  189.405792236, -16.5551319122,
  194.415481567, -8.11065673828,
  196.235061646, -9.0916967392,
  197.760345459, -11.6358346939,
  197.768280029, -13.7030153275,
  197.76701355, -15.8956680298,
  197.75668335, -17.9628391266,
  196.228408813, -20.505197525,
  194.407684326, -21.4841136932,
  194.616714478, -21.1411247253,
  194.581268311, -23.2080154419,
  193.022232056, -25.7316246033,
  191.189758301, -26.6883602142,
  189.241592407, -27.6945514679,
  187.400741577, -28.6350402832,
  184.440460205, -28.4455814362,
  182.73449707, -27.2781009674,
  186.825454712, -25.2086658478,
  186.755172729, -27.2746677399,
  185.153823853, -29.771648407,
  183.305496216, -30.6973628998,
  181.340637207, -31.6705760956,
  179.484191895, -32.5799026489,
  176.527511597, -32.3405838013,
  174.84147644, -31.1445140839,
  175.241638184, -31.1793479919,
  173.374282837, -32.0660667419,
  170.420715332, -31.7908496857,
  168.749343872, -30.5743961334,
  166.98223877, -29.2762851715,
  165.321685791, -28.0450954437,
  164.175918579, -25.3089561462,
  164.463729858, -23.2618923187,
  178.323608398, -16.54596138,
  180.108581543, -17.5886497498,
  181.545959473, -20.1834602356,
  181.483215332, -22.249704361,
  181.406951904, -24.441028595,
  181.325927734, -26.5066356659,
  179.711608887, -28.9952507019,
  177.85848999, -29.9113311768,
  178.07913208, -29.5756874084,
  177.973022461, -31.6401557922,
  176.328582764, -34.1089820862,
  174.46446228, -35.002494812,
  172.483062744, -35.9414749146,
  170.611114502, -36.8184661865,
  167.6590271, -36.5278892517,
  165.993988037, -35.3027534485,
  170.153335571, -33.3744277954,
  170.012451172, -35.4368171692,
  168.326660156, -37.8775787354,
  166.447738647, -38.7395477295,
  164.450805664, -39.6450042725,
  162.564346313, -40.4903144836,
  159.61756897, -40.1500320435,
  157.973419189, -38.8970031738,
  158.372146606, -38.9454994202,
  156.475570679, -39.7678413391,
  153.533157349, -39.3917922974,
  151.904342651, -38.1188926697,
  150.182632446, -36.7611122131,
  148.565155029, -35.4738540649,
  147.513641357, -32.7001419067,
  147.871276855, -30.6641139984,
  157.60333252, -31.9653320312,
  157.413497925, -34.0237960815,
  155.670227051, -36.4238357544,
  153.771392822, -37.2409439087,
  151.753463745, -38.0987243652,
  149.847457886, -38.8990058899,
  146.909622192, -38.4888381958,
  145.295684814, -37.1971282959,
  145.693145752, -37.2550735474,
  143.777557373, -38.0321502686,
  140.844909668, -37.5863380432,
  139.246795654, -36.2751159668,
  137.557846069, -34.8768386841,
  135.971374512, -33.5515365601,
  134.986022949, -30.7536354065,
  135.391891479, -28.7266750336,
  138.898529053, -31.6799755096,
  136.970123291, -32.4246559143,
  134.045394897, -31.9294757843,
  132.469589233, -30.5915088654,
  130.804458618, -29.1649608612,
  129.240570068, -27.8131103516,
  128.302490234, -24.9989967346,
  128.742492676, -22.9791660309,
  128.8671875, -23.3609848022,
  127.319816589, -21.9902381897,
  126.415992737, -19.1649417877,
  126.880477905, -17.150604248,
  127.382583618, -15.0162162781,
  127.864860535, -13.006064415,
  129.93347168, -10.8800230026,
  131.929672241, -10.3428926468,
  131.929702759, 10.3428726196,
  129.933502197, 10.8800039291,
  127.864898682, 13.0060462952,
  127.382621765, 15.0161962509,
  126.880516052, 17.1505832672,
  126.416030884, 19.1649246216,
  127.319854736, 21.9902210236,
  128.867218018, 23.3609657288,
  128.742523193, 22.9791469574,
  128.30255127, 24.9989795685,
  129.240600586, 27.8130912781,
  130.804504395, 29.1649456024,
  132.46963501, 30.5914897919,
  134.045440674, 31.9294548035,
  136.970169067, 32.4246330261,
  138.898574829, 31.6799545288,
  135.391937256, 28.7266540527,
  134.986053467, 30.7536125183,
  135.971420288, 33.5515174866,
  137.557876587, 34.8768196106,
  139.246826172, 36.2750968933,
  140.844955444, 37.586315155,
  143.777618408, 38.0321311951,
  145.693191528, 37.2550544739,
  145.295730591, 37.1970977783,
  146.90965271, 38.4888114929,
  149.847488403, 38.898979187,
  151.753494263, 38.0986976624,
  153.77142334, 37.2409133911,
  155.670288086, 36.4238090515,
  157.413543701, 34.023765564,
  157.603363037, 31.965303421,
  147.871292114, 30.6640911102,
  147.513671875, 32.7001190186,
  148.565200806, 35.4738349915,
  150.182678223, 36.761089325,
  151.904388428, 38.1188735962,
  153.533203125, 39.3917694092,
  156.475601196, 39.7678146362,
  158.372192383, 38.9454689026,
  157.973449707, 38.8969764709,
  159.617599487, 40.1500015259,
  162.564376831, 40.4902877808,
  164.450836182, 39.6449775696,
  166.447769165, 38.7395172119,
  168.326690674, 37.8775444031,
  170.012496948, 35.4367866516,
  170.153366089, 33.3743972778,
  165.994018555, 35.3027267456,
  167.659057617, 36.5278625488,
  170.61114502, 36.8184394836,
  172.483093262, 35.9414520264,
  174.464523315, 35.0024681091,
  176.32862854, 34.1089515686,
  177.97303772, 31.640127182,
  178.079147339, 29.5756568909,
  177.858535767, 29.9113044739,
  179.711639404, 28.9952163696,
  181.325973511, 26.5066051483,
  181.406997681, 24.4409980774,
  181.48324585, 22.2496700287,
  181.546005249, 20.1834278107,
  180.108596802, 17.5886154175,
  178.323638916, 16.5459270477,
  164.463760376, 23.2618675232,
  164.175964355, 25.3089313507,
  165.321716309, 28.0450706482,
  166.982284546, 29.276260376,
  168.749389648, 30.5743732452,
  170.420776367, 31.7908287048,
  173.374328613, 32.0660438538,
  175.241699219, 31.1793270111,
  174.841522217, 31.1444892883,
  176.527557373, 32.3405570984,
  179.484237671, 32.5798797607,
  181.340698242, 31.6705513,
  183.305526733, 30.6973381042,
  185.15385437, 29.7716236115,
  186.755187988, 27.2746429443,
  186.825485229, 25.2086429596,
  182.734512329, 27.2780723572,
  184.440490723, 28.4455547333,
  187.400756836, 28.6350135803,
  189.241622925, 27.6945266724,
  191.189788818, 26.6883335114,
  193.022262573, 25.7315998077,
  194.581298828, 23.207988739,
  194.616744995, 21.1410961151,
  194.407730103, 21.4840869904,
  196.228439331, 20.5051708221,
  197.756713867, 17.9628105164,
  197.767059326, 15.8956403732,
  197.768325806, 13.7029876709,
  197.760375977, 11.6358070374,
  196.235092163, 9.09166622162,
  194.415527344, 8.11062812805,
  189.405807495, 16.5551052094,
  191.139022827, 17.6817474365,
  194.102966309, 17.8008556366,
  195.920974731, 16.8169193268,
  197.844680786, 15.764749527,
  199.653915405, 14.7647724152,
  201.152572632, 12.2048501968,
  201.138946533, 10.1376991272,
  200.938110352, 10.4855594635,
  202.735076904, 9.46368312836,
  204.202545166, 6.88574886322,
  204.163803101, 4.8189163208,
  204.113006592, 2.62685132027,
  204.055984497, 0.560441911221,
  202.470703125, -1.94676053524,
  200.628341675, -2.88431549072,
  200.773147583, 1.69799184799,
  202.552642822, 0.645974993706,
  203.976455688, -1.95632481575,
  203.902877808, -4.02221107483,
  203.815124512, -6.21310806274,
  203.723297119, -8.27826309204,
  202.095977783, -10.7583913803,
  200.238082886, -11.6647624969,
  200.460510254, -11.3302774429,
  200.343582153, -13.3941650391,
  198.686279297, -15.854347229,
  196.817504883, -16.7380886078,
  194.831176758, -17.6666870117,
  192.954666138, -18.5338630676,
  190.004150391, -18.2278385162,
  188.345550537, -16.9940032959,
  178.289459229, 0.984124600887,
  178.106170654, 3.04317903519,
  179.389633179, 5.71748638153,
  181.110671997, 6.86260890961,
  182.94152832, 8.06914329529,
  184.672637939, 9.19899845123,
  187.636352539, 9.32360076904,
  189.456192017, 8.34303569794,
  189.054779053, 8.32859992981,
  190.799499512, 9.43734836578,
  193.764511108, 9.52595043182,
  195.572296143, 8.52335834503,
  197.485076904, 7.45144844055,
  199.283935547, 6.43290519714,
  200.756164551, 3.85769605637,
  200.7212677, 1.79079532623,
  196.740844727, 4.06566286087,
  198.503997803, 5.14484882355,
  201.470077515, 5.1834654808,
  203.26071167, 4.15054702759,
  205.155166626, 3.04655194283,
  206.93661499, 1.9978351593,
  208.365249634, -0.601821005344,
  208.295471191, -2.66784000397,
  208.104171753, -2.31465888023,
  209.87272644, -3.38493251801,
  211.269714355, -6.00174617767,
  211.174850464, -8.06676673889,
  211.064605713, -10.2566432953,
  210.951522827, -12.3207435608,
  209.298751831, -14.7839946747,
  207.431625366, -15.6711969376,
  202.858047485, -6.98280525208,
  204.646316528, -5.94579124451,
  207.612487793, -5.97761869431,
  209.378082275, -7.05276632309,
  211.245773315, -8.20143508911,
  213.001800537, -9.29215812683,
  214.368286133, -11.9250049591,
  214.24949646, -13.98878479,
  214.066650391, -13.6311597824,
  215.809295654, -14.7431268692,
  217.143692017, -17.3923740387,
  216.999862671, -19.4545593262,
  216.837646484, -21.6412010193,
  216.675567627, -23.7020339966,
  214.964797974, -26.1253471375,
  213.07711792, -26.967962265,
  213.454849243, -22.3989562988,
  215.178512573, -23.5401382446,
  216.468078613, -26.2115020752,
  216.289505005, -28.2709693909,
  216.090438843, -30.4545631409,
  215.893661499, -32.5123710632,
  214.142272949, -34.9065055847,
  212.240692139, -35.7171897888,
  212.479782104, -35.3944511414,
  212.258041382, -37.4497184753,
  210.477722168, -39.8224029541,
  208.566421509, -40.6099357605,
  206.535415649, -41.4362792969,
  204.617218018, -42.2068748474,
  201.686096191, -41.7511405945,
  200.092407227, -40.4345321655,
  201.623626709, -25.1095504761,
  203.446304321, -24.1342926025,
  206.409667969, -24.2675323486,
  208.137481689, -25.402425766,
  209.964813232, -26.6142902374,
  211.682495117, -27.7644233704,
  212.9581604, -30.4424552917,
  212.76890564, -32.5009689331,
  212.598342896, -32.1373023987,
  214.301971436, -33.3082084656,
  215.545028687, -36.001537323,
  215.330749512, -38.0576019287,
  215.093841553, -40.2374153137,
  214.86138916, -42.2915000916,
  213.068740845, -44.6548957825,
  211.153366089, -45.432472229,
  211.687103271, -40.8790550232,
  213.370773315, -42.0785102844,
  214.568222046, -44.7924041748,
  214.319335938, -46.8445587158,
  214.045715332, -49.0200767517,
  213.778686523, -51.0699539185,
  211.946456909, -53.4028015137,
  210.018234253, -54.1479759216,
  210.268264771, -53.83360672,
  209.976364136, -55.8800849915,
  208.115936279, -58.1905136108,
  206.178817749, -58.9122200012,
  204.120773315, -59.6686286926,
  202.177352905, -60.3731803894,
  199.263504028, -59.817489624,
  197.715774536, -58.4471473694,
  203.918075562, -50.8354644775,
  205.572723389, -52.0745620728,
  206.705429077, -54.8161239624,
  206.407867432, -56.8617935181,
  206.082687378, -59.0302009583,
  205.767044067, -61.0731544495,
  203.879928589, -63.3618278503,
  201.934585571, -64.0610122681,
  202.191986084, -63.7526664734,
  201.851547241, -65.7916412354,
  199.936798096, -68.0572280884,
  197.983078003, -68.7327346802,
  195.907669067, -69.4400634766,
  193.948074341, -70.0982666016,
  191.048248291, -69.4735336304,
  189.533493042, -68.0668334961,
  193.885299683, -66.6245117188,
  193.510574341, -68.6574554443,
  191.557891846, -70.8904495239,
  189.593078613, -71.5329284668,
  187.505996704, -72.2051925659,
  185.535583496, -72.8302688599,
  182.646713257, -72.15675354,
  181.155883789, -70.7247238159,
  181.546524048, -70.8182907104,
  179.568649292, -71.4193954468,
  176.688171387, -70.7108535767,
  175.21484375, -69.2608108521,
  173.658905029, -67.7158813477,
  172.198471069, -66.2528610229,
  171.469528198, -63.3774795532,
  172.056594849, -61.395401001,
  175.481155396, -36.9969444275,
  175.462432861, -34.9298362732,
  176.954788208, -32.3662338257,
  178.761550903, -31.3618106842,
  180.682678223, -30.3049125671,
  182.498260498, -29.3165054321,
  185.462509155, -29.4283237457,
  187.198455811, -30.5507030487,
  186.797180176, -30.5331268311,
  188.624633789, -29.5668449402,
  191.587310791, -29.7146511078,
  193.309494019, -30.8580303192,
  195.130844116, -32.0788612366,
  196.84286499, -33.2374267578,
  198.10534668, -35.9217033386,
  197.905944824, -37.9792633057,
  194.119384766, -35.3946266174,
  195.962844849, -34.4592819214,
  198.922607422, -34.6569976807,
  200.625289917, -35.8292388916,
  202.42578125, -37.0805969238,
  204.118057251, -38.2678527832,
  205.335098267, -40.9730300903,
  205.101043701, -43.0269317627,
  204.938476562, -42.6596336365,
  206.616210938, -43.867351532,
  207.800323486, -46.5871047974,
  207.541351318, -48.6380157471,
  207.257034302, -50.8121528625,
  206.979919434, -52.8606910706,
  205.136245728, -55.1844940186,
  203.204376221, -55.9201889038,
  199.337234497, -46.8951530457,
  201.202407837, -46.0038490295,
  204.15663147, -46.2717895508,
  205.830993652, -47.4841346741,
  207.601272583, -48.7778930664,
  209.264846802, -50.0050010681,
  210.417327881, -52.7383117676,
  210.13458252, -54.7860717773,
  209.980789185, -54.4150276184,
  211.629348755, -55.6622467041,
  212.748535156, -58.4093475342,
  212.440933228, -60.4535217285,
  212.105072021, -62.6203079224,
  211.779388428, -64.6616821289,
  209.881057739, -66.9410552979,
  207.932281494, -67.6306686401,
  208.672668457, -63.1062583923,
  210.299972534, -64.3810806274,
  211.372711182, -67.1466598511,
  211.030700684, -69.1853561401,
  210.65838623, -71.3461685181,
  210.298355103, -73.3817672729,
  208.361877441, -75.6288223267,
  206.401748657, -76.2854919434,
  206.665786743, -75.9828186035,
  206.281051636, -78.0139007568,
  204.317459106, -80.2372741699,
  202.349502563, -80.8700866699,
  200.259155273, -81.5320739746,
  198.285690308, -82.1474533081,
  195.400161743, -81.4597396851,
  193.916397095, -80.0203857422,
  196.663253784, -64.8660430908,
  198.557800293, -64.0390319824,
  201.50112915, -64.4078521729,
  203.133071899, -65.6767349243,
  204.858062744, -67.0302734375,
  206.478713989, -68.3135528564,
  207.53704834, -71.0846710205,
  207.184432983, -73.1215667725,
  207.043426514, -72.7454833984,
  208.648361206, -74.0483398438,
  209.672988892, -76.8321075439,
  209.295639038, -78.8645782471,
  208.885879517, -81.0185928345,
  208.490600586, -83.0476455688,
  206.515426636, -85.2607650757,
  204.544219971, -85.8833312988,
  205.43888855, -81.3868942261,
  207.021621704, -82.7166137695,
  207.999176025, -85.517250061,
  207.587646484, -87.5430679321,
  207.14163208, -89.6898880005,
  206.712203979, -91.7119903564,
  204.700027466, -93.8915100098,
  202.718597412, -94.4807662964,
  202.992843628, -94.1873016357,
  202.538864136, -96.2040405273,
  200.500366211, -98.3589477539,
  198.511932373, -98.9241027832,
  196.400177002, -99.5142211914,
  194.406814575, -100.061767578,
  191.546524048, -99.2757873535,
  190.112838745, -97.786529541,
  196.901596069, -90.6929626465,
  198.452331543, -92.0598983765,
  199.363082886, -94.8829650879,
  198.903564453, -96.8984375,
  198.406738281, -99.0340499878,
  197.929412842, -101.045379639,
  195.866027832, -103.176513672,
  193.871154785, -103.718551636,
  194.152297974, -103.431678772,
  193.650588989, -105.437072754,
  191.561477661, -107.542976379,
  189.560165405, -108.060745239,
  187.4349823, -108.60055542,
  185.42918396, -109.100601196,
  182.58833313, -108.246932983,
  181.19039917, -106.724060059,
  185.643295288, -105.632881165,
  185.107833862, -107.62953186,
  182.983520508, -109.699920654,
  180.9737854, -110.183891296,
  178.839828491, -110.687797546,
  176.825897217, -111.153968811,
  173.999816895, -110.25252533,
  172.6277771, -108.706314087,
  173.00970459, -108.830696106,
  170.990249634, -109.272377014,
  168.175338745, -108.336677551,
  166.822174072, -106.773925781,
  165.394226074, -105.109985352,
  164.054946899, -103.535324097,
  163.557296753, -100.61100769,
  164.300354004, -98.681968689,
  178.917160034, -84.1669311523,
  180.851348877, -83.4373779297,
  183.772109985, -83.9554519653,
  185.337387085, -85.3057174683,
  186.99130249, -86.745262146,
  188.544555664, -88.1093215942,
  189.46055603, -90.9306945801,
  189.004776001, -92.947013855,
  188.883071899, -92.5642242432,
  190.419647217, -93.9470443726,
  191.301315308, -96.7793426514,
  190.821075439, -98.7899780273,
  190.302276611, -100.920372009,
  189.804275513, -102.926681519,
  187.719085693, -105.036460876,
  185.71875, -105.557945251,
  186.841003418, -101.112831116,
  188.3540802, -102.521347046,
  189.187866211, -105.368080139,
  188.673812866, -107.370346069,
  188.119186401, -109.491699219,
  187.58744812, -111.489334106,
  185.466995239, -113.563659668,
  183.458145142, -114.051353455,
  183.746963501, -113.772224426,
  183.190994263, -115.763267517,
  181.045471191, -117.811683655,
  179.03086853, -118.27495575,
  176.891845703, -118.756874084,
  174.873214722, -119.202293396,
  172.05657959, -118.271820068,
  170.700500488, -116.711578369,
  177.84135437, -109.972541809,
  179.320541382, -111.416595459,
  180.086517334, -114.282333374,
  179.525054932, -116.271820068,
  178.920196533, -118.379394531,
  178.341156006, -120.363845825,
  176.172058105, -122.387237549,
  174.152191162, -122.827095032,
  174.44758606, -122.554901123,
  173.844497681, -124.532165527,
  171.650970459, -126.529067993,
  169.625915527, -126.944366455,
  167.476043701, -127.375366211,
  165.447387695, -127.772727966,
  162.653656006, -126.775634766,
  161.335006714, -125.183609009,
  165.837615967, -124.320365906,
  165.201293945, -126.287193298,
  162.97442627, -128.246841431,
  160.942672729, -128.627944946,
  158.785827637, -129.022644043,
  156.750762939, -129.385742188,
  153.974227905, -128.341720581,
  152.682617188, -126.727706909,
  153.057693481, -126.871368408,
  151.018386841, -127.209739685,
  148.254730225, -126.132064819,
  146.982803345, -124.502494812,
  145.641357422, -122.768074036,
  144.383911133, -121.127296448,
  144.035690308, -118.181465149,
  144.875900269, -116.292732239,
  160.049102783, -113.651870728,
  161.478042603, -115.14566803,
  162.145584106, -118.035919189,
  161.516403198, -120.00504303,
  160.839828491, -122.090698242,
  160.193252563, -124.054191589,
  157.956237793, -126.002220154,
  155.92250061, -126.372749329,
  156.227035522, -126.110809326,
  155.556686401, -128.066299438,
  153.296142578, -129.987045288,
  151.258087158, -130.332839966,
  149.094726562, -130.690063477,
  147.05368042, -131.017822266,
  144.295669556, -129.92578125,
  143.03225708, -128.289611816,
  147.561721802, -127.580825806,
  146.858520508, -129.524734497,
  144.565917969, -131.407104492,
  142.522323608, -131.718505859,
  140.353240967, -132.039215088,
  138.306976318, -132.332519531,
  135.567749023, -131.194152832,
  134.332092285, -129.53692627,
  134.702072144, -129.693313599,
  132.652374268, -129.961761475,
  129.927185059, -128.790206909,
  128.711730957, -127.118080139,
  127.430358887, -125.338806152,
  126.229766846, -123.656005859,
  125.982452393, -120.699981689,
  126.886779785, -118.841079712,
  135.884567261, -122.771224976,
  135.135375977, -124.697891235,
  132.79876709, -126.525276184,
  130.748336792, -126.788070679,
  128.572250366, -127.057182312,
  126.519584656, -127.301818848,
  123.808174133, -126.098724365,
  122.612205505, -124.412612915,
  122.978363037, -124.577758789,
  120.922874451, -124.797447205,
  118.226280212, -123.561508179,
  117.050880432, -121.86100769,
  115.812133789, -120.051795959,
  114.651824951, -118.340950012,
  114.47479248, -115.379905701,
  115.422988892, -113.542991638,
  117.98109436, -117.347541809,
  115.922172546, -117.532554626,
  113.246795654, -116.251365662,
  112.100227356, -114.531280518,
  110.89214325, -112.701446533,
  109.76083374, -110.971298218,
  109.633735657, -108.00769043,
  110.612754822, -106.187034607,
  110.627540588, -106.588417053,
  109.517326355, -104.844642639,
  109.426216125, -101.879707336,
  110.427276611, -100.071075439,
  111.497581482, -98.1573867798,
  112.514602661, -96.3576812744,
  115.088569641, -94.8832626343,
  117.155502319, -94.9164352417,
  136.946395874, -77.7770004272,
  137.208847046, -75.7265396118,
  139.035842896, -73.3896026611,
  140.962387085, -72.6400985718,
  143.009338379, -71.8541793823,
  144.942428589, -71.1217575073,
  147.863937378, -71.6354751587,
  149.431228638, -72.9834213257,
  149.036056519, -72.9114608765,
  150.977905273, -72.2025680542,
  153.892974854, -72.7517471313,
  155.443771362, -74.1186218262,
  157.082244873, -75.5756988525,
  158.620864868, -76.9562454224,
  159.506744385, -79.7872238159,
  159.02947998, -81.798576355,
  155.62940979, -78.723197937,
  157.582931519, -78.0471343994,
  160.488327026, -78.6453552246,
  162.015869141, -80.0381698608,
  163.629562378, -81.5226821899,
  165.144729614, -82.9289550781,
  165.982727051, -85.7744522095,
  165.47164917, -87.7774734497,
  165.360534668, -87.3914871216,
  166.85848999, -88.8160552979,
  167.661895752, -91.6715393066,
  167.12651062, -93.6682052612,
  166.549285889, -95.7835083008,
  165.996292114, -97.7753601074,
  163.85383606, -99.8269729614,
  161.839920044, -100.29322052,
  159.235488892, -90.8262710571,
  161.20451355, -90.1967849731,
  164.09487915, -90.8638381958,
  165.588912964, -92.2925262451,
  167.166885376, -93.8149261475,
  168.648223877, -95.2567825317,
  169.418441772, -98.121383667,
  168.859954834, -100.111701965,
  168.757995605, -99.723197937,
  170.221710205, -101.182937622,
  170.957092285, -104.056678772,
  170.374465942, -106.04006958,
  169.747177124, -108.141082764,
  169.147033691, -110.119239807,
  166.956451416, -112.119392395,
  164.932022095, -112.537689209,
  166.280578613, -108.155921936,
  167.719482422, -109.64012146,
  168.406326294, -112.525863647,
  167.790344238, -114.499153137,
  167.127761841, -116.589294434,
  166.494338989, -118.557052612,
  164.270385742, -120.520011902,
  162.239181519, -120.904121399,
  162.541915894, -120.640151978,
  161.884658813, -122.600074768,
  159.637023926, -124.535888672,
  157.601318359, -124.895309448,
  155.440383911, -125.266990662,
  153.401580811, -125.608383179,
  150.636322021, -124.534805298,
  149.361984253, -122.90712738,
  154.1434021, -108.266838074,
  156.132797241, -107.705055237,
  158.998672485, -108.470550537,
  160.442977905, -109.949501038,
  161.967956543, -111.524963379,
  163.399124146, -113.016639709,
  164.070953369, -115.905891418,
  163.444717407, -117.875953674,
  163.356109619, -117.484176636,
  164.769042969, -118.993125916,
  165.405731201, -121.890335083,
  164.755599976, -123.852638245,
  164.05682373, -125.930953979,
  163.389373779, -127.887443542,
  161.131729126, -129.811523438,
  159.094161987, -130.160354614,
  160.591766357, -125.827270508,
  161.9790802, -127.359809875,
  162.566833496, -130.267333984,
  161.883743286, -132.218414307,
  161.150039673, -134.28465271,
  160.449707031, -136.229598999,
  158.159927368, -138.115371704,
  156.116790771, -138.429794312,
  156.428390503, -138.176345825,
  155.704483032, -140.11265564,
  153.391967773, -141.970458984,
  151.345153809, -142.260040283,
  149.172805786, -142.557632446,
  147.123519897, -142.829116821,
  144.396591187, -141.661621094,
  143.178665161, -139.99130249,
  150.868682861, -133.886428833,
  152.219238281, -135.451477051,
  152.737762451, -138.372146606,
  152.008529663, -140.306442261,
  151.225982666, -142.354705811,
  150.479675293, -144.282470703,
  148.145736694, -146.113327026,
  146.095703125, -146.379165649,
  146.413238525, -146.133163452,
  145.643569946, -148.051727295,
  143.287597656, -149.854095459,
  141.234481812, -150.095031738,
  139.055664062, -150.340927124,
  137.00050354, -150.563674927,
  134.302062988, -149.331741333,
  133.124145508, -147.632980347,
  137.684005737, -147.157226562,
  136.882095337, -149.062545776,
  134.496078491, -150.824951172,
  132.439208984, -151.031234741,
  130.256561279, -151.240371704,
  128.197937012, -151.428451538,
  125.520645142, -150.1512146,
  124.371513367, -148.432830811,
  124.732971191, -148.60798645,
  122.672225952, -148.771057129,
  120.010635376, -147.461410522,
  118.882469177, -145.729187012,
  117.693984985, -143.886581421,
  116.581176758, -142.144470215,
  116.485671997, -139.179656982,
  117.484054565, -137.369552612,
  133.938323975, -124.976219177,
  135.953735352, -124.516365051,
  138.776947021, -125.42665863,
  140.144134521, -126.977172852,
  141.587036133, -128.628189087,
  142.940444946, -130.190734863,
  143.464401245, -133.110427856,
  142.738769531, -135.046081543,
  142.670211792, -134.650299072,
  144.004562378, -136.229171753,
  144.493026733, -139.155014038,
  143.743927002, -141.081710815,
  142.940322876, -143.121795654,
  142.174224854, -145.041793823,
  139.821594238, -146.848526001,
  137.768936157, -147.09324646,
  139.485046387, -142.841964722,
  140.79258728, -144.443099976,
  141.231674194, -147.376754761,
  140.450210571, -149.290557861,
  139.612365723, -151.316802979,
  138.813995361, -153.223617554,
  136.431243896, -154.990432739,
  134.374740601, -155.200531006,
  134.69883728, -154.963226318,
  133.877380371, -156.860214233,
  131.473342896, -158.597991943,
  129.41444397, -158.783081055,
  127.229766846, -158.96975708,
  125.169303894, -159.136657715,
  122.505310059, -157.83190918,
  121.373924255, -156.101806641,
  129.364547729, -150.396011353,
  130.633712769, -152.027755737,
  131.003036499, -154.97102356,
  130.176345825, -156.865707397,
  129.290588379, -158.871520996,
  128.447174072, -160.758834839,
  126.023139954, -162.468566895,
  123.962234497, -162.629760742,
  124.291862488, -162.400253296,
  123.425590515, -164.277175903,
  120.980979919, -165.957382202,
  118.918266296, -166.093521118,
  116.729759216, -166.228271484,
  114.665931702, -166.346160889,
  112.033668518, -164.978561401,
  110.943687439, -163.222091675,
  115.521835327, -162.978927612,
  114.624046326, -164.840988159,
  112.151451111, -166.479736328,
  110.086738586, -166.581100464,
  107.896270752, -166.678955078,
  105.830749512, -166.762054443,
  103.221908569, -165.350265503,
  102.161689758, -163.575668335,
  102.513778687, -163.768981934,
  100.447395325, -163.82699585,
  97.8558883667, -162.383636475,
  96.8172988892, -160.596298218,
  95.724067688, -158.695602417,
  94.7013320923, -156.899139404,
  94.7567749023, -153.933288574,
  95.8459625244, -152.176315308,
  111.237289429, -151.622543335,
  112.449905396, -153.296722412,
  112.718345642, -156.250915527,
  111.827339172, -158.116226196,
  110.873535156, -160.090545654,
  109.966064453, -161.947921753,
  107.484985352, -163.573776245,
  105.419784546, -163.664413452,
  105.757049561, -163.446304321,
  104.827110291, -165.292526245,
  102.326469421, -166.888122559,
  100.260307312, -166.953643799,
  98.0684738159, -167.01348877,
  96.0018157959, -167.060760498,
  93.4178543091, -165.603912354,
  92.3885726929, -163.811203003,
  96.9723510742, -163.724761963,
  96.0114212036, -165.555007935,
  93.4842376709, -167.108261108,
  91.4172744751, -167.138961792,
  89.2247390747, -167.161834717,
  87.1575775146, -167.174255371,
  84.5985412598, -165.674087524,
  83.5996246338, -163.864273071,
  83.9449005127, -164.069519043,
  81.877746582, -164.056854248,
  79.337097168, -162.525726318,
  78.3602294922, -160.703903198,
  77.332649231, -158.766937256,
  76.3719329834, -156.936553955,
  76.5287628174, -153.974349976,
  77.6773834229, -152.255645752,
  86.0574111938, -157.372406006,
  85.053276062, -159.179351807,
  82.4899291992, -160.672134399,
  80.4228134155, -160.653747559,
  78.2303543091, -160.624557495,
  76.163482666, -160.587905884,
  73.6407775879, -159.027389526,
  72.6851196289, -157.194351196,
  73.0254364014, -157.407730103,
  70.9591598511, -157.345977783,
  68.4555969238, -155.754943848,
  67.522277832, -153.910446167,
  66.5409698486, -151.949615479,
  65.6239929199, -150.096939087,
  65.8511199951, -147.139297485,
  67.0402297974, -145.448348999,
  69.0574111938, -149.565322876,
  66.9924697876, -149.468765259,
  64.5160827637, -147.835754395,
  63.6139755249, -145.975784302,
  62.6658554077, -143.998703003,
  61.7802391052, -142.130828857,
  62.057182312, -139.177444458,
  63.2746124268, -137.506774902,
  63.2346916199, -137.906448364,
  62.3718147278, -136.027954102,
  62.6846122742, -133.078155518,
  63.9222450256, -131.422393799,
  65.2427520752, -129.671951294,
  66.4949874878, -128.027191162,
  69.2454833984, -126.916381836,
  71.2887268066, -127.230209351,
  95.9261398315, -127.326278687,
  97.9717559814, -127.028388977,
  100.713516235, -128.160614014,
  101.952880859, -129.815093994,
  103.259689331, -131.575759888,
  104.484382629, -133.241134644,
  104.774162292, -136.193283081,
  103.89667511, -138.064987183,
  103.859870911, -137.665023804,
  105.064247131, -139.345123291,
  105.318153381, -142.300582886,
  104.417999268, -144.161499023,
  103.454490662, -146.131118774,
  102.537902832, -147.984008789,
  100.048858643, -149.597640991,
  97.9832305908, -149.678115845,
  100.032447815, -145.57699585,
  101.208335876, -147.277160645,
  101.412391663, -150.236480713,
  100.480995178, -152.081954956,
  99.4844360352, -154.035079956,
  98.5367507935, -155.872238159,
  96.0208587646, -157.443695068,
  93.9541625977, -157.489349365,
  94.2961196899, -157.278640747,
  93.3261947632, -159.104156494,
  90.7914199829, -160.644958496,
  88.7243270874, -160.665496826,
  86.5317077637, -160.677581787,
  84.4645080566, -160.679840088,
  81.9128799438, -159.167114258,
  80.9228744507, -157.352401733,
  89.3425216675, -152.301101685,
  90.4777069092, -154.028717041,
  90.6114425659, -156.99206543,
  89.6364822388, -158.814910889,
  88.5938186646, -160.743789673,
  87.6027908325, -162.557937622,
  85.0502853394, -164.069213867,
  82.9830932617, -164.065750122,
  83.3299560547, -163.863235474,
  82.3169555664, -165.66519165,
  79.7462921143, -167.145370483,
  77.6792984009, -167.116836548,
  75.4869995117, -167.076858521,
  73.4203414917, -167.030029297,
  70.9053497314, -165.457122803,
  69.9587173462, -163.619430542,
  74.5416793823, -163.741622925,
  73.4984512329, -165.526275635,
  70.903213501, -166.962905884,
  68.8369903564, -166.899520874,
  66.6456832886, -166.822616577,
  64.5800933838, -166.740966797,
  62.0919685364, -165.125900269,
  61.1764411926, -163.272506714,
  61.5120201111, -163.493225098,
  59.4475860596, -163.386489868,
  56.9792518616, -161.741348267,
  56.0863037109, -159.876953125,
  55.1479148865, -157.895248413,
  54.2714920044, -156.023025513,
  54.562953949, -153.071060181,
  55.7885932922, -151.406387329,
  71.1751327515, -152.080123901,
  72.250579834, -153.845535278,
  72.2828903198, -156.811737061,
  71.2461776733, -158.600158691,
  70.138168335, -160.492279053,
  69.0856704712, -162.271484375,
  66.4829864502, -163.694580078,
  64.4171218872, -163.620437622,
  64.7707138062, -163.429885864,
  63.6966896057, -165.196182251,
  61.0769119263, -166.587585449,
  59.0121002197, -166.488372803,
  56.8224639893, -166.373443604,
  54.758605957, -166.255966187,
  52.298866272, -164.597991943,
  51.4156341553, -162.729003906,
  55.9917373657, -163.007827759,
  54.8880882263, -164.755767822,
  52.2452392578, -166.102813721,
  50.1823959351, -165.968811035,
  47.9949989319, -165.817001343,
  45.9334182739, -165.664749146,
  43.5019760132, -163.965576172,
  42.6503639221, -162.081939697,
  42.9781951904, -162.314025879,
  40.9186096191, -162.136764526,
  38.5079803467, -160.408157349,
  37.6793060303, -158.514312744,
  36.8092308044, -156.501693726,
  35.997341156, -154.600616455,
  36.3895797729, -151.660308838,
  37.6714248657, -150.038513184,
  45.6173362732, -155.806427002,
  44.4724960327, -157.527679443,
  41.7984046936, -158.811584473,
  39.7393188477, -158.628646851,
  37.5561523438, -158.424926758,
  35.4987640381, -158.2237854,
  33.1083564758, -156.467330933,
  32.3017082214, -154.56401062,
  32.6239433289, -154.803817749,
  30.569152832, -154.577697754,
  28.2002506256, -152.792327881,
  27.4167804718, -150.879364014,
  26.5947399139, -148.846618652,
  25.8282241821, -146.92678833,
  26.2901744843, -143.996643066,
  27.610168457, -142.405761719,
  29.2930736542, -146.670303345,
  27.2423858643, -146.409591675,
  24.9039115906, -144.584564209,
  24.1527938843, -142.65864563,
  23.3651332855, -140.612350464,
  22.6310787201, -138.679870605,
  23.1423473358, -135.757919312,
  24.4889678955, -134.18951416,
  24.417339325, -134.584747314,
  23.7068099976, -132.643493652,
  24.2535228729, -129.727981567,
  25.6190910339, -128.176025391,
  27.074798584, -126.536323547,
  28.4540424347, -124.996505737,
  31.2842712402, -124.108253479,
  33.2960205078, -124.583808899,
  49.6531677246, -137.105072021,
  50.6374053955, -138.922912598,
  50.5187911987, -141.886886597,
  49.3924331665, -143.620269775,
  48.1896057129, -145.453567505,
  47.0479621887, -147.1769104,
  44.376247406, -148.465774536,
  42.3168296814, -148.286651611,
  42.6796531677, -148.114334106,
  41.5171661377, -149.823699951,
  38.8299980164, -151.080032349,
  36.7729072571, -150.875915527,
  34.5919456482, -150.649734497,
  32.5367393494, -150.427429199,
  30.1645278931, -148.646453857,
  29.3775157928, -146.734939575,
  33.9335098267, -147.24621582,
  32.7423782349, -148.935745239,
  30.0344219208, -150.146606445,
  27.9810600281, -149.907836914,
  25.8042221069, -149.644958496,
  23.7530555725, -149.388031006,
  21.4111995697, -147.567352295,
  20.6565132141, -145.642837524,
  20.9721126556, -145.891281128,
  18.9242172241, -145.609481812,
  16.6046447754, -143.760482788,
  15.8733863831, -141.826950073,
  15.1068258286, -139.772674561,
  14.392698288, -137.832733154,
  14.934006691, -134.91619873,
  16.2966957092, -133.361724854,
  23.9388904572, -139.526397705,
  22.7079734802, -141.187164307,
  19.972026825, -142.333374023,
  17.9249134064, -142.045913696,
  15.7549343109, -141.731399536,
  13.7104454041, -141.425857544,
  11.412484169, -139.550064087,
  10.7037086487, -137.608184814,
  11.0133228302, -137.864059448,
  8.97269535065, -137.533691406,
  6.69768428802, -135.630142212,
  6.01254701614, -133.679794312,
  5.29498338699, -131.60786438,
  4.62712287903, -129.651535034,
  5.23753595352, -126.748695374,
  6.63675260544, -125.227012634,
  8.10053730011, -129.571640015,
  6.06576728821, -129.206939697,
  3.82316160202, -127.265304565,
  3.17099118233, -125.303688049,
  2.48845005035, -123.219970703,
  1.85365629196, -121.252655029,
  2.51290631294, -118.360496521,
  3.9375705719, -116.862609863,
  3.84592604637, -117.253707886,
  3.23507094383, -115.278816223,
  3.92939639091, -112.394874573,
  5.37214565277, -110.914413452,
  6.90938472748, -109.350883484,
  8.36517429352, -107.883232117,
  11.2369251251, -107.140106201,
  13.2218799591, -107.717399597,
  17.8889503479, -122.394508362,
  16.6019611359, -124.012207031,
  13.828420639, -125.064201355,
  11.7923355103, -124.70690155,
  9.63437843323, -124.318374634,
  7.60153388977, -123.943092346,
  5.36906099319, -121.989822388,
  4.72710609436, -120.024833679,
  5.02778863907, -120.291152954,
  2.9996509552, -119.891220093,
  0.791064798832, -117.910972595,
  0.173020735383, -115.938331604,
  -0.473272502422, -113.843093872,
  -1.0738440752, -111.865058899,
  -0.364522784948, -108.984764099,
  1.08591008186, -107.51184082,
  2.40026831627, -111.90397644,
  0.379159390926, -111.469909668,
  -1.79573893547, -109.452728271,
  -2.38044881821, -107.469947815,
  -2.99133682251, -105.364112854,
  -3.55848574638, -103.376235962,
  -2.80072164536, -100.50831604,
  -1.32566988468, -99.060043335,
  -1.4306344986, -99.4477386475,
  -1.97360038757, -97.453125,
  -1.18106281757, -94.5946273804,
  0.311468243599, -93.1643753052,
  1.90127432346, -91.6543121338,
  3.40639829636, -90.2373123169,
  6.30188179016, -89.5928192139,
  8.26593494415, -90.2376480103,
  5.58777284622, -99.6839981079,
  3.57754063606, -99.2020568848,
  1.45115506649, -97.1338043213,
  0.913693070412, -95.1377029419,
  0.352982074022, -93.0179595947,
  -0.166803136468, -91.0171737671,
  0.658848881721, -88.1680526733,
  2.16787552834, -86.7552032471,
  2.05373477936, -87.1403121948,
  1.55828630924, -85.1333694458,
  2.4184782505, -82.2944793701,
  3.94455170631, -80.900062561,
  5.56976652145, -79.4281845093,
  7.10811471939, -78.0473175049,
  10.0180864334, -77.4717636108,
  11.9662742615, -78.1630477905,
  8.54231452942, -81.2118301392,
  8.08076190948, -79.1968231201,
  8.98867797852, -76.3728408813,
  10.5380363464, -75.0043411255,
  12.1878271103, -73.5600662231,
  13.7492284775, -72.205329895,
  16.6684875488, -71.6789093018,
  18.6047477722, -72.4029159546,
  18.209028244, -72.4718017578,
  19.7867698669, -71.1361160278,
  22.7122058868, -70.6451873779,
  24.6395282745, -71.3926620483,
  26.6802940369, -72.1945343018,
  28.600933075, -72.9590301514,
  30.4096508026, -75.3101425171,
  30.6561031342, -77.3625946045
]
