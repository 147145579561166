// libs
import * as THREE from 'three'

// base geometry class
import Base from '../base/Base'

// shaders
import fragmentShader from './shaders/crystal.frag'
import vertexShader from './shaders/crystal.vert'

export default class Crystal extends Base {
  constructor (args) {
    super(args)

    this.normalMap = new THREE.TextureLoader().load('assets/images/textures/normalMap.jpg')

    this.normalMap.minFilter = THREE.NearestFilter
    this.instanceTotal = 9 * 4000

    if (this.config.detector.isMobile) {
      this.instanceTotal = 3 * 4000
    }

    this.txCount = 0
    this.txIndexOffsets = {}

    this.cubeMap = new THREE.CubeTextureLoader()
      .setPath('assets/images/textures/cubemaps/playa-full/')
      .load([
        '0004.jpg',
        '0002.jpg',
        '0006.jpg',
        '0005.jpg',
        '0001.jpg',
        '0003.jpg'
      ])

    this.material = new CrystalMaterial({
      flatShading: true,
      opacity: 1.0,
      color: 0xffffff,
      emissive: 0x000000,
      metalness: 1.0,
      roughness: 0.2,
      transparent: true,
      side: THREE.FrontSide,
      envMap: this.cubeMap,
      normalMap: this.normalMap,
      normalScale: new THREE.Vector2(0.01, 0.01),
      roughnessMap: this.roughnessMap
    })
  }

  async init (blockGeoData) {
    this.offsetsArray = new Float32Array(this.instanceTotal * 3)
    this.offsetsArray2D = new Float32Array(this.instanceTotal * 2)
    this.txValuesArray = new Float32Array(this.instanceTotal)

    this.scalesArray = new Float32Array(this.instanceTotal)
    this.quatArray = new Float32Array(this.instanceTotal * 4)
    this.isHovered = new Float32Array(this.instanceTotal)
    this.isSelected = new Float32Array(this.instanceTotal)

    const blockPosition = blockGeoData.blockData.pos

    const positions = new Float32Array([
      0,
      0.5199999809265137,
      1,
      0,
      -0.5,
      1,
      0.8660253882408142,
      0.5199999809265137,
      0.5,
      0,
      -0.5,
      1,
      0.8660253882408142,
      -0.5,
      0.5,
      0.8660253882408142,
      0.5199999809265137,
      0.5,
      0.8660253882408142,
      0.5199999809265137,
      0.5,
      0.8660253882408142,
      -0.5,
      0.5,
      0.8660253882408142,
      0.5099999904632568,
      -0.5,
      0.8660253882408142,
      -0.5,
      0.5,
      0.8660253882408142,
      -0.5,
      -0.5,
      0.8660253882408142,
      0.5099999904632568,
      -0.5,
      0.8660253882408142,
      0.5099999904632568,
      -0.5,
      0.8660253882408142,
      -0.5,
      -0.5,
      1.2246468525851679e-16,
      0.5,
      -1,
      0.8660253882408142,
      -0.5,
      -0.5,
      1.2246468525851679e-16,
      -0.5,
      -1,
      1.2246468525851679e-16,
      0.5,
      -1,
      1.2246468525851679e-16,
      0.5,
      -1,
      1.2246468525851679e-16,
      -0.5,
      -1,
      -0.8660253882408142,
      0.49000000953674316,
      -0.5,
      1.2246468525851679e-16,
      -0.5,
      -1,
      -0.8660253882408142,
      -0.5,
      -0.5,
      -0.8660253882408142,
      0.49000000953674316,
      -0.5,
      -0.8660253882408142,
      0.49000000953674316,
      -0.5,
      -0.8660253882408142,
      -0.5,
      -0.5,
      -0.8660253882408142,
      0.5,
      0.5,
      -0.8660253882408142,
      -0.5,
      -0.5,
      -0.8660253882408142,
      -0.5,
      0.5,
      -0.8660253882408142,
      0.5,
      0.5,
      -0.8660253882408142,
      0.5,
      0.5,
      -0.8660253882408142,
      -0.5,
      0.5,
      0,
      0.5199999809265137,
      1,
      -0.8660253882408142,
      -0.5,
      0.5,
      0,
      -0.5,
      1,
      0,
      0.5199999809265137,
      1,
      0,
      0.5199999809265137,
      1,
      0.8660253882408142,
      0.5199999809265137,
      0.5,
      0,
      0.5099999904632568,
      0,
      0.8660253882408142,
      0.5199999809265137,
      0.5,
      0.8660253882408142,
      0.5099999904632568,
      -0.5,
      0,
      0.5099999904632568,
      0,
      0.8660253882408142,
      0.5099999904632568,
      -0.5,
      1.2246468525851679e-16,
      0.5,
      -1,
      0,
      0.5099999904632568,
      0,
      1.2246468525851679e-16,
      0.5,
      -1,
      -0.8660253882408142,
      0.49000000953674316,
      -0.5,
      0,
      0.5099999904632568,
      0,
      -0.8660253882408142,
      0.49000000953674316,
      -0.5,
      -0.8660253882408142,
      0.5,
      0.5,
      0,
      0.5099999904632568,
      0,
      -0.8660253882408142,
      0.5,
      0.5,
      0,
      0.5199999809265137,
      1,
      0,
      0.5099999904632568,
      0,
      0.8660253882408142,
      -0.5,
      0.5,
      0,
      -0.5,
      1,
      0,
      -0.5,
      0,
      0.8660253882408142,
      -0.5,
      -0.5,
      0.8660253882408142,
      -0.5,
      0.5,
      0,
      -0.5,
      0,
      1.2246468525851679e-16,
      -0.5,
      -1,
      0.8660253882408142,
      -0.5,
      -0.5,
      0,
      -0.5,
      0,
      -0.8660253882408142,
      -0.5,
      -0.5,
      1.2246468525851679e-16,
      -0.5,
      -1,
      0,
      -0.5,
      0,
      -0.8660253882408142,
      -0.5,
      0.5,
      -0.8660253882408142,
      -0.5,
      -0.5,
      0,
      -0.5,
      0,
      0,
      -0.5,
      1,
      -0.8660253882408142,
      -0.5,
      0.5,
      0,
      -0.5,
      0
    ])

    const normals = new Float32Array([
      0,
      0,
      1,
      0,
      0,
      1,
      0.8660253882408142,
      0,
      0.5,
      0,
      0,
      1,
      0.8660253882408142,
      0,
      0.5,
      0.8660253882408142,
      0,
      0.5,
      0.8660253882408142,
      0,
      0.5,
      0.8660253882408142,
      0,
      0.5,
      0.8660253882408142,
      0,
      -0.5,
      0.8660253882408142,
      0,
      0.5,
      0.8660253882408142,
      0,
      -0.5,
      0.8660253882408142,
      0,
      -0.5,
      0.8660253882408142,
      0,
      -0.5,
      0.8660253882408142,
      0,
      -0.5,
      1.2246468525851679e-16,
      0,
      -1,
      0.8660253882408142,
      0,
      -0.5,
      1.2246468525851679e-16,
      0,
      -1,
      1.2246468525851679e-16,
      0,
      -1,
      1.2246468525851679e-16,
      0,
      -1,
      1.2246468525851679e-16,
      0,
      -1,
      -0.8660253882408142,
      0,
      -0.5,
      1.2246468525851679e-16,
      0,
      -1,
      -0.8660253882408142,
      0,
      -0.5,
      -0.8660253882408142,
      0,
      -0.5,
      -0.8660253882408142,
      0,
      -0.5,
      -0.8660253882408142,
      0,
      -0.5,
      -0.8660253882408142,
      0,
      0.5,
      -0.8660253882408142,
      0,
      -0.5,
      -0.8660253882408142,
      0,
      0.5,
      -0.8660253882408142,
      0,
      0.5,
      -0.8660253882408142,
      0,
      0.5,
      -0.8660253882408142,
      0,
      0.5,
      -2.4492937051703357e-16,
      0,
      1,
      -0.8660253882408142,
      0,
      0.5,
      -2.4492937051703357e-16,
      0,
      1,
      -2.4492937051703357e-16,
      0,
      1,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0,
      0,
      -1,
      0
    ])

    const UVs = new Float32Array([
      0,
      1,
      0,
      0,
      0.1666666716337204,
      1,
      0,
      0,
      0.1666666716337204,
      0,
      0.1666666716337204,
      1,
      0.1666666716337204,
      1,
      0.1666666716337204,
      0,
      0.3333333432674408,
      1,
      0.1666666716337204,
      0,
      0.3333333432674408,
      0,
      0.3333333432674408,
      1,
      0.3333333432674408,
      1,
      0.3333333432674408,
      0,
      0.5,
      1,
      0.3333333432674408,
      0,
      0.5,
      0,
      0.5,
      1,
      0.5,
      1,
      0.5,
      0,
      0.6666666865348816,
      1,
      0.5,
      0,
      0.6666666865348816,
      0,
      0.6666666865348816,
      1,
      0.6666666865348816,
      1,
      0.6666666865348816,
      0,
      0.8333333134651184,
      1,
      0.6666666865348816,
      0,
      0.8333333134651184,
      0,
      0.8333333134651184,
      1,
      0.8333333134651184,
      1,
      0.8333333134651184,
      0,
      1,
      1,
      0.8333333134651184,
      0,
      1,
      0,
      1,
      1,
      1,
      0.5,
      0.75,
      0.9330127239227295,
      0.5,
      0.5,
      0.75,
      0.9330127239227295,
      0.25,
      0.9330127239227295,
      0.5,
      0.5,
      0.25,
      0.9330127239227295,
      0,
      0.5,
      0.5,
      0.5,
      0,
      0.5,
      0.25,
      0.0669872984290123,
      0.5,
      0.5,
      0.25,
      0.0669872984290123,
      0.75,
      0.0669872984290123,
      0.5,
      0.5,
      0.75,
      0.0669872984290123,
      1,
      0.5,
      0.5,
      0.5,
      0.75,
      0.0669872984290123,
      1,
      0.5,
      0.5,
      0.5,
      0.25,
      0.0669872984290123,
      0.75,
      0.0669872984290123,
      0.5,
      0.5,
      0,
      0.5,
      0.25,
      0.0669872984290123,
      0.5,
      0.5,
      0.25,
      0.9330127239227295,
      0,
      0.5,
      0.5,
      0.5,
      0.75,
      0.9330127239227295,
      0.25,
      0.9330127239227295,
      0.5,
      0.5,
      1,
      0.5,
      0.75,
      0.9330127239227295,
      0.5,
      0.5
    ])

    this.geometry = new THREE.InstancedBufferGeometry()

    this.geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
    this.geometry.setAttribute('normal', new THREE.BufferAttribute(normals, 3))
    this.geometry.setAttribute('uv', new THREE.BufferAttribute(UVs, 2))

    this.geometry.instanceCount = this.instanceTotal

    // attributes
    const spentRatios = new THREE.InstancedBufferAttribute(new Float32Array(this.instanceTotal), 1)
    const txTimes = new THREE.InstancedBufferAttribute(new Float32Array(this.instanceTotal), 1)
    const offsets = new THREE.InstancedBufferAttribute(this.offsetsArray, 3)
    const scales = new THREE.InstancedBufferAttribute(this.scalesArray, 1)
    const quaternions = new THREE.InstancedBufferAttribute(this.quatArray, 4)
    const isHovered = new THREE.InstancedBufferAttribute(this.isHovered, 1)
    const isSelected = new THREE.InstancedBufferAttribute(this.isSelected, 1)
    const blockStartTimes = new THREE.InstancedBufferAttribute(new Float32Array(this.instanceTotal), 1)
    const blockLoadTimes = new THREE.InstancedBufferAttribute(new Float32Array(this.instanceTotal), 1)

    const object = new THREE.Object3D()
    object.position.set(blockPosition.x, 0, blockPosition.z)
    object.lookAt(0, 0, 0)

    this.setTxAttributes(
      object,
      blockGeoData,
      offsets,
      quaternions,
      scales,
      this.txValuesArray,
      spentRatios,
      txTimes,
      this.offsetsArray2D
    )

    this.geometry.setAttribute('offset', offsets)
    this.geometry.setAttribute('scale', scales)
    this.geometry.setAttribute('spentRatio', spentRatios)
    this.geometry.setAttribute('quaternion', quaternions)
    this.geometry.setAttribute('txTime', txTimes)
    this.geometry.setAttribute('blockStartTime', blockStartTimes)
    this.geometry.setAttribute('blockLoadTime', blockLoadTimes)
    this.geometry.setAttribute('isHovered', isHovered)
    this.geometry.setAttribute('isSelected', isSelected)

    const positionAttrib = this.geometry.getAttribute('position')

    const barycentric = []

    // for each triangle in the geometry, add the barycentric coordinates
    for (let i = 0; i < positionAttrib.count / 3; i++) {
      if (
        i === 23 ||
        i === 22 ||
        i === 21 ||
        i === 20 ||
        i === 19 ||
        i === 18 ||
        i === 17 ||
        i === 16 ||
        i === 15 ||
        i === 14 ||
        i === 13 ||
        i === 12
      ) {
        barycentric.push(
          0, 0, 0,
          0, 0, 0,
          0, 0, 0
        )
      } else if (i % 2 === 0) {
        barycentric.push(
          0, 0, 1,
          0, 1, 0,
          1, 0, 1
        )
      } else {
        barycentric.push(
          0, 1, 0,
          0, 0, 1,
          1, 0, 1
        )
      }
    }

    const array = new Float32Array(barycentric)
    const attribute = new THREE.BufferAttribute(array, 3)
    this.geometry.setAttribute('barycentric', attribute)

    // const centerTopVertex = [
    //   0, 0, 0,
    //   0, 0, 0,
    //   0, 0, 0,

    //   0, 0, 0,
    //   0, 0, 0,
    //   0, 0, 0,

    //   0, 0, 0,
    //   0, 0, 0,
    //   0, 0, 0,

    //   0, 0, 0,
    //   0, 0, 0,
    //   0, 0, 0,

    //   0, 0, 1,
    //   0, 0, 1,
    //   0, 0, 1,

    //   0, 0, 1,
    //   0, 0, 1,
    //   0, 0, 1,

    //   0, 0, 0,
    //   0, 0, 0,
    //   0, 0, 0,

    //   0, 0, 0,
    //   0, 0, 0,
    //   0, 0, 0
    // ]

    // const CTVArray = new Float32Array(centerTopVertex)
    // const CTVAttribute = new THREE.BufferAttribute(CTVArray, 1)
    // this.geometry.setAttribute('centerTopVertex', CTVAttribute)

    // const centerBottomVertex = [
    //   0, 0, 0,
    //   0, 0, 0,
    //   0, 0, 0,

    //   0, 0, 0,
    //   0, 0, 0,
    //   0, 0, 0,

    //   0, 0, 0,
    //   0, 0, 0,
    //   0, 0, 0,

    //   0, 0, 0,
    //   0, 0, 0,
    //   0, 0, 0,

    //   0, 0, 0,
    //   0, 0, 0,
    //   0, 0, 0,

    //   0, 0, 0,
    //   0, 0, 0,
    //   0, 0, 0,

    //   0, 0, 1,
    //   0, 0, 1,
    //   0, 0, 1,

    //   0, 0, 1,
    //   0, 0, 1,
    //   0, 0, 1

    // ]

    // const CBVArray = new Float32Array(centerBottomVertex)
    // const CBVAttribute = new THREE.BufferAttribute(CBVArray, 1)
    // this.geometry.setAttribute('centerBottomVertex', CBVAttribute)

    const topVertex = [
      1, 0, 1,
      0, 0, 1,
      1, 0, 1,

      0, 0, 1,
      1, 0, 1,
      0, 0, 1,

      1, 0, 1,
      0, 0, 1,
      1, 0, 1,

      0, 0, 1,
      1, 0, 1,
      0, 0, 1,

      1, 1, 1,
      1, 1, 1,
      1, 1, 1,

      1, 1, 1,
      1, 1, 1,
      1, 1, 1,

      0, 0, 0,
      0, 0, 0,
      0, 0, 0,

      0, 0, 0,
      0, 0, 0,
      0, 0, 0
    ]

    const TVArray = new Float32Array(topVertex)
    const TVAttribute = new THREE.BufferAttribute(TVArray, 1)
    this.geometry.setAttribute('topVertex', TVAttribute)

    this.geometry.attributes.position.needsUpdate = true
    this.geometry.attributes.topVertex.needsUpdate = true
    this.geometry.attributes.barycentric.needsUpdate = true

    this.geometry.needsUpdate = true

    this.txCount += blockGeoData.blockData.n_tx

    this.mesh = new THREE.Mesh(this.geometry, this.material)

    this.mesh.frustumCulled = false

    return this.mesh
  }

  updateBlockStartTimes (blockData) {
    const txIndexOffset = this.txIndexOffsets[blockData.height]
    const offsetTime = window.performance.now()

    for (let i = 0; i < blockData.n_tx; i++) {
      this.geometry.attributes.blockStartTime.array[txIndexOffset + i] = offsetTime
    }

    this.geometry.attributes.blockStartTime.needsUpdate = true
  }

  updateBlockLoadTimes (blockData) {
    const txIndexOffset = this.txIndexOffsets[blockData.height]
    const offsetTime = window.performance.now()

    for (let i = 0; i < blockData.n_tx; i++) {
      this.geometry.attributes.blockLoadTime.array[txIndexOffset + i] = offsetTime
    }

    this.geometry.attributes.blockLoadTime.needsUpdate = true
  }

  updateGeometry (blockGeoData) {
    if (this.txCount + blockGeoData.blockData.n_tx > this.instanceTotal) {
      this.txCount = 0
    }

    const blockPosition = blockGeoData.blockData.pos

    const object = new THREE.Object3D()
    object.position.set(blockPosition.x, 0, blockPosition.z)
    object.lookAt(0, 0, 0)

    this.setTxAttributes(
      object,
      blockGeoData,
      this.geometry.attributes.offset,
      this.geometry.attributes.quaternion,
      this.geometry.attributes.scale,
      this.txValuesArray,
      this.geometry.attributes.spentRatio,
      this.geometry.attributes.txTime,
      this.offsetsArray2D
    )

    this.txCount += blockGeoData.blockData.n_tx

    this.updateBlockStartTimes(blockGeoData.blockData)
    this.updateBlockLoadTimes(blockGeoData.blockData)
  }

  update (args) {
    this.material.uniforms.uIsMobile.value = this.config.detector.isMobile ? 1.0 : 0.0
    this.material.uniforms.uTime.value = args.time
    this.material.uniforms.uAudioTime.value = args.time
    this.material.uniforms.uCamPos.value = args.camPos
    this.material.uniforms.uCamPosYPositive.value = args.camPos.y > 1
    this.material.uniforms.uAutoPilot.value = args.autoPilot
  }
}

class CrystalMaterial extends THREE.MeshStandardMaterial {
  constructor (cfg) {
    super(cfg)
    this.type = 'ShaderMaterial'

    this.uniforms = THREE.ShaderLib.standard.uniforms

    this.uniforms.uIsMobile = {
      type: 'f',
      value: 0.0
    }

    this.uniforms.uTime = {
      type: 'f',
      value: 0.0
    }

    this.uniforms.uAutoPilot = {
      type: 'f',
      value: 0.0
    }

    this.uniforms.uCamPos = {
      type: 'v3',
      value: new THREE.Vector3(0.0, 0.0, 0.0)
    }

    this.uniforms.uCamPosYPositive = {
      type: 'f',
      value: 1.0
    }

    this.uniforms.uAudioTime = {
      type: 'f',
      value: 0.0
    }

    this.uniforms.uOriginOffset = {
      type: 'v2',
      value: new THREE.Vector2(0.0, 0.0)
    }

    this.vertexShader = vertexShader
    this.fragmentShader = fragmentShader
  }
}
