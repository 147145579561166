module.exports = [
  -22.6756038666, -79.3887023926,
  -16.738073349, -80.4222183228,
  -10.2350215912, -86.2126693726,
  -8.52239990234, -91.991027832,
  -6.72632217407, -98.3261108398,
  -5.15139722824, -104.143501282,
  -7.64773273468, -112.485427856,
  -12.1593351364, -116.481414795,
  -11.9597549438, -115.853782654,
  -10.5846796036, -121.721618652,
  -13.3648099899, -129.97328186,
  -18.0104198456, -133.812667847,
  -23.1355628967, -137.946914673,
  -27.8710308075, -141.674880981,
  -36.5241317749, -142.645965576,
  -41.9681282043, -140.060348511,
  -32.4494400024, -132.773880005,
  -31.3746528625, -138.704086304,
  -34.5709648132, -146.803634644,
  -39.405872345, -150.40171814,
  -44.7346954346, -154.269866943,
  -49.6536827087, -157.752105713,
  -58.3449821472, -158.28175354,
  -63.6503944397, -155.422531128,
  -62.9924240112, -155.393722534,
  -68.0276031494, -158.705718994,
  -76.7319335938, -158.937820435,
  -81.9364700317, -155.898864746,
  -87.5830078125, -152.511276245,
  -92.7139129639, -149.349594116,
  -96.6059570312, -141.560424805,
  -96.0536193848, -135.558990479,
  -71.0970611572, -135.461685181,
  -70.4979553223, -141.458633423,
  -74.3291473389, -149.277923584,
  -79.4352416992, -152.479522705,
  -85.0551986694, -155.911026001,
  -90.2358779907, -158.990463257,
  -98.9417495728, -158.826263428,
  -104.002593994, -155.553604126,
  -103.344429016, -155.577301025,
  -108.627388, -158.477783203,
  -117.322540283, -158.015945435,
  -122.268531799, -154.572158813,
  -127.627372742, -150.745666504,
  -132.490188599, -147.185424805,
  -135.749557495, -139.111022949,
  -134.721038818, -133.172622681,
  -125.145835876, -140.384658813,
  -130.569503784, -143.012634277,
  -139.229904175, -142.109069824,
  -143.994293213, -138.418136597,
  -149.151519775, -134.323974609,
  -153.826919556, -130.520935059,
  -156.671325684, -122.29119873,
  -155.342056274, -116.412811279,
  -155.137573242, -117.038856506,
  -159.680206299, -113.078178406,
  -162.241500854, -104.755981445,
  -160.711990356, -98.9264907837,
  -158.965377808, -92.5776062012,
  -157.297866821, -86.7860717773,
  -150.840179443, -80.9450759888,
  -144.910873413, -79.8652877808,
  -118.084571838, -103.09752655,
  -118.306251526, -109.120262146,
  -123.16481781, -116.346153259,
  -128.658737183, -118.823928833,
  -134.692993164, -121.459617615,
  -140.244186401, -123.806236267,
  -148.846923828, -122.460075378,
  -153.415939331, -118.529853821,
  -152.767089844, -118.642784119,
  -158.395294189, -120.798194885,
  -166.946960449, -119.158668518,
  -171.378890991, -115.074501038,
  -176.167800903, -110.555053711,
  -180.501525879, -106.36680603,
  -182.633026123, -97.9242935181,
  -180.806808472, -92.1808319092,
  -172.300872803, -100.627540588,
  -178.031433105, -102.493843079,
  -186.488616943, -100.421394348,
  -190.707061768, -96.1170578003,
  -195.259857178, -91.3598327637,
  -199.374893188, -86.9565505981,
  -201.074142456, -78.4165496826,
  -198.958129883, -72.7734222412,
  -198.840637207, -73.4214630127,
  -202.802703857, -68.8800354004,
  -204.208938599, -60.2869110107,
  -201.901168823, -54.7194480896,
  -199.307693481, -48.6669197083,
  -196.868362427, -43.1558265686,
  -189.676605225, -38.2468948364,
  -183.655563354, -37.9831428528,
  -180.186645508, -62.6976509094,
  -186.047622681, -64.1016616821,
  -194.312927246, -61.3622703552,
  -198.175170898, -56.7356643677,
  -202.334655762, -51.6309738159,
  -206.085922241, -46.9139633179,
  -207.099685669, -38.2657585144,
  -204.540969849, -32.8090705872,
  -204.475479126, -33.4644050598,
  -208.063293457, -28.6218681335,
  -208.780731201, -19.9440555573,
  -206.036911011, -14.5780601501,
  -202.969665527, -8.75129699707,
  -200.099212646, -3.45196700096,
  -192.539352417, 0.868640482426,
  -186.516418457, 0.652049183846,
  -192.368362427, -9.80992412567,
  -195.705154419, -4.79113960266,
  -195.980209351, 3.91193437576,
  -192.966964722, 9.13139915466,
  -189.607284546, 14.7945833206,
  -186.470947266, 19.9410247803,
  -178.701065063, 23.8714523315,
  -172.696975708, 23.3487434387,
  -173.289642334, 23.0615577698,
  -169.979156494, 28.0977401733,
  -162.079437256, 31.7601699829,
  -156.096725464, 31.032453537,
  -149.570022583, 30.1598091125,
  -143.606277466, 29.2902297974,
  -136.94619751, 23.6810894012,
  -135.075057983, 17.9521026611,
  -135.075057983, -17.9521026611,
  -136.94619751, -23.6810894012,
  -143.606277466, -29.2902297974,
  -149.570022583, -30.1598091125,
  -156.096725464, -31.032453537,
  -162.079437256, -31.7601699829,
  -169.979156494, -28.0977401733,
  -173.289642334, -23.0615577698,
  -172.696975708, -23.3487434387,
  -178.701065063, -23.8714523315,
  -186.470947266, -19.9410247803,
  -189.607284546, -14.7945833206,
  -192.966964722, -9.13139915466,
  -195.980209351, -3.91193437576,
  -195.705154419, 4.79113960266,
  -192.368362427, 9.80992412567,
  -186.516418457, -0.652049183846,
  -192.539352417, -0.868640482426,
  -200.099212646, 3.45196700096,
  -202.969665527, 8.75129699707,
  -206.036911011, 14.5780601501,
  -208.780731201, 19.9440555573,
  -208.063293457, 28.6218681335,
  -204.475479126, 33.4644050598,
  -204.540969849, 32.8090705872,
  -207.099685669, 38.2657585144,
  -206.085922241, 46.9139633179,
  -202.334655762, 51.6309738159,
  -198.175170898, 56.7356643677,
  -194.312927246, 61.3622703552,
  -186.047622681, 64.1016616821,
  -180.186645508, 62.6976509094,
  -183.655563354, 37.9831428528,
  -189.676605225, 38.2468948364,
  -196.868362427, 43.1558265686,
  -199.307693481, 48.6669197083,
  -201.901168823, 54.7194480896,
  -204.208938599, 60.2869110107,
  -202.802703857, 68.8800354004,
  -198.840637207, 73.4214630127,
  -198.958129883, 72.7734222412,
  -201.074142456, 78.4165496826,
  -199.374893188, 86.9565505981,
  -195.259857178, 91.3598327637,
  -190.707061768, 96.1170578003,
  -186.488616943, 100.421394348,
  -178.031433105, 102.493843079,
  -172.300872803, 100.627540588,
  -180.806808472, 92.1808319092,
  -182.633026123, 97.9242935181,
  -180.501525879, 106.36680603,
  -176.167800903, 110.555053711,
  -171.378890991, 115.074501038,
  -166.946960449, 119.158668518,
  -158.395294189, 120.798194885,
  -152.767089844, 118.642784119,
  -153.415939331, 118.529853821,
  -148.846923828, 122.460075378,
  -140.244186401, 123.806236267,
  -134.692993164, 121.459617615,
  -128.658737183, 118.823928833,
  -123.16481781, 116.346153259,
  -118.306251526, 109.120262146,
  -118.084571838, 103.09752655,
  -144.910873413, 79.8652877808,
  -150.840179443, 80.9450759888,
  -157.297866821, 86.7860717773,
  -158.965377808, 92.5776062012,
  -160.711990356, 98.9264907837,
  -162.241500854, 104.755981445,
  -159.680206299, 113.078178406,
  -155.137573242, 117.038856506,
  -155.342056274, 116.412811279,
  -156.671325684, 122.29119873,
  -153.826919556, 130.520935059,
  -149.151519775, 134.323974609,
  -143.994293213, 138.418136597,
  -139.229904175, 142.109069824,
  -130.569503784, 143.012634277,
  -125.145835876, 140.384658813,
  -134.721038818, 133.172622681,
  -135.749557495, 139.111022949,
  -132.490188599, 147.185424805,
  -127.627372742, 150.745666504,
  -122.268531799, 154.572158813,
  -117.322540283, 158.015945435,
  -108.627388, 158.477783203,
  -103.344429016, 155.577301025,
  -104.002593994, 155.553604126,
  -98.9417495728, 158.826263428,
  -90.2358779907, 158.990463257,
  -85.0551986694, 155.911026001,
  -79.4352416992, 152.479522705,
  -74.3291473389, 149.277923584,
  -70.4979553223, 141.458633423,
  -71.0970611572, 135.461685181,
  -96.0536193848, 135.558990479,
  -96.6059570312, 141.560424805,
  -92.7139129639, 149.349594116,
  -87.5830078125, 152.511276245,
  -81.9364700317, 155.898864746,
  -76.7319335938, 158.937820435,
  -68.0276031494, 158.705718994,
  -62.9924240112, 155.393722534,
  -63.6503944397, 155.422531128,
  -58.3449821472, 158.28175354,
  -49.6536827087, 157.752105713,
  -44.7346954346, 154.269866943,
  -39.405872345, 150.40171814,
  -34.5709648132, 146.803634644,
  -31.3746528625, 138.704086304,
  -32.4494400024, 132.773880005,
  -41.9681282043, 140.060348511,
  -36.5241317749, 142.645965576,
  -27.8710308075, 141.674880981,
  -23.1355628967, 137.946914673,
  -18.0104198456, 133.812667847,
  -13.3648099899, 129.97328186,
  -10.5846796036, 121.721618652,
  -11.9597549438, 115.853782654,
  -12.1593351364, 116.481414795,
  -7.64773273468, 112.485427856,
  -5.15139722824, 104.143501282,
  -6.72632217407, 98.3261108398,
  -8.52239990234, 91.991027832,
  -10.2350215912, 86.2126693726,
  -16.738073349, 80.4222183228,
  -22.6756038666, 79.3887023926,
  22.6756076813, 79.3887023926,
  16.7380771637, 80.4222183228,
  10.2350234985, 86.2126693726,
  8.52240085602, 91.991027832,
  6.72632217407, 98.3261108398,
  5.1513967514, 104.143501282,
  7.64773273468, 112.485427856,
  12.1593351364, 116.481414795,
  11.9597549438, 115.853782654,
  10.5846796036, 121.721618652,
  13.3648099899, 129.97328186,
  18.0104198456, 133.812667847,
  23.1355628967, 137.946914673,
  27.8710308075, 141.674880981,
  36.5241317749, 142.645965576,
  41.9681282043, 140.060348511,
  32.4494438171, 132.773880005,
  31.3746604919, 138.704086304,
  34.5709686279, 146.803634644,
  39.4058799744, 150.40171814,
  44.7347068787, 154.269882202,
  49.6536903381, 157.75213623,
  58.3449897766, 158.28175354,
  63.6503944397, 155.422531128,
  62.9924240112, 155.393722534,
  68.0276107788, 158.705718994,
  76.7319412231, 158.937820435,
  81.9364776611, 155.898864746,
  87.5830154419, 152.511276245,
  92.7139205933, 149.349594116,
  96.6059646606, 141.560424805,
  96.0536346436, 135.558990479,
  71.0970611572, 135.461669922,
  70.4979553223, 141.458618164,
  74.3291473389, 149.277893066,
  79.4352416992, 152.479507446,
  85.0551986694, 155.911026001,
  90.2358779907, 158.990463257,
  98.9417495728, 158.82623291,
  104.002593994, 155.553604126,
  103.344436646, 155.577301025,
  108.62739563, 158.477783203,
  117.322570801, 158.015945435,
  122.268554688, 154.572158813,
  127.627372742, 150.745666504,
  132.490188599, 147.185394287,
  135.749557495, 139.111022949,
  134.721038818, 133.172607422,
  125.145835876, 140.384643555,
  130.569503784, 143.012619019,
  139.229904175, 142.109054565,
  143.994293213, 138.418121338,
  149.151519775, 134.323944092,
  153.826919556, 130.5209198,
  156.671325684, 122.291183472,
  155.342056274, 116.412788391,
  155.137573242, 117.038848877,
  159.680206299, 113.078163147,
  162.241500854, 104.755981445,
  160.711990356, 98.9264755249,
  158.965377808, 92.5775985718,
  157.297866821, 86.7860641479,
  150.840179443, 80.9450683594,
  144.910873413, 79.8652801514,
  118.084571838, 103.09752655,
  118.306251526, 109.120262146,
  123.16481781, 116.346153259,
  128.658737183, 118.823913574,
  134.692993164, 121.459609985,
  140.244186401, 123.806221008,
  148.846923828, 122.46006012,
  153.415939331, 118.529830933,
  152.767089844, 118.64276886,
  158.395294189, 120.798187256,
  166.946960449, 119.15864563,
  171.378890991, 115.074485779,
  176.167800903, 110.555046082,
  180.501525879, 106.366790771,
  182.633026123, 97.9242782593,
  180.806808472, 92.1808166504,
  172.300872803, 100.6275177,
  178.031433105, 102.49382019,
  186.488616943, 100.42137146,
  190.707061768, 96.1170425415,
  195.259857178, 91.3598175049,
  199.374893188, 86.95652771,
  201.074142456, 78.4165267944,
  198.958129883, 72.7734069824,
  198.840637207, 73.4214477539,
  202.802703857, 68.8800125122,
  204.208938599, 60.2868919373,
  201.901168823, 54.7194328308,
  199.307693481, 48.6669006348,
  196.868362427, 43.1558074951,
  189.676605225, 38.2468719482,
  183.655563354, 37.9831237793,
  180.186645508, 62.6976318359,
  186.047622681, 64.1016387939,
  194.312927246, 61.3622550964,
  198.175170898, 56.7356452942,
  202.334655762, 51.6309585571,
  206.085922241, 46.913936615,
  207.099685669, 38.2657356262,
  204.540969849, 32.8090515137,
  204.475479126, 33.464389801,
  208.063293457, 28.6218528748,
  208.780731201, 19.9440402985,
  206.036911011, 14.5780420303,
  202.969665527, 8.75127983093,
  200.099212646, 3.45195126534,
  192.539352417, -0.86865323782,
  186.516418457, -0.652061462402,
  192.368362427, 9.80992031097,
  195.705154419, 4.79113721848,
  195.980209351, -3.91193819046,
  192.966964722, -9.13140296936,
  189.607284546, -14.794585228,
  186.470947266, -19.9410266876,
  178.701065063, -23.8714561462,
  172.696975708, -23.3487491608,
  173.289642334, -23.0615615845,
  169.979156494, -28.0977420807,
  162.079437256, -31.7601795197,
  156.096725464, -31.0324630737,
  149.570022583, -30.1598167419,
  143.606277466, -29.2902355194,
  136.94619751, -23.6810932159,
  135.075057983, -17.9521083832,
  135.07510376, 17.9520874023,
  136.946228027, 23.6810741425,
  143.606323242, 29.2902145386,
  149.570068359, 30.1597881317,
  156.09677124, 31.0324344635,
  162.079483032, 31.7601509094,
  169.979202271, 28.0977115631,
  173.289672852, 23.0615310669,
  172.697006226, 23.3487243652,
  178.701095581, 23.8714313507,
  186.470977783, 19.9409999847,
  189.607315063, 14.7945585251,
  192.96697998, 9.13137245178,
  195.980224609, 3.91190648079,
  195.705169678, -4.79116725922,
  192.368377686, -9.80995178223,
  186.516448975, 0.652025878429,
  192.539367676, 0.868616461754,
  200.099212646, -3.45199155807,
  202.969680786, -8.75131988525,
  206.03692627, -14.578086853,
  208.78074646, -19.9440822601,
  208.063308716, -28.6218967438,
  204.475494385, -33.4644317627,
  204.540969849, -32.8090934753,
  207.099685669, -38.2657775879,
  206.085922241, -46.9139862061,
  202.334655762, -51.6310043335,
  198.17515564, -56.7356872559,
  194.312927246, -61.3622894287,
  186.047622681, -64.1016769409,
  180.186645508, -62.6976737976,
  183.655563354, -37.9831695557,
  189.676605225, -38.2469215393,
  196.868362427, -43.1558532715,
  199.307693481, -48.6669502258,
  201.901153564, -54.7194747925,
  204.208908081, -60.2869415283,
  202.80267334, -68.8800582886,
  198.840637207, -73.4214935303,
  198.958129883, -72.7734603882,
  201.074142456, -78.4165878296,
  199.37487793, -86.9565887451,
  195.259841919, -91.3598709106,
  190.707046509, -96.1170959473,
  186.488601685, -100.421432495,
  178.031417847, -102.493881226,
  172.300857544, -100.627571106,
  180.806808472, -92.1808700562,
  182.633026123, -97.9243240356,
  180.501525879, -106.36681366,
  176.167800903, -110.55506897,
  171.378890991, -115.074508667,
  166.946960449, -119.158676147,
  158.395294189, -120.798210144,
  152.767089844, -118.642807007,
  153.415939331, -118.52986908,
  148.846923828, -122.460098267,
  140.244186401, -123.806251526,
  134.692993164, -121.459632874,
  128.658737183, -118.823944092,
  123.16481781, -116.346168518,
  118.306251526, -109.120269775,
  118.084571838, -103.097541809,
  144.910858154, -79.8652877808,
  150.840148926, -80.9450759888,
  157.297866821, -86.7860717773,
  158.965377808, -92.5776062012,
  160.711990356, -98.9264984131,
  162.241500854, -104.755996704,
  159.680206299, -113.078178406,
  155.137573242, -117.038871765,
  155.342056274, -116.412811279,
  156.671325684, -122.29119873,
  153.826919556, -130.520935059,
  149.151519775, -134.323974609,
  143.994293213, -138.418136597,
  139.229888916, -142.109069824,
  130.569503784, -143.012634277,
  125.145835876, -140.384658813,
  134.721038818, -133.172622681,
  135.749557495, -139.111022949,
  132.490188599, -147.185424805,
  127.627372742, -150.745666504,
  122.268531799, -154.572158813,
  117.322540283, -158.015945435,
  108.627388, -158.477783203,
  103.344429016, -155.577301025,
  104.002593994, -155.553604126,
  98.9417266846, -158.82623291,
  90.2358551025, -158.990463257,
  85.0551757812, -155.911026001,
  79.4352340698, -152.479507446,
  74.3291397095, -149.277908325,
  70.4979476929, -141.458618164,
  71.0970535278, -135.461685181,
  96.0536117554, -135.558990479,
  96.6059494019, -141.560424805,
  92.7139053345, -149.349594116,
  87.5829925537, -152.511276245,
  81.9364624023, -155.898864746,
  76.7319259644, -158.937820435,
  68.02759552, -158.705718994,
  62.9924087524, -155.393722534,
  63.6503753662, -155.422531128,
  58.3449630737, -158.28175354,
  49.65366745, -157.752105713,
  44.7346763611, -154.269866943,
  39.4058570862, -150.401702881,
  34.5709495544, -146.803634644,
  31.3746376038, -138.704071045,
  32.4494247437, -132.773864746,
  41.9681091309, -140.060348511,
  36.5241165161, -142.645965576,
  27.8710136414, -141.674880981,
  23.1355438232, -137.946914673,
  18.0104007721, -133.812667847,
  13.3647928238, -129.97328186,
  10.5846614838, -121.721618652,
  11.959736824, -115.853782654,
  12.1593132019, -116.481391907,
  7.64771270752, -112.485404968,
  5.15137624741, -104.143501282,
  6.72630262375, -98.3261032104,
  8.52238464355, -91.991027832,
  10.2350063324, -86.2126693726,
  16.7380580902, -80.4222183228,
  22.6755886078, -79.3887023926

]
