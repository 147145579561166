module.exports = [
  -65.7877731323, -99.0829467773,
  -101.646438599, -99.2227630615,
  -143.42414856, -63.0421943665,
  -148.408432007, -27.5313491821,
  -148.408432007, 27.5313491821,
  -143.42414856, 63.0421943665,
  -101.646438599, 99.2227630615,
  -65.7877731323, 99.0829467773,
  65.7877731323, 99.0829467773,
  101.646438599, 99.2227554321,
  143.42414856, 63.0421829224,
  148.408432007, 27.5313396454,
  148.408447266, -27.5313663483,
  143.42414856, -63.0422134399,
  101.646430969, -99.2227630615,
  65.7877578735, -99.0829467773

]
