module.exports = [
  -20.5559062958, -84.7833328247,
  -14.1258134842, -90.5088272095,
  -10.7180347443, -102.528663635,
  -13.1863641739, -110.776992798,
  -16.0928421021, -119.917373657,
  -18.8417835236, -128.076461792,
  -28.5659484863, -135.920547485,
  -37.1219711304, -136.880737305,
  -36.7838973999, -136.621948242,
  -39.9443511963, -144.630645752,
  -50.0549659729, -151.96987915,
  -58.6487541199, -152.49357605,
  -68.2309341431, -152.913162231,
  -76.837600708, -153.14263916,
  -87.5510253906, -146.715240479,
  -91.3994064331, -139.013458252,
  -75.7242050171, -138.952331543,
  -79.5124130249, -146.683898926,
  -90.1753997803, -153.194656372,
  -98.7835922241, -153.032287598,
  -108.368751526, -152.687408447,
  -116.96635437, -152.230758667,
  -127.133911133, -144.970581055,
  -130.356704712, -136.986785889,
  -130.01663208, -137.242935181,
  -138.579879761, -136.349502563,
  -148.36491394, -128.581466675,
  -151.177398682, -120.444046021,
  -154.155075073, -111.32660675,
  -156.687637329, -103.09778595,
  -153.373687744, -91.0517578125,
  -146.988449097, -85.2762832642,
  -123.143280029, -105.926780701,
  -127.947341919, -113.071609497,
  -139.396408081, -118.072433472,
  -147.902633667, -116.741386414,
  -157.35194397, -115.096748352,
  -165.807647705, -113.475601196,
  -174.893875122, -104.900650024,
  -177.001464844, -96.5528640747,
  -176.699386597, -96.8528518677,
  -185.061691284, -94.8036422729,
  -193.699935913, -85.7775650024,
  -195.380111694, -77.3333740234,
  -197.090744019, -67.8957977295,
  -198.481201172, -59.3990859985,
  -193.560501099, -47.9153556824,
  -186.449417114, -43.0614929199,
  -184.270584106, -58.5846595764,
  -192.443130493, -55.8760108948,
  -200.335098267, -46.190662384,
  -201.337493896, -37.6394844055,
  -202.29107666, -28.0956459045,
  -203.000488281, -19.5151920319,
  -197.180862427, -8.45980930328,
  -189.705810547, -4.18767642975,
  -189.913665771, -4.55924081802,
  -190.185623169, 4.04619216919,
  -183.811141968, 14.7912034988,
  -176.128433228, 18.6775341034,
  -167.497009277, 22.8599777222,
  -159.685928345, 26.4813194275,
  -147.302536011, 24.8256092072,
  -140.717178345, 19.2793979645,
  -140.717178345, -19.2793979645,
  -147.302536011, -24.8256092072,
  -159.685928345, -26.4813194275,
  -167.497009277, -22.8599777222,
  -176.128433228, -18.6775341034,
  -183.811141968, -14.7912034988,
  -190.185623169, -4.04619216919,
  -189.913665771, 4.55924081802,
  -189.705810547, 4.18767642975,
  -197.180862427, 8.45980930328,
  -203.000488281, 19.5151920319,
  -202.29107666, 28.0956459045,
  -201.337493896, 37.6394844055,
  -200.335098267, 46.190662384,
  -192.443130493, 55.8760108948,
  -184.270584106, 58.5846595764,
  -186.449417114, 43.0614929199,
  -193.560501099, 47.9153556824,
  -198.481201172, 59.3990859985,
  -197.090744019, 67.8957977295,
  -195.380111694, 77.3333740234,
  -193.699935913, 85.7775650024,
  -185.061691284, 94.8036422729,
  -176.699386597, 96.8528518677,
  -177.001464844, 96.5528640747,
  -174.893875122, 104.900650024,
  -165.807647705, 113.475601196,
  -157.35194397, 115.096748352,
  -147.902633667, 116.741386414,
  -139.396408081, 118.072433472,
  -127.947341919, 113.071609497,
  -123.143280029, 105.926780701,
  -146.988449097, 85.2762832642,
  -153.373687744, 91.0517578125,
  -156.687637329, 103.09778595,
  -154.155075073, 111.32660675,
  -151.177398682, 120.444046021,
  -148.36491394, 128.581466675,
  -138.579879761, 136.349502563,
  -130.01663208, 137.242935181,
  -130.356704712, 136.986785889,
  -127.133911133, 144.970581055,
  -116.96635437, 152.230758667,
  -108.368751526, 152.687408447,
  -98.7835922241, 153.032287598,
  -90.1753997803, 153.194656372,
  -79.5124130249, 146.683898926,
  -75.7242050171, 138.952331543,
  -91.3994064331, 139.013458252,
  -87.5510253906, 146.715240479,
  -76.837600708, 153.14263916,
  -68.2309341431, 152.913162231,
  -58.6487541199, 152.49357605,
  -50.0549659729, 151.96987915,
  -39.9443511963, 144.630645752,
  -36.7838973999, 136.621948242,
  -37.1219711304, 136.880737305,
  -28.5659484863, 135.920547485,
  -18.8417835236, 128.076461792,
  -16.0928421021, 119.917373657,
  -13.1863641739, 110.776992798,
  -10.7180347443, 102.528663635,
  -14.1258134842, 90.5088272095,
  -20.5559062958, 84.7833328247,
  20.5559101105, 84.7833328247,
  14.1258163452, 90.5088272095,
  10.7180347443, 102.528663635,
  13.1863641739, 110.776992798,
  16.0928421021, 119.917373657,
  18.8417835236, 128.076461792,
  28.5659484863, 135.920547485,
  37.1219711304, 136.880737305,
  36.7839012146, 136.621948242,
  39.9443511963, 144.630645752,
  50.054977417, 151.969909668,
  58.6487617493, 152.49357605,
  68.2309341431, 152.913162231,
  76.8376083374, 153.14263916,
  87.5510406494, 146.715240479,
  91.3994140625, 139.013458252,
  75.7242050171, 138.952331543,
  79.5124130249, 146.683868408,
  90.1753997803, 153.194656372,
  98.7835922241, 153.03225708,
  108.368759155, 152.687408447,
  116.966377258, 152.230758667,
  127.133911133, 144.970565796,
  130.356704712, 136.98677063,
  130.01663208, 137.242919922,
  138.579879761, 136.349487305,
  148.36491394, 128.581436157,
  151.177398682, 120.444038391,
  154.155075073, 111.32660675,
  156.687637329, 103.097770691,
  153.373687744, 91.0517501831,
  146.988449097, 85.2762756348,
  123.143280029, 105.926780701,
  127.947341919, 113.071609497,
  139.396408081, 118.072418213,
  147.902633667, 116.741363525,
  157.35194397, 115.096725464,
  165.807647705, 113.475585938,
  174.893875122, 104.900634766,
  177.001464844, 96.5528411865,
  176.699386597, 96.8528289795,
  185.061691284, 94.8036270142,
  193.699935913, 85.7775421143,
  195.380111694, 77.3333587646,
  197.090744019, 67.8957748413,
  198.481201172, 59.399066925,
  193.560501099, 47.9153366089,
  186.449417114, 43.0614776611,
  184.270584106, 58.5846481323,
  192.443130493, 55.8759918213,
  200.335098267, 46.1906433105,
  201.337493896, 37.639465332,
  202.29107666, 28.0956306458,
  203.000488281, 19.5151748657,
  197.180862427, 8.45979404449,
  189.705810547, 4.18766307831,
  189.913665771, 4.55923748016,
  190.185623169, -4.04619598389,
  183.811141968, -14.7912073135,
  176.128433228, -18.6775398254,
  167.497009277, -22.8599815369,
  159.685928345, -26.4813270569,
  147.302536011, -24.8256149292,
  140.717178345, -19.2794036865,
  140.717208862, 19.2793807983,
  147.302566528, 24.825592041,
  159.685958862, 26.4812984467,
  167.497055054, 22.8599529266,
  176.128463745, 18.6775150299,
  183.811172485, 14.7911806107,
  190.185638428, 4.04616498947,
  189.91368103, -4.5592675209,
  189.705841064, -4.18770027161,
  197.180877686, -8.45983409882,
  203.000488281, -19.5152168274,
  202.291107178, -28.0956745148,
  201.337493896, -37.639503479,
  200.335098267, -46.1906852722,
  192.443130493, -55.8760299683,
  184.270584106, -58.5846824646,
  186.449417114, -43.0615196228,
  193.560501099, -47.9153823853,
  198.481185913, -59.3991088867,
  197.090713501, -67.8958206177,
  195.380111694, -77.333404541,
  193.699920654, -85.77759552,
  185.061676025, -94.8036804199,
  176.699371338, -96.8528900146,
  177.001464844, -96.5528945923,
  174.893875122, -104.900672913,
  165.807647705, -113.475624084,
  157.35194397, -115.096763611,
  147.902633667, -116.741401672,
  139.396408081, -118.072441101,
  127.947341919, -113.071632385,
  123.143280029, -105.926803589,
  146.988433838, -85.2762832642,
  153.373687744, -91.0517578125,
  156.687637329, -103.097801208,
  154.155075073, -111.32661438,
  151.177398682, -120.444046021,
  148.36491394, -128.581466675,
  138.579879761, -136.349502563,
  130.01663208, -137.242935181,
  130.356704712, -136.986785889,
  127.133911133, -144.970581055,
  116.96635437, -152.230758667,
  108.368751526, -152.687408447,
  98.7835845947, -153.03225708,
  90.1753845215, -153.194656372,
  79.5124053955, -146.683883667,
  75.7241897583, -138.952331543,
  91.3993988037, -139.013458252,
  87.5510253906, -146.715240479,
  76.8375854492, -153.14263916,
  68.2309112549, -152.913162231,
  58.6487426758, -152.49357605,
  50.0549507141, -151.96987915,
  39.9443321228, -144.630630493,
  36.7838859558, -136.621948242,
  37.1219520569, -136.880737305,
  28.5659313202, -135.920547485,
  18.8417644501, -128.076461792,
  16.0928230286, -119.917373657,
  13.1863441467, -110.776977539,
  10.7180147171, -102.528656006,
  14.1257982254, -90.5088272095,
  20.555891037, -84.7833328247

]
