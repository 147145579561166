module.exports = [
  -28.7933578491, -76.5452423096,
  -26.9926872253, -74.2045974731,
  -23.0423679352, -72.6524047852,
  -20.1299552917, -73.1411514282,
  -17.0230007172, -73.681968689,
  -14.1167392731, -74.2060470581,
  -10.9232301712, -77.0017318726,
  -10.0193557739, -79.8131332397,
  -10.4063091278, -79.4685668945,
  -7.50929355621, -80.041557312,
  -4.36335420609, -82.8906784058,
  -3.50699210167, -85.7169265747,
  -2.61082220078, -88.7405853271,
  -1.78884601593, -91.5770263672,
  -2.87421751022, -95.680229187,
  -4.99113607407, -97.7392730713,
  -6.73741674423, -91.5798568726,
  -3.85482382774, -92.2214813232,
  -0.777427017689, -95.1444854736,
  0.0115814423189, -97.9902648926,
  0.835698723793, -101.034355164,
  1.59008848667, -103.889511108,
  0.407587647438, -107.965782166,
  -1.75762856007, -109.973983765,
  -1.60908639431, -109.477615356,
  -0.902923822403, -112.345085144,
  -2.15395712852, -116.400856018,
  -4.35271167755, -118.372276306,
  -6.71351528168, -120.463272095,
  -8.93604850769, -122.407844543,
  -13.1132173538, -123.159889221,
  -15.8744115829, -122.112594604,
  -11.7401504517, -109.111083984,
  -8.88118362427, -109.850891113,
  -5.90554094315, -112.877433777,
  -5.21430635452, -115.748542786,
  -4.49476528168, -118.819015503,
  -3.83845019341, -121.698303223,
  -5.1596493721, -125.731758118,
  -7.39227056503, -127.664749146,
  -7.2268409729, -127.173736572,
  -6.61914587021, -130.063674927,
  -8.00813674927, -134.074295044,
  -10.273018837, -135.96937561,
  -12.7039442062, -137.978408813,
  -14.9916744232, -139.845855713,
  -19.1921195984, -140.45463562,
  -21.9158859253, -139.313537598,
  -16.932844162, -135.293899536,
  -16.3939476013, -138.19744873,
  -17.8777809143, -142.173950195,
  -20.1870250702, -144.014709473,
  -22.66497612, -145.96546936,
  -24.9964027405, -147.778060913,
  -29.2101192474, -148.286911011,
  -31.9060211182, -147.081451416,
  -31.39112854, -147.023666382,
  -33.7527770996, -148.796707153,
  -37.9744720459, -149.234481812,
  -40.6496734619, -147.983764648,
  -43.498374939, -146.630767822,
  -46.1581878662, -145.34765625,
  -48.4865150452, -141.798950195,
  -48.6046066284, -138.848175049,
  -31.8871059418, -126.051071167,
  -29.0694770813, -126.935386658,
  -26.2516517639, -130.109390259,
  -25.7073726654, -133.011917114,
  -25.1449623108, -136.11505127,
  -24.6359729767, -139.023986816,
  -26.1606464386, -142.985015869,
  -28.4887104034, -144.8019104,
  -28.2985248566, -144.319961548,
  -27.838634491, -147.237075806,
  -29.4298553467, -151.171844482,
  -31.7882099152, -152.949249268,
  -34.3181877136, -154.83203125,
  -36.6979560852, -156.580657959,
  -40.9239311218, -156.974975586,
  -43.5861206055, -155.696777344,
  -38.4050445557, -151.935836792,
  -38.0145530701, -154.863037109,
  -39.6987571716, -158.758895874,
  -42.0986595154, -160.479812622,
  -44.6726341248, -162.301986694,
  -47.0932540894, -163.993606567,
  -51.3274040222, -164.287475586,
  -53.9584846497, -162.946426392,
  -53.4413261414, -162.914932251,
  -55.8901138306, -164.565505981,
  -60.1286087036, -164.787963867,
  -62.7367210388, -163.402755737,
  -65.5129013062, -161.906600952,
  -68.1039962769, -160.489837646,
  -70.2487869263, -156.827285767,
  -70.2166061401, -153.874328613,
  -56.5866622925, -153.277496338,
  -56.2964935303, -156.216339111,
  -58.1129417419, -160.052337646,
  -60.5702819824, -161.690170288,
  -63.2050628662, -163.42326355,
  -65.6821060181, -165.03112793,
  -69.923828125, -165.180007935,
  -72.5075149536, -163.749786377,
  -71.9895782471, -163.735961914,
  -74.4933700562, -165.301864624,
  -78.7369995117, -165.379226685,
  -81.2962112427, -163.905654907,
  -84.0196151733, -162.315444946,
  -86.5607528687, -160.810913086,
  -88.5790405273, -157.077163696,
  -88.445892334, -154.127029419,
  -82.9559326172, -157.420700073,
  -85.4962005615, -158.926696777,
  -89.7404632568, -158.903274536,
  -92.263961792, -157.369354248,
  -94.9488372803, -155.714920044,
  -97.4535217285, -154.15045166,
  -99.3825912476, -150.369827271,
  -99.17943573, -147.423690796,
  -98.947845459, -147.887176514,
  -101.425811768, -146.280715942,
  -103.290893555, -142.46812439,
  -103.038101196, -139.525817871,
  -102.749076843, -136.385437012,
  -102.460586548, -133.446411133,
  -99.9309844971, -130.038269043,
  -97.2014312744, -128.911071777,
  -70.0011291504, -128.805023193,
  -67.2628707886, -129.910903931,
  -64.7067642212, -133.299224854,
  -64.395362854, -136.23588562,
  -64.0818710327, -139.373931885,
  -63.8061599731, -142.314178467,
  -65.6414337158, -146.141204834,
  -68.106803894, -147.766937256,
  -67.8788375854, -147.301651001,
  -67.6527175903, -150.246109009,
  -69.5522384644, -154.041671753,
  -72.0446548462, -155.625610352,
  -74.7165374756, -157.300918579,
  -77.2280044556, -158.854492188,
  -81.4719543457, -158.911010742,
  -84.0238952637, -157.424865723,
  -78.5597686768, -154.088500977,
  -78.4036407471, -157.037506104,
  -80.3927536011, -160.786880493,
  -82.922088623, -162.311203003,
  -85.6329956055, -163.922546387,
  -88.1806488037, -165.416061401,
  -92.4247436523, -165.371780396,
  -94.9406738281, -163.825454712,
  -94.4226455688, -163.835220337,
  -96.9951019287, -165.285568237,
  -101.237854004, -165.169769287,
  -103.727371216, -163.581268311,
  -106.375572205, -161.868789673,
  -108.845611572, -160.250152588,
  -110.691902161, -156.428436279,
  -110.424674988, -153.48739624,
  -96.7904815674, -153.977935791,
  -96.7352828979, -156.930557251,
  -98.8514404297, -160.609710693,
  -101.431419373, -162.046646118,
  -104.195846558, -163.56439209,
  -106.793083191, -164.969894409,
  -111.033187866, -164.780487061,
  -113.494766235, -163.149047852,
  -112.977371216, -163.176513672,
  -115.597923279, -164.538040161,
  -119.834220886, -164.277236938,
  -122.267951965, -162.604537964,
  -124.856063843, -160.802474976,
  -127.269317627, -159.100341797,
  -128.983840942, -155.217712402,
  -128.616195679, -152.287536621,
  -123.405952454, -156.007965088,
  -126.058097839, -157.306884766,
  -130.287017822, -156.945541382,
  -132.680358887, -155.215515137,
  -135.22492981, -153.352523804,
  -137.597076416, -151.593566895,
  -139.218933105, -147.671325684,
  -138.781799316, -144.750732422,
  -138.587844849, -145.231170654,
  -140.929992676, -143.432495117,
  -142.485534668, -139.483474731,
  -141.999237061, -136.570648193,
  -141.461044312, -133.463241577,
  -140.939422607, -130.556533813,
  -138.14642334, -127.360671997,
  -135.335769653, -126.454421997,
  -118.519012451, -139.120788574,
  -118.614089966, -142.072387695,
  -120.914672852, -145.639129639,
  -123.564399719, -146.942962646,
  -126.40246582, -148.318099976,
  -129.067840576, -149.589660645,
  -133.292800903, -149.184814453,
  -135.668212891, -147.430252075,
  -135.152893066, -147.484024048,
  -137.839309692, -148.710479736,
  -142.056854248, -148.234466553,
  -144.40234375, -146.440124512,
  -146.89541626, -144.508758545,
  -149.218948364, -142.686050415,
  -150.733764648, -138.721237183,
  -150.217529297, -135.813583374,
  -145.203292847, -139.794204712,
  -147.918060303, -140.956512451,
  -152.123123169, -140.380508423,
  -154.425354004, -138.530975342,
  -156.871871948, -136.540939331,
  -159.151443481, -134.663574219,
  -160.571685791, -130.663925171,
  -159.986541748, -127.769332886,
  -159.81729126, -128.259033203,
  -162.064910889, -126.343513489,
  -163.417541504, -122.320480347,
  -162.783691406, -119.436172485,
  -162.088134766, -116.360160828,
  -161.419281006, -113.483764648,
  -158.467346191, -110.434112549,
  -155.614242554, -109.672035217,
  -151.378692627, -122.640914917,
  -154.131637573, -123.709701538,
  -158.314544678, -122.990242004,
  -160.5521698, -121.063056946,
  -162.929229736, -118.990539551,
  -165.143295288, -117.036331177,
  -166.425888062, -112.99042511,
  -165.742111206, -110.117546082,
  -165.589706421, -110.612754822,
  -167.770523071, -108.621505737,
  -168.984786987, -104.55456543,
  -168.252655029, -101.693618774,
  -167.452316284, -98.6431808472,
  -166.685516357, -95.7913360596,
  -163.631027222, -92.8444213867,
  -160.75352478, -92.1803512573,
  -162.451705933, -98.3532104492,
  -164.584609985, -96.3107376099,
  -165.701950073, -92.2161178589,
  -164.902130127, -89.3733520508,
  -164.029556274, -86.3428039551,
  -163.195266724, -83.5099716187,
  -160.071624756, -80.6364059448,
  -157.179168701, -80.0408477783,
  -157.563430786, -80.3884124756,
  -156.681503296, -77.5700378418,
  -153.509902954, -74.7495422363,
  -150.607818604, -74.2028121948,
  -147.505157471, -73.6377792358,
  -144.59664917, -73.126335144,
  -140.634353638, -74.6476669312,
  -138.815475464, -76.9742050171,
  -116.093925476, -96.6516342163,
  -113.531417847, -98.1194839478,
  -111.459640503, -101.823822021,
  -111.550346375, -104.775566101,
  -111.66632843, -107.927101135,
  -111.792861938, -110.8775177,
  -114.131347656, -114.419540405,
  -116.794822693, -115.695030212,
  -116.505722046, -115.265075684,
  -116.681983948, -118.212944031,
  -119.079826355, -121.715034485,
  -121.764419556, -122.945472717,
  -124.639228821, -124.242027283,
  -127.338577271, -125.43976593,
  -131.550827026, -124.918830872,
  -133.877044678, -123.099578857,
  -128.010101318, -120.536956787,
  -128.25630188, -123.479820251,
  -130.73664856, -126.923995972,
  -133.449707031, -128.090316772,
  -136.354492188, -129.318252563,
  -139.08152771, -130.451553345,
  -143.280197144, -129.830749512,
  -145.562576294, -127.956771851,
  -145.050674438, -128.036880493,
  -147.796417236, -129.124053955,
  -151.984039307, -128.432571411,
  -154.234512329, -126.520385742,
  -156.625350952, -124.463813782,
  -158.852416992, -122.524452209,
  -160.162078857, -118.487213135,
  -159.497512817, -115.609832764,
  -146.056564331, -117.949203491,
  -146.403244019, -120.88192749,
  -148.999908447, -124.239265442,
  -151.751266479, -125.312141418,
  -154.696365356, -126.440010071,
  -157.460540771, -127.479393005,
  -161.635528564, -126.715370178,
  -163.852508545, -124.764442444,
  -163.343643188, -124.862007141,
  -166.124954224, -125.854667664,
  -170.286453247, -125.020385742,
  -172.470230103, -123.03237915,
  -174.789337158, -120.895233154,
  -176.948791504, -118.880851746,
  -178.119628906, -114.801200867,
  -177.357055664, -111.948219299,
  -172.700927734, -116.34236145,
  -175.505020142, -117.268692017,
  -179.645568848, -116.335838318,
  -181.781509399, -114.296516418,
  -184.049224854, -112.104919434,
  -186.160247803, -110.039810181,
  -187.233856201, -105.933517456,
  -186.403762817, -103.099441528,
  -186.276931763, -103.601806641,
  -188.352859497, -101.501419067,
  -189.357086182, -97.3776016235,
  -188.479354858, -94.5579299927,
  -187.523757935, -91.5525283813,
  -186.611846924, -88.7437133789,
  -183.410339355, -85.9571838379,
  -180.502593994, -85.4414367676,
  -165.563781738, -100.276222229,
  -166.059204102, -103.187507629,
  -168.823272705, -106.408401489,
  -171.625656128, -107.339927673,
  -174.624282837, -108.316505432,
  -177.437774658, -109.213928223,
  -181.568496704, -108.238525391,
  -183.683349609, -106.177345276,
  -183.180084229, -106.300674438,
  -186.008285522, -107.150543213,
  -190.122009277, -106.105636597,
  -192.201797485, -104.009109497,
  -194.409194946, -101.756767273,
  -196.463409424, -99.6351318359,
  -197.425140381, -95.5012054443,
  -196.518447876, -92.6907043457,
  -192.091888428, -97.3160171509,
  -194.939453125, -98.0984954834,
  -199.027175903, -96.9562149048,
  -201.056610107, -94.8108825684,
  -203.209899902, -92.5067596436,
  -205.213134766, -90.3369369507,
  -206.076477051, -86.1813354492,
  -205.103286743, -83.3931655884,
  -205.002166748, -83.9013214111,
  -206.968536377, -81.6980514526,
  -207.761703491, -77.5284957886,
  -206.741668701, -74.7571182251,
  -205.634399414, -71.8042297363,
  -204.580764771, -69.0454406738,
  -201.241668701, -66.4253921509,
  -198.311447144, -66.0582199097,
  -195.878204346, -79.4824905396,
  -198.75088501, -80.167137146,
  -202.797164917, -78.8857345581,
  -204.752029419, -76.6722640991,
  -206.825271606, -74.2958450317,
  -208.753143311, -72.0587997437,
  -209.473846436, -67.8761062622,
  -208.405853271, -65.1228408813,
  -208.322219849, -65.6341629028,
  -210.212081909, -63.3649406433,
  -210.862213135, -59.1706962585,
  -209.748031616, -56.4358253479,
  -208.540405273, -53.5225105286,
  -207.393051147, -50.8013725281,
  -203.966323853, -48.2970352173,
  -201.025253296, -48.0302810669,
  -203.546798706, -53.9149894714,
  -205.382247925, -51.6015319824,
  -205.932601929, -47.393032074,
  -204.753753662, -44.6853904724,
  -203.477325439, -41.801574707,
  -202.265670776, -39.1084480286,
  -198.780410767, -36.6861877441,
  -195.833862305, -36.4893493652,
  -196.261795044, -36.7814483643,
  -195.00491333, -34.10912323,
  -191.4793396, -31.7459506989,
  -188.529876709, -31.5988006592,
  -185.379226685, -31.4607810974,
  -182.428192139, -31.3494644165,
  -178.709472656, -33.3953094482,
  -177.223754883, -35.9474945068,
  -173.442947388, -62.8839912415,
  -174.168655396, -65.7465667725,
  -177.180465698, -68.7371139526,
  -180.048126221, -69.4425048828,
  -183.115005493, -70.1771850586,
  -185.991012573, -70.847694397,
  -190.030944824, -69.5464096069,
  -191.97492981, -67.323348999,
  -191.483108521, -67.4863586426,
  -194.370025635, -68.1082992554,
  -198.387435913, -66.739112854,
  -200.293670654, -64.4836044312,
  -202.314682007, -62.0626220703,
  -204.193389893, -59.7841339111,
  -204.822891235, -55.5867462158,
  -203.695236206, -52.8573875427,
  -199.651062012, -57.8205299377,
  -202.551925659, -58.3737449646,
  -206.535690308, -56.9095497131,
  -208.387832642, -54.6094169617,
  -210.350784302, -52.1411247253,
  -212.174865723, -49.8186683655,
  -212.70451355, -45.607509613,
  -211.512359619, -42.9056930542,
  -211.452026367, -43.42029953,
  -213.236694336, -41.0674285889,
  -213.695297241, -36.847946167,
  -212.457778931, -34.1666069031,
  -211.118850708, -31.3112659454,
  -209.848892212, -28.6451511383,
  -206.311706543, -26.2993392944,
  -203.361541748, -26.1666946411,
  -202.005142212, -39.742099762,
  -204.923202515, -40.1957969666,
  -208.854598999, -38.5962333679,
  -210.626983643, -36.2341079712,
  -212.504394531, -33.7001342773,
  -214.247955322, -31.3166618347,
  -214.633300781, -27.0898609161,
  -213.349456787, -24.4303913116,
  -213.306793213, -24.9467506409,
  -215.00994873, -22.5342311859,
  -215.323989868, -18.3015346527,
  -213.995529175, -15.6640806198,
  -212.559738159, -12.8561964035,
  -211.199325562, -10.2350702286,
  -207.584030151, -8.0115852356,
  -204.631057739, -7.97989940643,
  -207.613235474, -13.6451063156,
  -209.258636475, -11.1928215027,
  -209.472076416, -6.95386171341,
  -208.081344604, -4.34869766235,
  -206.579299927, -1.57569646835,
  -205.157058716, 1.01238572598,
  -201.489944458, 3.14939451218,
  -198.537063599, 3.1109495163,
  -198.986907959, 2.85385870934,
  -197.521240234, 5.41760253906,
  -193.818649292, 7.49250364304,
  -190.866821289, 7.404296875,
  -187.715179443, 7.29095935822,
  -184.764648438, 7.16691160202,
  -181.220672607, 4.83141613007,
  -179.942932129, 2.16901516914,
  -190.220550537, -16.205165863,
  -193.157928467, -16.5098285675,
  -197.002868652, -14.7123327255,
  -198.652801514, -12.2631025314,
  -200.398834229, -9.63690376282,
  -202.01890564, -7.16781711578,
  -202.188720703, -2.92688369751,
  -200.771255493, -0.33616951108,
  -200.754898071, -0.854034900665,
  -202.333114624, 1.6420058012,
  -202.431427002, 5.88519763947,
  -200.970489502, 8.4516544342,
  -199.393737793, 11.1828632355,
  -197.901763916, 13.731388092,
  -194.178024292, 15.7680778503,
  -191.227279663, 15.6494979858,
  -194.49382019, 10.1433382034,
  -196.012298584, 12.6761512756,
  -196.009841919, 16.9204826355,
  -194.488372803, 19.4515247345,
  -192.847213745, 22.1445217133,
  -191.295120239, 24.6569004059,
  -187.52406311, 26.6045875549,
  -184.576965332, 26.4159736633,
  -185.039291382, 26.1820983887,
  -183.445083618, 28.667963028,
  -179.641723633, 30.5518188477,
  -176.69821167, 30.3135623932,
  -173.556427002, 30.0400390625,
  -170.616027832, 29.7660522461,
  -167.195449829, 27.2532939911,
  -166.054779053, 24.5293388367,
  -178.332366943, 18.5801296234,
  -179.763381958, 21.1633892059,
  -179.615768433, 25.4051532745,
  -178.008636475, 27.882686615,
  -176.276351929, 30.517988205,
  -174.63923645, 32.9758224487,
  -170.80380249, 34.793422699,
  -167.864852905, 34.5041389465,
  -168.33493042, 34.2862052917,
  -166.656661987, 36.7160987854,
  -162.791137695, 38.4687995911,
  -159.85748291, 38.1300239563,
  -156.726882935, 37.7492294312,
  -153.797576904, 37.3748512268,
  -150.464904785, 34.7465934753,
  -149.418060303, 31.9852294922,
  -155.763793945, 32.8336791992,
  -154.028289795, 35.223033905,
  -150.122192383, 36.883441925,
  -147.197433472, 36.4751052856,
  -144.076751709, 36.020072937,
  -141.157150269, 35.5762481689,
  -137.8878479, 32.8695907593,
  -136.90687561, 30.0841407776,
  -137.30317688, 30.4179077148,
  -134.391464233, 29.9249362946,
  -131.168258667, 27.16355896,
  -130.234375, 24.3619785309,
  -129.255264282, 21.364151001,
  -128.35546875, 18.5514297485,
  -129.327407837, 14.419883728,
  -131.386810303, 12.3033075333,
  -131.386810303, -12.3033075333,
  -129.327407837, -14.419883728,
  -128.35546875, -18.5514297485,
  -129.255264282, -21.364151001,
  -130.234375, -24.3619785309,
  -131.168258667, -27.16355896,
  -134.391464233, -29.9249362946,
  -137.30317688, -30.4179077148,
  -136.90687561, -30.0841407776,
  -137.8878479, -32.8695907593,
  -141.157150269, -35.5762481689,
  -144.076751709, -36.020072937,
  -147.197433472, -36.4751052856,
  -150.122192383, -36.883441925,
  -154.028289795, -35.223033905,
  -155.763793945, -32.8336791992,
  -149.418060303, -31.9852294922,
  -150.464904785, -34.7465934753,
  -153.797576904, -37.3748512268,
  -156.726882935, -37.7492294312,
  -159.85748291, -38.1300239563,
  -162.791137695, -38.4687995911,
  -166.656661987, -36.7160987854,
  -168.33493042, -34.2862052917,
  -167.864852905, -34.5041389465,
  -170.80380249, -34.793422699,
  -174.63923645, -32.9758224487,
  -176.276351929, -30.517988205,
  -178.008636475, -27.882686615,
  -179.615768433, -25.4051532745,
  -179.763381958, -21.1633892059,
  -178.332366943, -18.5801296234,
  -166.054779053, -24.5293388367,
  -167.195449829, -27.2532939911,
  -170.616027832, -29.7660522461,
  -173.556427002, -30.0400390625,
  -176.69821167, -30.3135623932,
  -179.641723633, -30.5518188477,
  -183.445083618, -28.667963028,
  -185.039291382, -26.1820983887,
  -184.576965332, -26.4159736633,
  -187.52406311, -26.6045875549,
  -191.295120239, -24.6569004059,
  -192.847213745, -22.1445217133,
  -194.488372803, -19.4515247345,
  -196.009841919, -16.9204826355,
  -196.012298584, -12.6761512756,
  -194.49382019, -10.1433382034,
  -191.227279663, -15.6494979858,
  -194.178024292, -15.7680778503,
  -197.901763916, -13.731388092,
  -199.393737793, -11.1828632355,
  -200.970489502, -8.4516544342,
  -202.431427002, -5.88519763947,
  -202.333114624, -1.6420058012,
  -200.754898071, 0.854034900665,
  -200.771255493, 0.33616951108,
  -202.188720703, 2.92688369751,
  -202.01890564, 7.16781711578,
  -200.398834229, 9.63690376282,
  -198.652801514, 12.2631025314,
  -197.002868652, 14.7123327255,
  -193.157928467, 16.5098285675,
  -190.220550537, 16.205165863,
  -179.942932129, -2.16901516914,
  -181.220672607, -4.83141613007,
  -184.764648438, -7.16691160202,
  -187.715179443, -7.29095935822,
  -190.866821289, -7.404296875,
  -193.818649292, -7.49250364304,
  -197.521240234, -5.41760253906,
  -198.986907959, -2.85385870934,
  -198.537063599, -3.1109495163,
  -201.489944458, -3.14939451218,
  -205.157058716, -1.01238572598,
  -206.579299927, 1.57569646835,
  -208.081344604, 4.34869766235,
  -209.472076416, 6.95386171341,
  -209.258636475, 11.1928215027,
  -207.613235474, 13.6451063156,
  -204.631057739, 7.97989940643,
  -207.584030151, 8.0115852356,
  -211.199325562, 10.2350702286,
  -212.559738159, 12.8561964035,
  -213.995529175, 15.6640806198,
  -215.323989868, 18.3015346527,
  -215.00994873, 22.5342311859,
  -213.306793213, 24.9467506409,
  -213.349456787, 24.4303913116,
  -214.633300781, 27.0898609161,
  -214.247955322, 31.3166618347,
  -212.504394531, 33.7001342773,
  -210.626983643, 36.2341079712,
  -208.854598999, 38.5962333679,
  -204.923202515, 40.1957969666,
  -202.005142212, 39.742099762,
  -203.361541748, 26.1666946411,
  -206.311706543, 26.2993392944,
  -209.848892212, 28.6451511383,
  -211.118850708, 31.3112659454,
  -212.457778931, 34.1666069031,
  -213.695297241, 36.847946167,
  -213.236694336, 41.0674285889,
  -211.452026367, 43.42029953,
  -211.512359619, 42.9056930542,
  -212.70451355, 45.607509613,
  -212.174865723, 49.8186683655,
  -210.350784302, 52.1411247253,
  -208.387832642, 54.6094169617,
  -206.535690308, 56.9095497131,
  -202.551925659, 58.3737449646,
  -199.651062012, 57.8205299377,
  -203.695236206, 52.8573875427,
  -204.822891235, 55.5867462158,
  -204.193389893, 59.7841339111,
  -202.314682007, 62.0626220703,
  -200.293670654, 64.4836044312,
  -198.387435913, 66.739112854,
  -194.370025635, 68.1082992554,
  -191.483108521, 67.4863586426,
  -191.97492981, 67.323348999,
  -190.030944824, 69.5464096069,
  -185.991012573, 70.847694397,
  -183.115005493, 70.1771850586,
  -180.048126221, 69.4425048828,
  -177.180465698, 68.7371139526,
  -174.168655396, 65.7465667725,
  -173.442947388, 62.8839912415,
  -177.223754883, 35.9474945068,
  -178.709472656, 33.3953094482,
  -182.428192139, 31.3494644165,
  -185.379226685, 31.4607810974,
  -188.529876709, 31.5988006592,
  -191.4793396, 31.7459506989,
  -195.00491333, 34.10912323,
  -196.261795044, 36.7814483643,
  -195.833862305, 36.4893493652,
  -198.780410767, 36.6861877441,
  -202.265670776, 39.1084480286,
  -203.477325439, 41.801574707,
  -204.753753662, 44.6853904724,
  -205.932601929, 47.393032074,
  -205.382247925, 51.6015319824,
  -203.546798706, 53.9149894714,
  -201.025253296, 48.0302810669,
  -203.966323853, 48.2970352173,
  -207.393051147, 50.8013725281,
  -208.540405273, 53.5225105286,
  -209.748031616, 56.4358253479,
  -210.862213135, 59.1706962585,
  -210.212081909, 63.3649406433,
  -208.322219849, 65.6341629028,
  -208.405853271, 65.1228408813,
  -209.473846436, 67.8761062622,
  -208.753143311, 72.0587997437,
  -206.825271606, 74.2958450317,
  -204.752029419, 76.6722640991,
  -202.797164917, 78.8857345581,
  -198.75088501, 80.167137146,
  -195.878204346, 79.4824905396,
  -198.311447144, 66.0582199097,
  -201.241668701, 66.4253921509,
  -204.580764771, 69.0454406738,
  -205.634399414, 71.8042297363,
  -206.741668701, 74.7571182251,
  -207.761703491, 77.5284957886,
  -206.968536377, 81.6980514526,
  -205.002166748, 83.9013214111,
  -205.103286743, 83.3931655884,
  -206.076477051, 86.1813354492,
  -205.213134766, 90.3369369507,
  -203.209899902, 92.5067596436,
  -201.056610107, 94.8108825684,
  -199.027175903, 96.9562149048,
  -194.939453125, 98.0984954834,
  -192.091888428, 97.3160171509,
  -196.518447876, 92.6907043457,
  -197.425140381, 95.5012054443,
  -196.463409424, 99.6351318359,
  -194.409194946, 101.756767273,
  -192.201797485, 104.009109497,
  -190.122009277, 106.105636597,
  -186.008285522, 107.150543213,
  -183.180084229, 106.300674438,
  -183.683349609, 106.177345276,
  -181.568496704, 108.238525391,
  -177.437774658, 109.213928223,
  -174.624282837, 108.316505432,
  -171.625656128, 107.339927673,
  -168.823272705, 106.408401489,
  -166.059204102, 103.187507629,
  -165.563781738, 100.276222229,
  -180.502593994, 85.4414367676,
  -183.410339355, 85.9571838379,
  -186.611846924, 88.7437133789,
  -187.523757935, 91.5525283813,
  -188.479354858, 94.5579299927,
  -189.357086182, 97.3776016235,
  -188.352859497, 101.501419067,
  -186.276931763, 103.601806641,
  -186.403762817, 103.099441528,
  -187.233856201, 105.933517456,
  -186.160247803, 110.039810181,
  -184.049224854, 112.104919434,
  -181.781509399, 114.296516418,
  -179.645568848, 116.335838318,
  -175.505020142, 117.268692017,
  -172.700927734, 116.34236145,
  -177.357055664, 111.948219299,
  -178.119628906, 114.801200867,
  -176.948791504, 118.880851746,
  -174.789337158, 120.895233154,
  -172.470230103, 123.03237915,
  -170.286453247, 125.020385742,
  -166.124954224, 125.854667664,
  -163.343643188, 124.862007141,
  -163.852508545, 124.764442444,
  -161.635528564, 126.715370178,
  -157.460540771, 127.479393005,
  -154.696365356, 126.440010071,
  -151.751266479, 125.312141418,
  -148.999908447, 124.239265442,
  -146.403244019, 120.88192749,
  -146.056564331, 117.949203491,
  -159.497512817, 115.609832764,
  -160.162078857, 118.487213135,
  -158.852416992, 122.524452209,
  -156.625350952, 124.463813782,
  -154.234512329, 126.520385742,
  -151.984039307, 128.432571411,
  -147.796417236, 129.124053955,
  -145.050674438, 128.036880493,
  -145.562576294, 127.956771851,
  -143.280197144, 129.830749512,
  -139.08152771, 130.451553345,
  -136.354492188, 129.318252563,
  -133.449707031, 128.090316772,
  -130.73664856, 126.923995972,
  -128.25630188, 123.479820251,
  -128.010101318, 120.536956787,
  -133.877044678, 123.099578857,
  -131.550827026, 124.918830872,
  -127.338577271, 125.43976593,
  -124.639228821, 124.242027283,
  -121.764419556, 122.945472717,
  -119.079826355, 121.715034485,
  -116.681983948, 118.212944031,
  -116.505722046, 115.265075684,
  -116.794822693, 115.695030212,
  -114.131347656, 114.419540405,
  -111.792861938, 110.8775177,
  -111.66632843, 107.927101135,
  -111.550346375, 104.775566101,
  -111.459640503, 101.823822021,
  -113.531417847, 98.1194839478,
  -116.093925476, 96.6516342163,
  -138.815475464, 76.9742050171,
  -140.634353638, 74.6476669312,
  -144.59664917, 73.126335144,
  -147.505157471, 73.6377792358,
  -150.607818604, 74.2028121948,
  -153.509902954, 74.7495422363,
  -156.681503296, 77.5700378418,
  -157.563430786, 80.3884124756,
  -157.179168701, 80.0408477783,
  -160.071624756, 80.6364059448,
  -163.195266724, 83.5099716187,
  -164.029556274, 86.3428039551,
  -164.902130127, 89.3733520508,
  -165.701950073, 92.2161178589,
  -164.584609985, 96.3107376099,
  -162.451705933, 98.3532104492,
  -160.75352478, 92.1803512573,
  -163.631027222, 92.8444213867,
  -166.685516357, 95.7913360596,
  -167.452316284, 98.6431808472,
  -168.252655029, 101.693618774,
  -168.984786987, 104.55456543,
  -167.770523071, 108.621505737,
  -165.589706421, 110.612754822,
  -165.742111206, 110.117546082,
  -166.425888062, 112.99042511,
  -165.143295288, 117.036331177,
  -162.929229736, 118.990539551,
  -160.5521698, 121.063056946,
  -158.314544678, 122.990242004,
  -154.131637573, 123.709701538,
  -151.378692627, 122.640914917,
  -155.614242554, 109.672035217,
  -158.467346191, 110.434112549,
  -161.419281006, 113.483764648,
  -162.088134766, 116.360160828,
  -162.783691406, 119.436172485,
  -163.417541504, 122.320480347,
  -162.064910889, 126.343513489,
  -159.81729126, 128.259033203,
  -159.986541748, 127.769332886,
  -160.571685791, 130.663925171,
  -159.151443481, 134.663574219,
  -156.871871948, 136.540939331,
  -154.425354004, 138.530975342,
  -152.123123169, 140.380508423,
  -147.918060303, 140.956512451,
  -145.203292847, 139.794204712,
  -150.217529297, 135.813583374,
  -150.733764648, 138.721237183,
  -149.218948364, 142.686050415,
  -146.89541626, 144.508758545,
  -144.40234375, 146.440124512,
  -142.056854248, 148.234466553,
  -137.839309692, 148.710479736,
  -135.152893066, 147.484024048,
  -135.668212891, 147.430252075,
  -133.292800903, 149.184814453,
  -129.067840576, 149.589660645,
  -126.40246582, 148.318099976,
  -123.564399719, 146.942962646,
  -120.914672852, 145.639129639,
  -118.614089966, 142.072387695,
  -118.519012451, 139.120788574,
  -135.335769653, 126.454421997,
  -138.14642334, 127.360671997,
  -140.939422607, 130.556533813,
  -141.461044312, 133.463241577,
  -141.999237061, 136.570648193,
  -142.485534668, 139.483474731,
  -140.929992676, 143.432495117,
  -138.587844849, 145.231170654,
  -138.781799316, 144.750732422,
  -139.218933105, 147.671325684,
  -137.597076416, 151.593566895,
  -135.22492981, 153.352523804,
  -132.680358887, 155.215515137,
  -130.287017822, 156.945541382,
  -126.058097839, 157.306884766,
  -123.405952454, 156.007965088,
  -128.616195679, 152.287536621,
  -128.983840942, 155.217712402,
  -127.269317627, 159.100341797,
  -124.856063843, 160.802474976,
  -122.267951965, 162.604537964,
  -119.834220886, 164.277236938,
  -115.597923279, 164.538040161,
  -112.977371216, 163.176513672,
  -113.494766235, 163.149047852,
  -111.033187866, 164.780487061,
  -106.793083191, 164.969894409,
  -104.195846558, 163.56439209,
  -101.431419373, 162.046646118,
  -98.8514404297, 160.609710693,
  -96.7352828979, 156.930557251,
  -96.7904815674, 153.977935791,
  -110.424674988, 153.48739624,
  -110.691902161, 156.428436279,
  -108.845611572, 160.250152588,
  -106.375572205, 161.868789673,
  -103.727371216, 163.581268311,
  -101.237854004, 165.169769287,
  -96.9951019287, 165.285568237,
  -94.4226455688, 163.835220337,
  -94.9406738281, 163.825454712,
  -92.4247436523, 165.371780396,
  -88.1806488037, 165.416061401,
  -85.6329956055, 163.922546387,
  -82.922088623, 162.311203003,
  -80.3927536011, 160.786880493,
  -78.4036407471, 157.037506104,
  -78.5597686768, 154.088500977,
  -84.0238952637, 157.424865723,
  -81.4719543457, 158.911010742,
  -77.2280044556, 158.854492188,
  -74.7165374756, 157.300918579,
  -72.0446548462, 155.625610352,
  -69.5522384644, 154.041671753,
  -67.6527175903, 150.246109009,
  -67.8788375854, 147.301651001,
  -68.106803894, 147.766937256,
  -65.6414337158, 146.141204834,
  -63.8061599731, 142.314178467,
  -64.0818710327, 139.373931885,
  -64.395362854, 136.23588562,
  -64.7067642212, 133.299224854,
  -67.2628707886, 129.910903931,
  -70.0011291504, 128.805023193,
  -97.2014312744, 128.911071777,
  -99.9309844971, 130.038269043,
  -102.460586548, 133.446411133,
  -102.749076843, 136.385437012,
  -103.038101196, 139.525817871,
  -103.290893555, 142.46812439,
  -101.425811768, 146.280715942,
  -98.947845459, 147.887176514,
  -99.17943573, 147.423690796,
  -99.3825912476, 150.369827271,
  -97.4535217285, 154.15045166,
  -94.9488372803, 155.714920044,
  -92.263961792, 157.369354248,
  -89.7404632568, 158.903274536,
  -85.4962005615, 158.926696777,
  -82.9559326172, 157.420700073,
  -88.445892334, 154.127029419,
  -88.5790405273, 157.077163696,
  -86.5607528687, 160.810913086,
  -84.0196151733, 162.315444946,
  -81.2962112427, 163.905654907,
  -78.7369995117, 165.379226685,
  -74.4933700562, 165.301864624,
  -71.9895782471, 163.735961914,
  -72.5075149536, 163.749786377,
  -69.923828125, 165.180007935,
  -65.6821060181, 165.03112793,
  -63.2050628662, 163.42326355,
  -60.5702819824, 161.690170288,
  -58.1129417419, 160.052337646,
  -56.2964935303, 156.216339111,
  -56.5866622925, 153.277496338,
  -70.2166061401, 153.874328613,
  -70.2487869263, 156.827285767,
  -68.1039962769, 160.489837646,
  -65.5129013062, 161.906600952,
  -62.7367210388, 163.402755737,
  -60.1286087036, 164.787963867,
  -55.8901138306, 164.565505981,
  -53.4413261414, 162.914932251,
  -53.9584846497, 162.946426392,
  -51.3274040222, 164.287475586,
  -47.0932540894, 163.993606567,
  -44.6726341248, 162.301986694,
  -42.0986595154, 160.479812622,
  -39.6987571716, 158.758895874,
  -38.0145530701, 154.863037109,
  -38.4050445557, 151.935836792,
  -43.5861206055, 155.696777344,
  -40.9239311218, 156.974975586,
  -36.6979560852, 156.580657959,
  -34.3181877136, 154.83203125,
  -31.7882099152, 152.949249268,
  -29.4298553467, 151.171844482,
  -27.838634491, 147.237075806,
  -28.2985248566, 144.319961548,
  -28.4887104034, 144.8019104,
  -26.1606464386, 142.985015869,
  -24.6359729767, 139.023986816,
  -25.1449623108, 136.11505127,
  -25.7073726654, 133.011917114,
  -26.2516517639, 130.109390259,
  -29.0694770813, 126.935386658,
  -31.8871059418, 126.051071167,
  -48.6046066284, 138.848175049,
  -48.4865150452, 141.798950195,
  -46.1581878662, 145.34765625,
  -43.498374939, 146.630767822,
  -40.6496734619, 147.983764648,
  -37.9744720459, 149.234481812,
  -33.7527770996, 148.796707153,
  -31.39112854, 147.023666382,
  -31.9060211182, 147.081451416,
  -29.2101192474, 148.286911011,
  -24.9964027405, 147.778060913,
  -22.66497612, 145.96546936,
  -20.1870250702, 144.014709473,
  -17.8777809143, 142.173950195,
  -16.3939476013, 138.19744873,
  -16.932844162, 135.293899536,
  -21.9158859253, 139.313537598,
  -19.1921195984, 140.45463562,
  -14.9916744232, 139.845855713,
  -12.7039442062, 137.978408813,
  -10.273018837, 135.96937561,
  -8.00813674927, 134.074295044,
  -6.61914587021, 130.063674927,
  -7.2268409729, 127.173736572,
  -7.39227056503, 127.664749146,
  -5.1596493721, 125.731758118,
  -3.83845019341, 121.698303223,
  -4.49476528168, 118.819015503,
  -5.21430635452, 115.748542786,
  -5.90554094315, 112.877433777,
  -8.88118362427, 109.850891113,
  -11.7401504517, 109.111083984,
  -15.8744115829, 122.112594604,
  -13.1132173538, 123.159889221,
  -8.93604850769, 122.407844543,
  -6.71351528168, 120.463272095,
  -4.35271167755, 118.372276306,
  -2.15395712852, 116.400856018,
  -0.902923822403, 112.345085144,
  -1.60908639431, 109.477615356,
  -1.75762856007, 109.973983765,
  0.407587647438, 107.965782166,
  1.59008848667, 103.889511108,
  0.835698723793, 101.034355164,
  0.0115814423189, 97.9902648926,
  -0.777427017689, 95.1444854736,
  -3.85482382774, 92.2214813232,
  -6.73741674423, 91.5798568726,
  -4.99113607407, 97.7392730713,
  -2.87421751022, 95.680229187,
  -1.78884601593, 91.5770263672,
  -2.61082220078, 88.7405853271,
  -3.50699210167, 85.7169265747,
  -4.36335420609, 82.8906784058,
  -7.50929355621, 80.041557312,
  -10.4063091278, 79.4685668945,
  -10.0193557739, 79.8131332397,
  -10.9232301712, 77.0017318726,
  -14.1167392731, 74.2060470581,
  -17.0230007172, 73.681968689,
  -20.1299552917, 73.1411514282,
  -23.0423679352, 72.6524047852,
  -26.9926872253, 74.2045974731,
  -28.7933578491, 76.5452423096,
  28.7933597565, 76.5452423096,
  26.99269104, 74.2045974731,
  23.0423698425, 72.6524047852,
  20.1299571991, 73.1411514282,
  17.0230026245, 73.681968689,
  14.1167430878, 74.2060470581,
  10.9232311249, 77.0017318726,
  10.0193586349, 79.8131332397,
  10.4063119888, 79.4685668945,
  7.50929546356, 80.041557312,
  4.36335659027, 82.8906784058,
  3.50699448586, 85.7169265747,
  2.61082339287, 88.7405853271,
  1.78884744644, 91.5770263672,
  2.87421870232, 95.680229187,
  4.99113702774, 97.7392730713,
  6.7374162674, 91.5798568726,
  3.85482287407, 92.2214813232,
  0.777425825596, 95.1444854736,
  -0.0115825552493, 97.9902648926,
  -0.83569920063, 101.034355164,
  -1.59008932114, 103.889511108,
  -0.407588750124, 107.965782166,
  1.75762689114, 109.973983765,
  1.60908639431, 109.477615356,
  0.902923822403, 112.345085144,
  2.15395712852, 116.400856018,
  4.35271167755, 118.372276306,
  6.71351337433, 120.463272095,
  8.93604755402, 122.407844543,
  13.1132173538, 123.159889221,
  15.8744115829, 122.112594604,
  11.7401504517, 109.111083984,
  8.88118362427, 109.850891113,
  5.90554094315, 112.877433777,
  5.21430635452, 115.748542786,
  4.49476528168, 118.819015503,
  3.83845019341, 121.698303223,
  5.1596493721, 125.731758118,
  7.39227056503, 127.664749146,
  7.2268409729, 127.173736572,
  6.61914587021, 130.063674927,
  8.00813674927, 134.074295044,
  10.273018837, 135.96937561,
  12.7039442062, 137.978408813,
  14.9916744232, 139.845855713,
  19.1921195984, 140.45463562,
  21.9158859253, 139.313537598,
  16.932844162, 135.293899536,
  16.3939476013, 138.19744873,
  17.8777809143, 142.173950195,
  20.1870250702, 144.014709473,
  22.66497612, 145.96546936,
  24.9964027405, 147.778060913,
  29.2101192474, 148.286911011,
  31.9060211182, 147.081451416,
  31.39112854, 147.023666382,
  33.7527770996, 148.796707153,
  37.9744720459, 149.234481812,
  40.6496734619, 147.983764648,
  43.498374939, 146.630767822,
  46.1581878662, 145.34765625,
  48.4865150452, 141.798950195,
  48.6046066284, 138.848175049,
  31.8871116638, 126.051071167,
  29.0694828033, 126.935386658,
  26.2516593933, 130.109390259,
  25.7073764801, 133.011917114,
  25.1449699402, 136.11505127,
  24.6359806061, 139.023986816,
  26.1606559753, 142.985015869,
  28.4887237549, 144.8019104,
  28.2985286713, 144.319976807,
  27.8386383057, 147.237091064,
  29.4298591614, 151.171844482,
  31.7882137299, 152.949249268,
  34.318195343, 154.83203125,
  36.6979637146, 156.580657959,
  40.9239425659, 156.974975586,
  43.5861282349, 155.696777344,
  38.4050559998, 151.935852051,
  38.0145606995, 154.863052368,
  39.6987724304, 158.758926392,
  42.0986671448, 160.479827881,
  44.6726493835, 162.302001953,
  47.0932693481, 163.993652344,
  51.3274116516, 164.287475586,
  53.9584999084, 162.94644165,
  53.4413337708, 162.914932251,
  55.8901176453, 164.565505981,
  60.128616333, 164.787963867,
  62.7367286682, 163.402755737,
  65.5129089355, 161.906600952,
  68.1040039062, 160.489837646,
  70.2487869263, 156.827285767,
  70.2166061401, 153.874328613,
  56.5866622925, 153.277496338,
  56.2964935303, 156.216339111,
  58.1129417419, 160.052337646,
  60.5702819824, 161.690170288,
  63.2050666809, 163.42326355,
  65.6821212769, 165.03112793,
  69.9238357544, 165.180007935,
  72.5075302124, 163.749786377,
  71.9895858765, 163.735961914,
  74.4933853149, 165.301864624,
  78.7370071411, 165.379226685,
  81.2962188721, 163.905654907,
  84.0196228027, 162.315444946,
  86.5607528687, 160.810913086,
  88.5790405273, 157.077163696,
  88.4459075928, 154.127029419,
  82.9559402466, 157.420700073,
  85.4962081909, 158.926696777,
  89.7404708862, 158.903274536,
  92.2639770508, 157.369354248,
  94.9488449097, 155.714904785,
  97.4535369873, 154.150436401,
  99.382598877, 150.369827271,
  99.1794509888, 147.423690796,
  98.9478530884, 147.887176514,
  101.425819397, 146.280715942,
  103.290893555, 142.46812439,
  103.038116455, 139.525817871,
  102.749084473, 136.385437012,
  102.460594177, 133.446411133,
  99.9309921265, 130.038284302,
  97.2014389038, 128.911071777,
  70.0011291504, 128.805007935,
  67.2628707886, 129.910888672,
  64.7067642212, 133.299209595,
  64.395362854, 136.23588562,
  64.0818710327, 139.373916626,
  63.8061599731, 142.314163208,
  65.6414337158, 146.141174316,
  68.106803894, 147.766906738,
  67.8788375854, 147.301635742,
  67.6527175903, 150.24609375,
  69.5522384644, 154.041656494,
  72.0446548462, 155.625595093,
  74.7165374756, 157.30090332,
  77.2280044556, 158.854476929,
  81.4719543457, 158.910980225,
  84.0238952637, 157.424835205,
  78.5597686768, 154.088500977,
  78.4036407471, 157.037506104,
  80.3927536011, 160.786880493,
  82.922088623, 162.311203003,
  85.6329956055, 163.922546387,
  88.1806488037, 165.416061401,
  92.4247436523, 165.371780396,
  94.9406738281, 163.825454712,
  94.4226455688, 163.835220337,
  96.9951019287, 165.285552979,
  101.237854004, 165.16973877,
  103.727371216, 163.581237793,
  106.375572205, 161.868759155,
  108.845611572, 160.25012207,
  110.691902161, 156.428405762,
  110.424674988, 153.487380981,
  96.7904968262, 153.977935791,
  96.7352905273, 156.930557251,
  98.8514556885, 160.609710693,
  101.431427002, 162.046646118,
  104.195854187, 163.56439209,
  106.793083191, 164.969894409,
  111.033195496, 164.780487061,
  113.494773865, 163.149047852,
  112.977386475, 163.176513672,
  115.597930908, 164.538040161,
  119.834243774, 164.277236938,
  122.267974854, 162.604507446,
  124.856086731, 160.802474976,
  127.269325256, 159.100341797,
  128.98387146, 155.217712402,
  128.616195679, 152.287536621,
  123.405952454, 156.007949829,
  126.058097839, 157.306884766,
  130.287017822, 156.945526123,
  132.680358887, 155.215484619,
  135.22492981, 153.352508545,
  137.597076416, 151.593536377,
  139.218933105, 147.671310425,
  138.781799316, 144.750717163,
  138.587844849, 145.231170654,
  140.929992676, 143.432479858,
  142.485534668, 139.483459473,
  141.999237061, 136.570648193,
  141.461044312, 133.463241577,
  140.939422607, 130.556533813,
  138.14642334, 127.360656738,
  135.335769653, 126.454421997,
  118.519012451, 139.120758057,
  118.614089966, 142.072372437,
  120.914672852, 145.63911438,
  123.564399719, 146.942947388,
  126.40246582, 148.318084717,
  129.067840576, 149.589645386,
  133.292800903, 149.184799194,
  135.668212891, 147.430236816,
  135.152893066, 147.484008789,
  137.839309692, 148.710449219,
  142.056854248, 148.234466553,
  144.40234375, 146.440109253,
  146.89541626, 144.508743286,
  149.218948364, 142.686035156,
  150.733764648, 138.721221924,
  150.217529297, 135.813552856,
  145.203292847, 139.794189453,
  147.918060303, 140.956512451,
  152.123123169, 140.380477905,
  154.425354004, 138.530944824,
  156.871871948, 136.540908813,
  159.151443481, 134.66355896,
  160.571685791, 130.663909912,
  159.986541748, 127.769325256,
  159.81729126, 128.259017944,
  162.064910889, 126.343490601,
  163.417541504, 122.320457458,
  162.783691406, 119.436149597,
  162.088134766, 116.360153198,
  161.419281006, 113.48374939,
  158.467330933, 110.434104919,
  155.614212036, 109.672019958,
  151.378692627, 122.640899658,
  154.131637573, 123.709701538,
  158.314559937, 122.990226746,
  160.552185059, 121.063049316,
  162.929229736, 118.990524292,
  165.143295288, 117.036315918,
  166.425888062, 112.99042511,
  165.742111206, 110.117538452,
  165.589706421, 110.612739563,
  167.770523071, 108.621490479,
  168.984786987, 104.554550171,
  168.252655029, 101.693611145,
  167.452316284, 98.6431732178,
  166.685516357, 95.7913284302,
  163.631027222, 92.8444213867,
  160.75352478, 92.1803359985,
  162.451705933, 98.3532028198,
  164.584609985, 96.3107299805,
  165.701950073, 92.2161026001,
  164.902130127, 89.373336792,
  164.029556274, 86.3427963257,
  163.195266724, 83.5099563599,
  160.071624756, 80.6363983154,
  157.179168701, 80.0408401489,
  157.563430786, 80.3883972168,
  156.681503296, 77.570022583,
  153.509902954, 74.7495269775,
  150.607818604, 74.202796936,
  147.505157471, 73.6377716064,
  144.59664917, 73.1263275146,
  140.634353638, 74.6476669312,
  138.815475464, 76.9741973877,
  116.093925476, 96.6516342163,
  113.531417847, 98.1194839478,
  111.459640503, 101.823822021,
  111.550346375, 104.775566101,
  111.66632843, 107.927101135,
  111.792861938, 110.8775177,
  114.131347656, 114.419540405,
  116.794822693, 115.695030212,
  116.505722046, 115.265090942,
  116.681983948, 118.21295929,
  119.079826355, 121.715049744,
  121.764419556, 122.945472717,
  124.63923645, 124.242012024,
  127.338577271, 125.43976593,
  131.550827026, 124.918823242,
  133.877044678, 123.099578857,
  128.010101318, 120.536949158,
  128.25630188, 123.479812622,
  130.73664856, 126.923980713,
  133.449707031, 128.090316772,
  136.354492188, 129.318237305,
  139.08152771, 130.451538086,
  143.280212402, 129.830734253,
  145.562576294, 127.956756592,
  145.050674438, 128.036865234,
  147.796417236, 129.124023438,
  151.984039307, 128.432556152,
  154.234512329, 126.520370483,
  156.625350952, 124.463790894,
  158.852416992, 122.524429321,
  160.162078857, 118.487197876,
  159.497512817, 115.609809875,
  146.056564331, 117.949180603,
  146.403244019, 120.881896973,
  148.999908447, 124.239242554,
  151.751266479, 125.31212616,
  154.696365356, 126.439987183,
  157.460540771, 127.479377747,
  161.635528564, 126.715354919,
  163.852508545, 124.764427185,
  163.343643188, 124.861991882,
  166.124954224, 125.854652405,
  170.286453247, 125.020362854,
  172.470230103, 123.032356262,
  174.789337158, 120.895225525,
  176.948791504, 118.880828857,
  178.119628906, 114.801177979,
  177.357055664, 111.948204041,
  172.700927734, 116.342346191,
  175.505020142, 117.268661499,
  179.645568848, 116.33581543,
  181.781509399, 114.296508789,
  184.049224854, 112.104896545,
  186.160247803, 110.039802551,
  187.233856201, 105.933509827,
  186.403762817, 103.099433899,
  186.276931763, 103.601783752,
  188.352859497, 101.501411438,
  189.357086182, 97.3775863647,
  188.479354858, 94.5579071045,
  187.523757935, 91.5525131226,
  186.611846924, 88.7436904907,
  183.410339355, 85.9571762085,
  180.502593994, 85.4414215088,
  165.563781738, 100.27620697,
  166.059204102, 103.187492371,
  168.823272705, 106.408378601,
  171.625656128, 107.339920044,
  174.624282837, 108.316490173,
  177.437774658, 109.213920593,
  181.568496704, 108.238502502,
  183.683349609, 106.177322388,
  183.180084229, 106.30065155,
  186.008285522, 107.150520325,
  190.122009277, 106.105613708,
  192.201797485, 104.009101868,
  194.409194946, 101.756744385,
  196.463409424, 99.6351089478,
  197.425140381, 95.5011825562,
  196.518447876, 92.6906890869,
  192.091888428, 97.3160018921,
  194.939453125, 98.0984802246,
  199.027175903, 96.9561920166,
  201.056610107, 94.8108596802,
  203.209899902, 92.5067367554,
  205.213134766, 90.3369216919,
  206.076477051, 86.1813201904,
  205.103286743, 83.3931427002,
  205.002166748, 83.9013061523,
  206.968536377, 81.6980361938,
  207.761703491, 77.5284805298,
  206.741668701, 74.7570953369,
  205.634399414, 71.8042068481,
  204.580764771, 69.045425415,
  201.241668701, 66.4253692627,
  198.311447144, 66.0581970215,
  195.878204346, 79.4824676514,
  198.75088501, 80.1671142578,
  202.797164917, 78.8857192993,
  204.752029419, 76.6722412109,
  206.825271606, 74.2958297729,
  208.753143311, 72.0587768555,
  209.473846436, 67.8760910034,
  208.405853271, 65.1228256226,
  208.322219849, 65.634147644,
  210.212081909, 63.3649215698,
  210.862213135, 59.1706771851,
  209.748031616, 56.4358062744,
  208.540405273, 53.5224914551,
  207.393051147, 50.8013572693,
  203.966323853, 48.2970199585,
  201.025253296, 48.0302658081,
  203.546798706, 53.9149703979,
  205.382247925, 51.6015129089,
  205.932601929, 47.3930168152,
  204.753753662, 44.6853713989,
  203.477325439, 41.8015556335,
  202.265670776, 39.1084289551,
  198.780410767, 36.6861724854,
  195.833862305, 36.4893341064,
  196.261795044, 36.7814254761,
  195.00491333, 34.1091003418,
  191.4793396, 31.745929718,
  188.529876709, 31.5987796783,
  185.379226685, 31.4607658386,
  182.428192139, 31.349445343,
  178.709472656, 33.3952903748,
  177.223754883, 35.9474830627,
  173.442947388, 62.883972168,
  174.168655396, 65.7465515137,
  177.180465698, 68.7370910645,
  180.048126221, 69.4424819946,
  183.115005493, 70.177154541,
  185.991012573, 70.8476715088,
  190.030944824, 69.5463867188,
  191.97492981, 67.3233261108,
  191.483108521, 67.4863357544,
  194.370025635, 68.1082839966,
  198.387435913, 66.7390899658,
  200.293670654, 64.4835891724,
  202.314712524, 62.0626029968,
  204.193389893, 59.7841148376,
  204.822891235, 55.5867271423,
  203.695236206, 52.8573646545,
  199.651062012, 57.8205108643,
  202.551925659, 58.3737258911,
  206.535690308, 56.9095344543,
  208.387832642, 54.6093978882,
  210.350784302, 52.1411018372,
  212.174865723, 49.8186416626,
  212.70451355, 45.6074867249,
  211.512359619, 42.9056739807,
  211.452026367, 43.4202766418,
  213.236694336, 41.0674057007,
  213.695297241, 36.8479232788,
  212.457778931, 34.1665840149,
  211.118850708, 31.3112411499,
  209.848892212, 28.6451263428,
  206.311706543, 26.2993202209,
  203.361541748, 26.1666755676,
  202.005142212, 39.7420883179,
  204.923202515, 40.1957855225,
  208.854598999, 38.5962142944,
  210.626983643, 36.2340927124,
  212.504394531, 33.7001190186,
  214.247955322, 31.3166465759,
  214.633300781, 27.0898456573,
  213.349456787, 24.4303760529,
  213.306793213, 24.9467353821,
  215.00994873, 22.5342159271,
  215.323989868, 18.3015174866,
  213.995529175, 15.6640634537,
  212.559738159, 12.85617733,
  211.199325562, 10.2350521088,
  207.584030151, 8.01156711578,
  204.631057739, 7.97988176346,
  207.613235474, 13.6450881958,
  209.258636475, 11.1928043365,
  209.472076416, 6.95384311676,
  208.081344604, 4.34868001938,
  206.579299927, 1.57567942142,
  205.157058716, -1.01240253448,
  201.489944458, -3.1494102478,
  198.537063599, -3.11096477509,
  198.986907959, -2.85387158394,
  197.521240234, -5.4176158905,
  193.818634033, -7.49251651764,
  190.866821289, -7.40430879593,
  187.715179443, -7.29097270966,
  184.764648438, -7.16692399979,
  181.220672607, -4.83142757416,
  179.942932129, -2.1690261364,
  190.220550537, 16.2051620483,
  193.157928467, 16.5098266602,
  197.002868652, 14.7123308182,
  198.652801514, 12.2631015778,
  200.398834229, 9.63690280914,
  202.01890564, 7.16781568527,
  202.188720703, 2.926882267,
  200.771255493, 0.336168378592,
  200.754898071, 0.854031682014,
  202.333114624, -1.64200925827,
  202.431427002, -5.88520145416,
  200.970489502, -8.45165729523,
  199.393737793, -11.1828660965,
  197.901763916, -13.7313909531,
  194.178024292, -15.7680797577,
  191.227279663, -15.6495008469,
  194.49382019, -10.1433401108,
  196.012298584, -12.676153183,
  196.009841919, -16.9204845428,
  194.488372803, -19.4515247345,
  192.847213745, -22.1445236206,
  191.295120239, -24.6569004059,
  187.52406311, -26.6045913696,
  184.576965332, -26.415977478,
  185.039291382, -26.182100296,
  183.445083618, -28.6679649353,
  179.641723633, -30.551820755,
  176.69821167, -30.3135643005,
  173.556427002, -30.0400447845,
  170.616027832, -29.7660579681,
  167.195449829, -27.2532978058,
  166.054779053, -24.5293426514,
  178.332366943, -18.5801353455,
  179.763381958, -21.163394928,
  179.615768433, -25.4051570892,
  178.008636475, -27.8826904297,
  176.276351929, -30.5179901123,
  174.63923645, -32.9758224487,
  170.80380249, -34.793422699,
  167.864852905, -34.5041389465,
  168.33493042, -34.2862167358,
  166.656661987, -36.7161064148,
  162.791137695, -38.4688072205,
  159.85748291, -38.1300315857,
  156.726882935, -37.7492332458,
  153.797576904, -37.3748588562,
  150.464904785, -34.7466011047,
  149.418060303, -31.9852313995,
  155.763793945, -32.8336830139,
  154.028289795, -35.2230377197,
  150.122192383, -36.8834495544,
  147.197433472, -36.4751091003,
  144.076751709, -36.0200805664,
  141.157150269, -35.5762519836,
  137.8878479, -32.869594574,
  136.90687561, -30.0841503143,
  137.30317688, -30.4179115295,
  134.391464233, -29.9249401093,
  131.168258667, -27.1635665894,
  130.234375, -24.3619804382,
  129.255264282, -21.3641529083,
  128.35546875, -18.5514354706,
  129.327407837, -14.4198875427,
  131.386810303, -12.3033123016,
  131.38684082, 12.3032913208,
  129.327453613, 14.4198684692,
  128.355499268, 18.5514144897,
  129.2552948, 21.3641357422,
  130.234420776, 24.3619651794,
  131.168304443, 27.1635513306,
  134.391525269, 29.9249210358,
  137.303222656, 30.4178924561,
  136.906921387, 30.0841274261,
  137.887893677, 32.8695755005,
  141.157196045, 35.5762290955,
  144.076797485, 36.0200576782,
  147.197463989, 36.4750823975,
  150.1222229, 36.8834266663,
  154.028320312, 35.2230072021,
  155.763824463, 32.8336524963,
  149.418106079, 31.9852085114,
  150.464935303, 34.7465744019,
  153.797622681, 37.374835968,
  156.726928711, 37.749206543,
  159.857513428, 38.1300048828,
  162.791168213, 38.4687805176,
  166.656677246, 36.7160797119,
  168.334976196, 34.2861862183,
  167.864883423, 34.5041122437,
  170.803833008, 34.7933921814,
  174.639282227, 32.9757957458,
  176.276382446, 30.5179595947,
  178.008666992, 27.8826599121,
  179.615783691, 25.4051265717,
  179.763397217, 21.1633605957,
  178.332397461, 18.5801010132,
  166.054824829, 24.5293178558,
  167.195480347, 27.2532730103,
  170.616088867, 29.7660369873,
  173.556472778, 30.0400238037,
  176.698272705, 30.3135414124,
  179.641769409, 30.5517959595,
  183.445114136, 28.6679401398,
  185.039321899, 26.1820755005,
  184.57699585, 26.4159488678,
  187.524093628, 26.6045627594,
  191.295166016, 24.6568717957,
  192.847244263, 22.1444969177,
  194.48840332, 19.4514980316,
  196.009887695, 16.9204559326,
  196.01234436, 12.6761255264,
  194.493835449, 10.1433115005,
  191.227294922, 15.6494722366,
  194.178039551, 15.7680501938,
  197.901779175, 13.7313604355,
  199.393768311, 11.1828346252,
  200.97052002, 8.45162582397,
  202.431442261, 5.8851685524,
  202.333129883, 1.64197587967,
  200.75491333, -0.854064583778,
  200.771270752, -0.336198985577,
  202.188751221, -2.92691326141,
  202.01890564, -7.16784524918,
  200.398834229, -9.63693237305,
  198.652816772, -12.2631320953,
  197.002868652, -14.7123613358,
  193.157943726, -16.5098552704,
  190.220565796, -16.2051925659,
  179.942932129, 2.16899299622,
  181.220687866, 4.83139419556,
  184.764678955, 7.16689014435,
  187.715209961, 7.29093742371,
  190.866851807, 7.40427303314,
  193.818664551, 7.49247980118,
  197.521255493, 5.4175786972,
  198.986923218, 2.85383462906,
  198.537078857, 3.11092591286,
  201.489959717, 3.14937019348,
  205.157058716, 1.01236081123,
  206.579330444, -1.57572174072,
  208.081359863, -4.34872245789,
  209.472076416, -6.9538860321,
  209.258651733, -11.1928462982,
  207.613250732, -13.6451311111,
  204.631088257, -7.97992610931,
  207.584060669, -8.01161193848,
  211.199356079, -10.2350969315,
  212.559738159, -12.8562231064,
  213.995529175, -15.6641082764,
  215.324020386, -18.3015594482,
  215.00994873, -22.5342559814,
  213.306793213, -24.9467773438,
  213.349472046, -24.4304180145,
  214.63331604, -27.089887619,
  214.247970581, -31.3166885376,
  212.504394531, -33.7001647949,
  210.626998901, -36.2341384888,
  208.854598999, -38.5962600708,
  204.923202515, -40.1958312988,
  202.005142212, -39.7421264648,
  203.361541748, -26.1667194366,
  206.311706543, -26.29936409,
  209.848892212, -28.6451759338,
  211.118850708, -31.3112869263,
  212.457778931, -34.1666297913,
  213.695297241, -36.8479690552,
  213.236694336, -41.0674514771,
  211.452026367, -43.4203224182,
  211.512359619, -42.9057235718,
  212.70451355, -45.6075363159,
  212.174865723, -49.8186950684,
  210.350753784, -52.1411552429,
  208.387832642, -54.6094398499,
  206.535690308, -56.909576416,
  202.551925659, -58.3737678528,
  199.651062012, -57.8205528259,
  203.695236206, -52.8574142456,
  204.822860718, -55.5867767334,
  204.193389893, -59.7841567993,
  202.314682007, -62.0626487732,
  200.293670654, -64.4836349487,
  198.387435913, -66.7391281128,
  194.370025635, -68.1083221436,
  191.483108521, -67.4863739014,
  191.97492981, -67.3233795166,
  190.030944824, -69.5464324951,
  185.991012573, -70.8477172852,
  183.115005493, -70.1772003174,
  180.048126221, -69.4425201416,
  177.180465698, -68.7371292114,
  174.168655396, -65.7465896606,
  173.442947388, -62.8840026855,
  177.223754883, -35.9475212097,
  178.709472656, -33.3953323364,
  182.428192139, -31.3494911194,
  185.379226685, -31.460811615,
  188.529876709, -31.5988292694,
  191.4793396, -31.7459793091,
  195.00491333, -34.1091537476,
  196.261795044, -36.7814788818,
  195.833862305, -36.4893798828,
  198.780410767, -36.686214447,
  202.265670776, -39.1084785461,
  203.477325439, -41.8016090393,
  204.753753662, -44.68542099,
  205.932601929, -47.3930664062,
  205.382247925, -51.6015625,
  203.546798706, -53.915019989,
  201.025238037, -48.0303115845,
  203.966308594, -48.2970619202,
  207.393051147, -50.801399231,
  208.540405273, -53.5225410461,
  209.748001099, -56.4358558655,
  210.862213135, -59.1707267761,
  210.212081909, -63.3649711609,
  208.322219849, -65.6341934204,
  208.405853271, -65.1228713989,
  209.473831177, -67.8761291504,
  208.753128052, -72.0588226318,
  206.825271606, -74.2958679199,
  204.75201416, -76.6722869873,
  202.797134399, -78.8857650757,
  198.750869751, -80.1671600342,
  195.878189087, -79.4825134277,
  198.311447144, -66.0582580566,
  201.241668701, -66.4254226685,
  204.580764771, -69.0454788208,
  205.634399414, -71.8042602539,
  206.741668701, -74.7571716309,
  207.761703491, -77.5285415649,
  206.968536377, -81.6980895996,
  205.002166748, -83.9013595581,
  205.103256226, -83.3932037354,
  206.076446533, -86.1813735962,
  205.213119507, -90.3369750977,
  203.209899902, -92.5067901611,
  201.056594849, -94.8109207153,
  199.027160645, -96.9562454224,
  194.939437866, -98.0985336304,
  192.091873169, -97.3160552979,
  196.518432617, -92.6907424927,
  197.425140381, -95.5012359619,
  196.463378906, -99.6351623535,
  194.409194946, -101.75680542,
  192.201782227, -104.009147644,
  190.12197876, -106.105674744,
  186.008270264, -107.15058136,
  183.18006897, -106.300712585,
  183.683334351, -106.177383423,
  181.568466187, -108.238563538,
  177.437759399, -109.213981628,
  174.624267578, -108.316551208,
  171.62562561, -107.33996582,
  168.823257446, -106.408439636,
  166.059173584, -103.187545776,
  165.563751221, -100.276252747,
  180.502593994, -85.4414520264,
  183.410339355, -85.9572143555,
  186.611846924, -88.7437515259,
  187.523757935, -91.5525512695,
  188.479354858, -94.5579528809,
  189.357086182, -97.3776397705,
  188.352859497, -101.501449585,
  186.276931763, -103.601837158,
  186.403762817, -103.099472046,
  187.233856201, -105.933532715,
  186.160247803, -110.039825439,
  184.049224854, -112.104927063,
  181.781494141, -114.296539307,
  179.64553833, -116.335853577,
  175.505020142, -117.268699646,
  172.700927734, -116.342384338,
  177.357055664, -111.948242188,
  178.119628906, -114.801216125,
  176.948791504, -118.880867004,
  174.789337158, -120.895263672,
  172.470230103, -123.032394409,
  170.286453247, -125.020401001,
  166.124938965, -125.854690552,
  163.34362793, -124.862030029,
  163.852508545, -124.764465332,
  161.635528564, -126.715393066,
  157.460540771, -127.479400635,
  154.696365356, -126.44002533,
  151.751251221, -125.312149048,
  148.999908447, -124.239273071,
  146.403244019, -120.88193512,
  146.05657959, -117.94921875,
  159.497512817, -115.609855652,
  160.162078857, -118.487236023,
  158.852416992, -122.524467468,
  156.625350952, -124.463829041,
  154.234512329, -126.52040863,
  151.984039307, -128.432601929,
  147.796417236, -129.124069214,
  145.050674438, -128.036895752,
  145.562576294, -127.95677948,
  143.280197144, -129.830764771,
  139.08152771, -130.451568604,
  136.354492188, -129.318267822,
  133.449707031, -128.090332031,
  130.73664856, -126.924003601,
  128.25630188, -123.47983551,
  128.010101318, -120.536987305,
  133.877044678, -123.099601746,
  131.550827026, -124.91884613,
  127.338577271, -125.439788818,
  124.639228821, -124.242034912,
  121.764419556, -122.945495605,
  119.079826355, -121.715049744,
  116.681983948, -118.21295929,
  116.505722046, -115.265090942,
  116.794822693, -115.69506073,
  114.131347656, -114.419563293,
  111.792861938, -110.877540588,
  111.66632843, -107.927124023,
  111.550346375, -104.77557373,
  111.459640503, -101.82383728,
  113.531417847, -98.1194992065,
  116.093925476, -96.6516571045,
  138.815475464, -76.9742050171,
  140.634338379, -74.6476669312,
  144.59664917, -73.126335144,
  147.505157471, -73.6377792358,
  150.607788086, -74.2028045654,
  153.509887695, -74.7495422363,
  156.681488037, -77.5700378418,
  157.563415527, -80.3884124756,
  157.179168701, -80.0408477783,
  160.071624756, -80.6364059448,
  163.195266724, -83.5099716187,
  164.029556274, -86.3428039551,
  164.902130127, -89.3733520508,
  165.701950073, -92.2161254883,
  164.584609985, -96.3107376099,
  162.451705933, -98.3532104492,
  160.75352478, -92.1803588867,
  163.631027222, -92.8444366455,
  166.685516357, -95.791343689,
  167.452316284, -98.643196106,
  168.252655029, -101.693626404,
  168.984786987, -104.554573059,
  167.770523071, -108.621505737,
  165.589706421, -110.612754822,
  165.742111206, -110.117546082,
  166.425888062, -112.990432739,
  165.143295288, -117.036346436,
  162.929229736, -118.99054718,
  160.5521698, -121.063072205,
  158.314544678, -122.990249634,
  154.131637573, -123.709716797,
  151.378692627, -122.640914917,
  155.614242554, -109.672035217,
  158.467346191, -110.434127808,
  161.419281006, -113.483764648,
  162.088134766, -116.360160828,
  162.783691406, -119.436172485,
  163.417541504, -122.320480347,
  162.064910889, -126.343513489,
  159.81729126, -128.259033203,
  159.986541748, -127.769332886,
  160.571685791, -130.663925171,
  159.151443481, -134.663574219,
  156.871871948, -136.540939331,
  154.425354004, -138.530975342,
  152.123123169, -140.380508423,
  147.918060303, -140.956512451,
  145.203292847, -139.794204712,
  150.217529297, -135.813583374,
  150.733764648, -138.721237183,
  149.218948364, -142.686050415,
  146.89541626, -144.508758545,
  144.40234375, -146.440124512,
  142.056854248, -148.234466553,
  137.839294434, -148.710479736,
  135.152877808, -147.484024048,
  135.668212891, -147.430236816,
  133.292800903, -149.184814453,
  129.067840576, -149.589645386,
  126.40246582, -148.318099976,
  123.564399719, -146.942947388,
  120.914672852, -145.639129639,
  118.614089966, -142.072387695,
  118.519012451, -139.120773315,
  135.335769653, -126.454421997,
  138.14642334, -127.360671997,
  140.939422607, -130.556533813,
  141.461044312, -133.463241577,
  141.999237061, -136.570648193,
  142.485534668, -139.483474731,
  140.929992676, -143.432495117,
  138.587844849, -145.231170654,
  138.781799316, -144.750732422,
  139.218933105, -147.671325684,
  137.597076416, -151.593566895,
  135.22492981, -153.352523804,
  132.680358887, -155.215515137,
  130.287017822, -156.945541382,
  126.058097839, -157.306884766,
  123.405952454, -156.007965088,
  128.616195679, -152.287536621,
  128.983840942, -155.217712402,
  127.269317627, -159.100341797,
  124.856063843, -160.802474976,
  122.267951965, -162.604507446,
  119.834220886, -164.277236938,
  115.597923279, -164.538040161,
  112.977371216, -163.176513672,
  113.494766235, -163.149047852,
  111.033187866, -164.780487061,
  106.793083191, -164.969894409,
  104.195846558, -163.56439209,
  101.431419373, -162.046646118,
  98.8514404297, -160.609710693,
  96.7352828979, -156.930557251,
  96.7904815674, -153.977935791,
  110.424674988, -153.487380981,
  110.691902161, -156.428405762,
  108.845611572, -160.25012207,
  106.375572205, -161.868759155,
  103.727348328, -163.581237793,
  101.237838745, -165.16973877,
  96.9950866699, -165.285552979,
  94.4226226807, -163.835220337,
  94.9406585693, -163.825454712,
  92.4247283936, -165.371780396,
  88.1806259155, -165.416061401,
  85.6329727173, -163.922546387,
  82.9220657349, -162.311203003,
  80.3927307129, -160.786880493,
  78.4036178589, -157.037506104,
  78.559753418, -154.088500977,
  84.0238876343, -157.424835205,
  81.4719467163, -158.910980225,
  77.2279968262, -158.854476929,
  74.7165222168, -157.30090332,
  72.0446472168, -155.625595093,
  69.552230835, -154.041656494,
  67.6527099609, -150.246109009,
  67.8788299561, -147.301635742,
  68.1067886353, -147.766937256,
  65.6414260864, -146.141204834,
  63.8061485291, -142.314178467,
  64.0818634033, -139.373931885,
  64.3953552246, -136.23588562,
  64.7067565918, -133.299224854,
  67.2628631592, -129.910903931,
  70.0011138916, -128.805023193,
  97.201423645, -128.911071777,
  99.9309768677, -130.038284302,
  102.460571289, -133.446411133,
  102.749069214, -136.385437012,
  103.038101196, -139.525817871,
  103.290878296, -142.46812439,
  101.425804138, -146.280715942,
  98.9478378296, -147.887176514,
  99.1794281006, -147.423690796,
  99.3825759888, -150.369827271,
  97.4535217285, -154.15045166,
  94.9488296509, -155.714920044,
  92.2639541626, -157.369354248,
  89.7404556274, -158.903274536,
  85.4961929321, -158.926681519,
  82.9559173584, -157.420700073,
  88.445892334, -154.127029419,
  88.5790252686, -157.077163696,
  86.5607376099, -160.810913086,
  84.0196075439, -162.315444946,
  81.2962036133, -163.905654907,
  78.7369918823, -165.379226685,
  74.4933624268, -165.301864624,
  71.9895629883, -163.735961914,
  72.5074996948, -163.749786377,
  69.9238128662, -165.180007935,
  65.6820983887, -165.03112793,
  63.2050361633, -163.42326355,
  60.5702629089, -161.690170288,
  58.1129226685, -160.052337646,
  56.2964782715, -156.216339111,
  56.586643219, -153.277496338,
  70.2165908813, -153.874328613,
  70.2487640381, -156.827285767,
  68.1039733887, -160.489837646,
  65.512878418, -161.906600952,
  62.7367019653, -163.402755737,
  60.1285896301, -164.787963867,
  55.8900947571, -164.565505981,
  53.4413070679, -162.914932251,
  53.9584693909, -162.946426392,
  51.3273849487, -164.287475586,
  47.0932388306, -163.993606567,
  44.672618866, -162.301986694,
  42.0986404419, -160.479812622,
  39.6987419128, -158.758895874,
  38.0145339966, -154.863037109,
  38.4050254822, -151.935836792,
  43.586101532, -155.696746826,
  40.9239120483, -156.974945068,
  36.6979370117, -156.5806427,
  34.3181724548, -154.832015991,
  31.788192749, -152.949249268,
  29.4298381805, -151.171829224,
  27.8386154175, -147.237045288,
  28.2985095978, -144.319946289,
  28.4886989594, -144.8019104,
  26.1606349945, -142.98500061,
  24.6359577179, -139.023986816,
  25.1449489594, -136.115036011,
  25.7073574066, -133.011917114,
  26.2516403198, -130.109375,
  29.0694656372, -126.935379028,
  31.8870925903, -126.051071167,
  48.6045875549, -138.848175049,
  48.4864997864, -141.798950195,
  46.1581726074, -145.34765625,
  43.4983558655, -146.630767822,
  40.6496543884, -147.983764648,
  37.9744529724, -149.234481812,
  33.7527580261, -148.796707153,
  31.3911132812, -147.023666382,
  31.9060020447, -147.081451416,
  29.210100174, -148.286911011,
  24.9963874817, -147.778060913,
  22.6649570465, -145.96546936,
  20.1870079041, -144.014709473,
  17.8777637482, -142.173950195,
  16.3939285278, -138.19744873,
  16.9328269958, -135.293899536,
  21.9158668518, -139.313537598,
  19.1921005249, -140.45463562,
  14.9916563034, -139.845855713,
  12.7039260864, -137.978408813,
  10.2730007172, -135.96937561,
  8.00811862946, -134.074295044,
  6.61912822723, -130.063674927,
  7.22682332993, -127.173736572,
  7.39225292206, -127.664749146,
  5.15963125229, -125.731758118,
  3.83843231201, -121.698303223,
  4.4947476387, -118.819015503,
  5.21428823471, -115.748542786,
  5.90552330017, -112.877433777,
  8.88116645813, -109.850891113,
  11.7401332855, -109.111083984,
  15.8743886948, -122.112594604,
  13.113196373, -123.159889221,
  8.93602561951, -122.407821655,
  6.71349191666, -120.463241577,
  4.3526892662, -118.372253418,
  2.15393590927, -116.400840759,
  0.902902901173, -112.345077515,
  1.60906612873, -109.477600098,
  1.75760579109, -109.973976135,
  -0.40760961175, -107.965766907,
  -1.59010958672, -103.88949585,
  -0.835718989372, -101.034347534,
  -0.0116023058072, -97.9902496338,
  0.7774066329, -95.1444702148,
  3.85480380058, -92.2214660645,
  6.73739671707, -91.5798492432,
  4.99112081528, -97.7392730713,
  2.87420201302, -95.680229187,
  1.7888302803, -91.5770263672,
  2.61080646515, -88.7405853271,
  3.50697636604, -85.7169265747,
  4.3633389473, -82.8906784058,
  7.50927734375, -80.041557312,
  10.406293869, -79.4685668945,
  10.0193395615, -79.8131332397,
  10.9232139587, -77.0017318726,
  14.1167240143, -74.2060470581,
  17.0229873657, -73.681968689,
  20.129940033, -73.1411514282,
  23.042350769, -72.6524047852,
  26.9926719666, -74.2045974731,
  28.793340683, -76.5452423096
]
