module.exports = [
  -25.9238681793, -76.8898010254,
  -21.9973392487, -75.3469619751,
  -16.0107727051, -76.3890075684,
  -12.8364973068, -79.1678543091,
  -9.43984889984, -82.1922912598,
  -6.31285858154, -85.0242462158,
  -4.58609294891, -90.8503265381,
  -5.6649274826, -94.928817749,
  -5.83590650558, -94.3257522583,
  -2.77704501152, -97.2311553955,
  -1.18911325932, -103.096595764,
  -2.36449193954, -107.1483078,
  -3.66836738586, -111.505439758,
  -4.91186571121, -115.536773682,
  -9.4607257843, -119.565765381,
  -13.612736702, -120.313293457,
  -10.9330644608, -111.886199951,
  -7.97534418106, -114.894508362,
  -6.58891248703, -120.810806274,
  -7.90215444565, -124.819976807,
  -9.35426044464, -129.129959106,
  -10.7348852158, -133.116409302,
  -15.4188594818, -136.987503052,
  -19.5940036774, -137.592605591,
  -19.1061172485, -137.199050903,
  -20.5810146332, -141.151580811,
  -25.3555927277, -144.910354614,
  -29.5439300537, -145.416168213,
  -34.063583374, -145.923370361,
  -38.2598495483, -146.358505249,
  -43.7488059998, -143.751541138,
  -46.0631141663, -140.224227905,
  -31.2222423553, -128.863677979,
  -28.4213867188, -132.018554688,
  -27.3377246857, -137.99772644,
  -28.8532180786, -141.934890747,
  -30.5227050781, -146.165420532,
  -32.1043434143, -150.07649231,
  -36.9791793823, -153.704269409,
  -41.1797027588, -154.096206665,
  -40.6724205017, -153.72795105,
  -42.3464813232, -157.600341797,
  -47.3060951233, -161.111358643,
  -51.5147399902, -161.403442383,
  -56.0543441772, -161.680084229,
  -60.2673149109, -161.901199341,
  -65.6165390015, -159.018356323,
  -67.7484130859, -155.377883911,
  -58.9139938354, -154.991027832,
  -60.719493866, -158.803924561,
  -65.7962646484, -162.143295288,
  -70.0124359131, -162.291275024,
  -74.5588378906, -162.412506104,
  -78.7769088745, -162.4894104,
  -84.0244216919, -159.425354004,
  -86.0305557251, -155.714111328,
  -85.4930343628, -156.036590576,
  -89.7117385864, -156.013305664,
  -94.8850250244, -152.825531006,
  -96.8024673462, -149.067672729,
  -98.8353347778, -144.999252319,
  -100.689178467, -141.209625244,
  -100.132278442, -135.158615112,
  -97.6179122925, -131.771011353,
  -69.5623550415, -131.661621094,
  -67.0216522217, -135.029541016,
  -66.4176025391, -141.076019287,
  -68.2418212891, -144.879989624,
  -70.2429199219, -148.964111328,
  -72.1310043335, -152.736816406,
  -77.279258728, -155.964859009,
  -81.4976577759, -156.021011353,
  -80.9626693726, -155.694366455,
  -82.9398117065, -159.421142578,
  -88.163269043, -162.526000977,
  -92.3818054199, -162.481994629,
  -96.9290237427, -162.396224976,
  -101.146217346, -162.28112793,
  -106.248855591, -158.981445312,
  -108.08404541, -155.182754517,
  -99.246887207, -155.500686646,
  -101.35030365, -159.157669067,
  -106.676887512, -162.082138062,
  -110.891456604, -161.893875122,
  -115.433067322, -161.652648926,
  -119.643867493, -161.3934021,
  -124.630706787, -157.921173096,
  -126.334907532, -154.061935425,
  -125.824783325, -154.426223755,
  -130.02822876, -154.067047119,
  -134.931228638, -150.477386475,
  -136.543304443, -146.578781128,
  -138.24571228, -142.361404419,
  -139.791854858, -138.436172485,
  -138.754867554, -132.448730469,
  -135.97869873, -129.272109985,
  -121.049697876, -140.516601562,
  -123.336433411, -144.061859131,
  -128.804901123, -146.711547852,
  -133.004425049, -146.309143066,
  -137.527893066, -145.837188721,
  -141.720046997, -145.364059448,
  -146.523773193, -141.64263916,
  -148.029464722, -137.701721191,
  -147.538528442, -138.091445923,
  -151.71824646, -137.51890564,
  -156.432250977, -133.684463501,
  -157.843933105, -129.708908081,
  -159.329605103, -125.410369873,
  -160.674072266, -121.411575317,
  -159.333831787, -115.484642029,
  -156.399658203, -112.453361511,
  -153.654327393, -120.859298706,
  -157.812042236, -120.144165039,
  -162.392196655, -116.150779724,
  -163.66708374, -112.129241943,
  -165.00491333, -107.782440186,
  -166.211837769, -103.740005493,
  -164.669708252, -97.8623580933,
  -161.633590698, -94.9331970215,
  -161.799850464, -95.5375823975,
  -162.910461426, -91.4676208496,
  -161.229171753, -85.6282653809,
  -158.124359131, -82.772026062,
  -154.751403809, -79.7211685181,
  -151.598907471, -76.9176635742,
  -145.620635986, -75.828956604,
  -141.6822052, -77.3411331177,
  -116.047554016, -99.5413665771,
  -113.988250732, -103.223396301,
  -114.211746216, -109.295852661,
  -116.536148071, -112.81653595,
  -119.073860168, -116.59072876,
  -121.457275391, -120.071739197,
  -126.996551514, -122.569969177,
  -131.183425903, -122.052185059,
  -130.60899353, -121.801269531,
  -133.074401855, -125.224693298,
  -138.671447754, -127.590698242,
  -142.844818115, -126.973625183,
  -147.338180542, -126.270515442,
  -151.500564575, -125.583190918,
  -156.107315063, -121.620513916,
  -157.409088135, -117.60760498,
  -148.697158813, -119.123908997,
  -151.278182983, -122.461021423,
  -156.952880859, -124.634231567,
  -161.102722168, -123.874809265,
  -165.569396973, -123.018455505,
  -169.705856323, -122.189208984,
  -174.1743927, -118.071311951,
  -175.338165283, -114.01625061,
  -174.882263184, -114.446472168,
  -178.997894287, -113.519233704,
  -183.367401123, -109.296394348,
  -184.434539795, -105.214828491,
  -185.547866821, -100.805175781,
  -186.546081543, -96.706199646,
  -184.704772949, -90.9153060913,
  -181.522567749, -88.1455612183,
  -168.26071167, -101.315071106,
  -171.008132935, -104.516571045,
  -176.786026001, -106.398284912,
  -180.891876221, -105.428749084,
  -185.309188843, -104.3462677,
  -189.398117065, -103.307662964,
  -193.651397705, -98.9677810669,
  -194.60736084, -94.858757019,
  -194.173950195, -95.3116226196,
  -198.237060547, -94.1762084961,
  -202.38609314, -89.7365570068,
  -203.244216919, -85.6059875488,
  -204.131774902, -81.145401001,
  -204.920166016, -77.0009613037,
  -202.786651611, -71.3112335205,
  -199.467666626, -68.7069625854,
  -197.890533447, -77.4080581665,
  -201.91242981, -76.1343841553,
  -205.907196045, -71.5554428101,
  -206.623580933, -67.3979415894,
  -207.358078003, -62.9096221924,
  -208.004302979, -58.7406387329,
  -205.677474976, -53.1271972656,
  -202.271377563, -50.6379394531,
  -202.518249512, -51.2141075134,
  -203.065261841, -47.030960083,
  -200.605789185, -41.4743499756,
  -197.141540527, -39.0666847229,
  -193.385162354, -36.5026664734,
  -189.880813599, -34.1537284851,
  -183.810058594, -33.8877944946,
  -180.113739014, -35.9213180542,
  -176.214050293, -63.7047576904,
  -179.207717896, -66.6772842407,
  -185.117111206, -68.0928955078,
  -189.132705688, -66.7994384766,
  -193.449798584, -65.3686141968,
  -197.443023682, -64.0076675415,
  -201.337188721, -59.3428497314,
  -201.962890625, -55.1707496643,
  -201.56690979, -55.6566772461,
  -205.526702881, -54.2013053894,
  -209.308944702, -49.4453353882,
  -209.83543396, -45.2595405579,
  -210.364929199, -40.7424507141,
  -210.820755005, -36.5483779907,
  -208.240905762, -31.0466308594,
  -204.725036621, -28.7149486542,
  -203.845855713, -37.5140075684,
  -207.753555298, -35.9240760803,
  -211.370986938, -31.0415477753,
  -211.753997803, -26.8402042389,
  -212.128738403, -22.307641983,
  -212.440887451, -18.1004390717,
  -209.674407959, -12.6901302338,
  -206.080886841, -10.4800367355,
  -206.372848511, -11.0347156525,
  -206.585006714, -6.8212852478,
  -203.690856934, -1.47819244862,
  -200.045852661, 0.645945012569,
  -196.097229004, 2.90266728401,
  -192.416946411, 4.96507072449,
  -186.344284058, 4.74669075012,
  -182.821640015, 2.42526221275,
  -191.945541382, -13.8863153458,
  -195.767318726, -12.099647522,
  -199.131652832, -7.03941631317,
  -199.300445557, -2.82402586937,
  -199.444107056, 1.72172760963,
  -199.5418396, 5.93936252594,
  -196.503707886, 11.2019319534,
  -192.802398682, 13.2263536453,
  -193.1222229, 12.68724823,
  -193.119766235, 16.9060173035,
  -189.95753479, 22.0949573517,
  -186.209182739, 24.030916214,
  -182.150848389, 26.0838413239,
  -178.370407104, 27.9563484192,
  -172.316726685, 27.4293251038,
  -168.916748047, 24.9316997528,
  -176.874603271, 21.0756511688,
  -176.727874756, 25.2918663025,
  -173.390060425, 30.369638443,
  -169.577697754, 32.1762886047,
  -165.451553345, 34.0892295837,
  -161.609313965, 35.8313751221,
  -155.577178955, 35.0976486206,
  -152.264587402, 32.4852180481,
  -152.885894775, 32.5682907104,
  -149.003341675, 34.2187004089,
  -142.990341187, 33.3419418335,
  -139.740737915, 30.6515884399,
  -136.262054443, 27.7218437195,
  -133.058258057, 24.9771022797,
  -131.171676636, 19.2008056641,
  -132.137756348, 15.0941419601,
  -132.137756348, -15.0941419601,
  -131.171676636, -19.2008056641,
  -133.058258057, -24.9771022797,
  -136.262054443, -27.7218437195,
  -139.740737915, -30.6515884399,
  -142.990341187, -33.3419418335,
  -149.003341675, -34.2187004089,
  -152.885894775, -32.5682907104,
  -152.264587402, -32.4852180481,
  -155.577178955, -35.0976486206,
  -161.609313965, -35.8313751221,
  -165.451553345, -34.0892295837,
  -169.577697754, -32.1762886047,
  -173.390060425, -30.369638443,
  -176.727874756, -25.2918663025,
  -176.874603271, -21.0756511688,
  -168.916748047, -24.9316997528,
  -172.316726685, -27.4293251038,
  -178.370407104, -27.9563484192,
  -182.150848389, -26.0838413239,
  -186.209182739, -24.030916214,
  -189.95753479, -22.0949573517,
  -193.119766235, -16.9060173035,
  -193.1222229, -12.68724823,
  -192.802398682, -13.2263536453,
  -196.503707886, -11.2019319534,
  -199.5418396, -5.93936252594,
  -199.444107056, -1.72172760963,
  -199.300445557, 2.82402586937,
  -199.131652832, 7.03941631317,
  -195.767318726, 12.099647522,
  -191.945541382, 13.8863153458,
  -182.821640015, -2.42526221275,
  -186.344284058, -4.74669075012,
  -192.416946411, -4.96507072449,
  -196.097229004, -2.90266728401,
  -200.045852661, -0.645945012569,
  -203.690856934, 1.47819244862,
  -206.585006714, 6.8212852478,
  -206.372848511, 11.0347156525,
  -206.080886841, 10.4800367355,
  -209.674407959, 12.6901302338,
  -212.440887451, 18.1004390717,
  -212.128738403, 22.307641983,
  -211.753997803, 26.8402042389,
  -211.370986938, 31.0415477753,
  -207.753555298, 35.9240760803,
  -203.845855713, 37.5140075684,
  -204.725036621, 28.7149486542,
  -208.240905762, 31.0466308594,
  -210.820755005, 36.5483779907,
  -210.364929199, 40.7424507141,
  -209.83543396, 45.2595405579,
  -209.308944702, 49.4453353882,
  -205.526702881, 54.2013053894,
  -201.56690979, 55.6566772461,
  -201.962890625, 55.1707496643,
  -201.337188721, 59.3428497314,
  -197.443023682, 64.0076675415,
  -193.449798584, 65.3686141968,
  -189.132705688, 66.7994384766,
  -185.117111206, 68.0928955078,
  -179.207717896, 66.6772842407,
  -176.214050293, 63.7047576904,
  -180.113739014, 35.9213180542,
  -183.810058594, 33.8877944946,
  -189.880813599, 34.1537284851,
  -193.385162354, 36.5026664734,
  -197.141540527, 39.0666847229,
  -200.605789185, 41.4743499756,
  -203.065261841, 47.030960083,
  -202.518249512, 51.2141075134,
  -202.271377563, 50.6379394531,
  -205.677474976, 53.1271972656,
  -208.004302979, 58.7406387329,
  -207.358078003, 62.9096221924,
  -206.623580933, 67.3979415894,
  -205.907196045, 71.5554428101,
  -201.91242981, 76.1343841553,
  -197.890533447, 77.4080581665,
  -199.467666626, 68.7069625854,
  -202.786651611, 71.3112335205,
  -204.920166016, 77.0009613037,
  -204.131774902, 81.145401001,
  -203.244216919, 85.6059875488,
  -202.38609314, 89.7365570068,
  -198.237060547, 94.1762084961,
  -194.173950195, 95.3116226196,
  -194.60736084, 94.858757019,
  -193.651397705, 98.9677810669,
  -189.398117065, 103.307662964,
  -185.309188843, 104.3462677,
  -180.891876221, 105.428749084,
  -176.786026001, 106.398284912,
  -171.008132935, 104.516571045,
  -168.26071167, 101.315071106,
  -181.522567749, 88.1455612183,
  -184.704772949, 90.9153060913,
  -186.546081543, 96.706199646,
  -185.547866821, 100.805175781,
  -184.434539795, 105.214828491,
  -183.367401123, 109.296394348,
  -178.997894287, 113.519233704,
  -174.882263184, 114.446472168,
  -175.338165283, 114.01625061,
  -174.1743927, 118.071311951,
  -169.705856323, 122.189208984,
  -165.569396973, 123.018455505,
  -161.102722168, 123.874809265,
  -156.952880859, 124.634231567,
  -151.278182983, 122.461021423,
  -148.697158813, 119.123908997,
  -157.409088135, 117.60760498,
  -156.107315063, 121.620513916,
  -151.500564575, 125.583190918,
  -147.338180542, 126.270515442,
  -142.844818115, 126.973625183,
  -138.671447754, 127.590698242,
  -133.074401855, 125.224693298,
  -130.60899353, 121.801269531,
  -131.183425903, 122.052185059,
  -126.996551514, 122.569969177,
  -121.457275391, 120.071739197,
  -119.073860168, 116.59072876,
  -116.536148071, 112.81653595,
  -114.211746216, 109.295852661,
  -113.988250732, 103.223396301,
  -116.047554016, 99.5413665771,
  -141.6822052, 77.3411331177,
  -145.620635986, 75.828956604,
  -151.598907471, 76.9176635742,
  -154.751403809, 79.7211685181,
  -158.124359131, 82.772026062,
  -161.229171753, 85.6282653809,
  -162.910461426, 91.4676208496,
  -161.799850464, 95.5375823975,
  -161.633590698, 94.9331970215,
  -164.669708252, 97.8623580933,
  -166.211837769, 103.740005493,
  -165.00491333, 107.782440186,
  -163.66708374, 112.129241943,
  -162.392196655, 116.150779724,
  -157.812042236, 120.144165039,
  -153.654327393, 120.859298706,
  -156.399658203, 112.453361511,
  -159.333831787, 115.484642029,
  -160.674072266, 121.411575317,
  -159.329605103, 125.410369873,
  -157.843933105, 129.708908081,
  -156.432250977, 133.684463501,
  -151.71824646, 137.51890564,
  -147.538528442, 138.091445923,
  -148.029464722, 137.701721191,
  -146.523773193, 141.64263916,
  -141.720046997, 145.364059448,
  -137.527893066, 145.837188721,
  -133.004425049, 146.309143066,
  -128.804901123, 146.711547852,
  -123.336433411, 144.061859131,
  -121.049697876, 140.516601562,
  -135.97869873, 129.272109985,
  -138.754867554, 132.448730469,
  -139.791854858, 138.436172485,
  -138.24571228, 142.361404419,
  -136.543304443, 146.578781128,
  -134.931228638, 150.477386475,
  -130.02822876, 154.067047119,
  -125.824783325, 154.426223755,
  -126.334907532, 154.061935425,
  -124.630706787, 157.921173096,
  -119.643867493, 161.3934021,
  -115.433067322, 161.652648926,
  -110.891456604, 161.893875122,
  -106.676887512, 162.082138062,
  -101.35030365, 159.157669067,
  -99.246887207, 155.500686646,
  -108.08404541, 155.182754517,
  -106.248855591, 158.981445312,
  -101.146217346, 162.28112793,
  -96.9290237427, 162.396224976,
  -92.3818054199, 162.481994629,
  -88.163269043, 162.526000977,
  -82.9398117065, 159.421142578,
  -80.9626693726, 155.694366455,
  -81.4976577759, 156.021011353,
  -77.279258728, 155.964859009,
  -72.1310043335, 152.736816406,
  -70.2429199219, 148.964111328,
  -68.2418212891, 144.879989624,
  -66.4176025391, 141.076019287,
  -67.0216522217, 135.029541016,
  -69.5623550415, 131.661621094,
  -97.6179122925, 131.771011353,
  -100.132278442, 135.158615112,
  -100.689178467, 141.209625244,
  -98.8353347778, 144.999252319,
  -96.8024673462, 149.067672729,
  -94.8850250244, 152.825531006,
  -89.7117385864, 156.013305664,
  -85.4930343628, 156.036590576,
  -86.0305557251, 155.714111328,
  -84.0244216919, 159.425354004,
  -78.7769088745, 162.4894104,
  -74.5588378906, 162.412506104,
  -70.0124359131, 162.291275024,
  -65.7962646484, 162.143295288,
  -60.719493866, 158.803924561,
  -58.9139938354, 154.991027832,
  -67.7484130859, 155.377883911,
  -65.6165390015, 159.018356323,
  -60.2673149109, 161.901199341,
  -56.0543441772, 161.680084229,
  -51.5147399902, 161.403442383,
  -47.3060951233, 161.111358643,
  -42.3464813232, 157.600341797,
  -40.6724205017, 153.72795105,
  -41.1797027588, 154.096206665,
  -36.9791793823, 153.704269409,
  -32.1043434143, 150.07649231,
  -30.5227050781, 146.165420532,
  -28.8532180786, 141.934890747,
  -27.3377246857, 137.99772644,
  -28.4213867188, 132.018554688,
  -31.2222423553, 128.863677979,
  -46.0631141663, 140.224227905,
  -43.7488059998, 143.751541138,
  -38.2598495483, 146.358505249,
  -34.063583374, 145.923370361,
  -29.5439300537, 145.416168213,
  -25.3555927277, 144.910354614,
  -20.5810146332, 141.151580811,
  -19.1061172485, 137.199050903,
  -19.5940036774, 137.592605591,
  -15.4188594818, 136.987503052,
  -10.7348852158, 133.116409302,
  -9.35426044464, 129.129959106,
  -7.90215444565, 124.819976807,
  -6.58891248703, 120.810806274,
  -7.97534418106, 114.894508362,
  -10.9330644608, 111.886199951,
  -13.612736702, 120.313293457,
  -9.4607257843, 119.565765381,
  -4.91186571121, 115.536773682,
  -3.66836738586, 111.505439758,
  -2.36449193954, 107.1483078,
  -1.18911325932, 103.096595764,
  -2.77704501152, 97.2311553955,
  -5.83590650558, 94.3257522583,
  -5.6649274826, 94.928817749,
  -4.58609294891, 90.8503265381,
  -6.31285858154, 85.0242462158,
  -9.43984889984, 82.1922912598,
  -12.8364973068, 79.1678543091,
  -16.0107727051, 76.3890075684,
  -21.9973392487, 75.3469619751,
  -25.9238681793, 76.8898010254,
  25.9238700867, 76.8898010254,
  21.9973392487, 75.3469619751,
  16.0107746124, 76.3890075684,
  12.8365001678, 79.1678543091,
  9.43985080719, 82.1922912598,
  6.31286096573, 85.0242462158,
  4.58609437943, 90.8503265381,
  5.66492843628, 94.928817749,
  5.83590555191, 94.3257522583,
  2.77704405785, 97.2311553955,
  1.18911266327, 103.096595764,
  2.36449098587, 107.1483078,
  3.66836738586, 111.505439758,
  4.91186571121, 115.536773682,
  9.4607257843, 119.565765381,
  13.612736702, 120.313293457,
  10.9330644608, 111.886199951,
  7.97534418106, 114.894508362,
  6.58891248703, 120.810806274,
  7.90215444565, 124.819976807,
  9.35426044464, 129.129959106,
  10.7348852158, 133.116409302,
  15.4188594818, 136.987503052,
  19.5940036774, 137.592605591,
  19.1061172485, 137.199050903,
  20.5810146332, 141.151580811,
  25.3555927277, 144.910354614,
  29.5439300537, 145.416168213,
  34.063583374, 145.923370361,
  38.2598495483, 146.358505249,
  43.7488059998, 143.751541138,
  46.0631141663, 140.224227905,
  31.22224617, 128.863677979,
  28.4213943481, 132.018554688,
  27.3377304077, 137.99772644,
  28.8532276154, 141.934890747,
  30.5227127075, 146.165420532,
  32.104347229, 150.07649231,
  36.9791870117, 153.704269409,
  41.1797103882, 154.096206665,
  40.6724319458, 153.727966309,
  42.3464927673, 157.600372314,
  47.3061065674, 161.11138916,
  51.5147476196, 161.403457642,
  56.0543518066, 161.680084229,
  60.2673187256, 161.901199341,
  65.6165466309, 159.018356323,
  67.7484130859, 155.377883911,
  58.9139938354, 154.991027832,
  60.719493866, 158.803924561,
  65.7962722778, 162.143295288,
  70.0124435425, 162.291275024,
  74.55884552, 162.412506104,
  78.7769165039, 162.4894104,
  84.0244293213, 159.425354004,
  86.0305709839, 155.714111328,
  85.4930496216, 156.036590576,
  89.7117462158, 156.013305664,
  94.8850250244, 152.825515747,
  96.8024749756, 149.067657471,
  98.8353424072, 144.999252319,
  100.689178467, 141.209625244,
  100.132286072, 135.158615112,
  97.6179199219, 131.771011353,
  69.5623550415, 131.661621094,
  67.0216522217, 135.029525757,
  66.4176025391, 141.07598877,
  68.2418212891, 144.879959106,
  70.2429199219, 148.964096069,
  72.1310043335, 152.736785889,
  77.279258728, 155.964828491,
  81.4976577759, 156.021011353,
  80.9626693726, 155.694366455,
  82.9398117065, 159.421142578,
  88.163269043, 162.526000977,
  92.3818054199, 162.481994629,
  96.9290237427, 162.396209717,
  101.146217346, 162.281097412,
  106.248855591, 158.981414795,
  108.08404541, 155.182723999,
  99.2469024658, 155.500686646,
  101.350311279, 159.157669067,
  106.676902771, 162.082138062,
  110.891464233, 161.893875122,
  115.433082581, 161.652648926,
  119.643875122, 161.3934021,
  124.630714417, 157.921173096,
  126.33493042, 154.061935425,
  125.824783325, 154.426208496,
  130.02822876, 154.067016602,
  134.931228638, 150.477355957,
  136.543304443, 146.578765869,
  138.24571228, 142.36138916,
  139.791854858, 138.436157227,
  138.754867554, 132.44871521,
  135.97869873, 129.272094727,
  121.049697876, 140.516586304,
  123.336433411, 144.061843872,
  128.804901123, 146.711532593,
  133.004425049, 146.309127808,
  137.527893066, 145.837188721,
  141.720046997, 145.364059448,
  146.523773193, 141.64263916,
  148.029464722, 137.701690674,
  147.538528442, 138.091445923,
  151.71824646, 137.518890381,
  156.432250977, 133.684463501,
  157.843933105, 129.708877563,
  159.329605103, 125.410354614,
  160.674072266, 121.4115448,
  159.333831787, 115.48462677,
  156.399642944, 112.453346252,
  153.654327393, 120.859283447,
  157.812057495, 120.14415741,
  162.392196655, 116.150749207,
  163.66708374, 112.129226685,
  165.00491333, 107.782424927,
  166.211837769, 103.739990234,
  164.669708252, 97.8623504639,
  161.633590698, 94.9331893921,
  161.799850464, 95.5375747681,
  162.910461426, 91.4676132202,
  161.229171753, 85.6282577515,
  158.124359131, 82.7720184326,
  154.751403809, 79.7211608887,
  151.598907471, 76.9176559448,
  145.620635986, 75.8289489746,
  141.6822052, 77.3411178589,
  116.047554016, 99.5413665771,
  113.988250732, 103.223396301,
  114.211746216, 109.295852661,
  116.536148071, 112.81653595,
  119.073860168, 116.590744019,
  121.457275391, 120.071746826,
  126.996551514, 122.569969177,
  131.183425903, 122.0521698,
  130.60899353, 121.801254272,
  133.074401855, 125.224693298,
  138.671447754, 127.590682983,
  142.844833374, 126.973609924,
  147.338180542, 126.270492554,
  151.500564575, 125.583175659,
  156.107315063, 121.620491028,
  157.409088135, 117.607589722,
  148.697158813, 119.123878479,
  151.278182983, 122.461006165,
  156.952880859, 124.634216309,
  161.102722168, 123.874801636,
  165.569396973, 123.018440247,
  169.705856323, 122.189186096,
  174.1743927, 118.071304321,
  175.338165283, 114.016220093,
  174.882263184, 114.446464539,
  178.997894287, 113.519226074,
  183.367401123, 109.29637146,
  184.434539795, 105.214805603,
  185.547866821, 100.805160522,
  186.546081543, 96.7061843872,
  184.704772949, 90.9152908325,
  181.522567749, 88.1455459595,
  168.26071167, 101.315048218,
  171.008132935, 104.516548157,
  176.786026001, 106.398262024,
  180.891876221, 105.428726196,
  185.309188843, 104.346260071,
  189.398117065, 103.307647705,
  193.651397705, 98.9677658081,
  194.60736084, 94.8587417603,
  194.173950195, 95.3116073608,
  198.237060547, 94.1761932373,
  202.38609314, 89.7365341187,
  203.244216919, 85.60597229,
  204.131774902, 81.1453933716,
  204.920166016, 77.0009384155,
  202.786651611, 71.3112182617,
  199.467666626, 68.7069473267,
  197.890533447, 77.4080429077,
  201.91242981, 76.1343612671,
  205.907196045, 71.5554275513,
  206.623580933, 67.3979263306,
  207.358078003, 62.9096031189,
  208.004302979, 58.7406234741,
  205.677474976, 53.1271781921,
  202.271377563, 50.6379241943,
  202.518249512, 51.2140922546,
  203.065261841, 47.0309410095,
  200.605789185, 41.4743309021,
  197.141540527, 39.0666656494,
  193.385162354, 36.5026435852,
  189.880813599, 34.1537055969,
  183.810058594, 33.8877792358,
  180.113739014, 35.9213027954,
  176.214050293, 63.7047386169,
  179.207717896, 66.6772689819,
  185.117111206, 68.0928649902,
  189.132705688, 66.7994232178,
  193.449798584, 65.3685913086,
  197.443023682, 64.0076522827,
  201.337188721, 59.3428344727,
  201.962890625, 55.1707229614,
  201.56690979, 55.656665802,
  205.526702881, 54.2012901306,
  209.308944702, 49.4453048706,
  209.83543396, 45.2595176697,
  210.364929199, 40.7424278259,
  210.820755005, 36.5483551025,
  208.240905762, 31.0466079712,
  204.725036621, 28.7149276733,
  203.845855713, 37.5139961243,
  207.753555298, 35.9240608215,
  211.370986938, 31.0415325165,
  211.753997803, 26.8401889801,
  212.128738403, 22.3076267242,
  212.440887451, 18.1004238129,
  209.674407959, 12.690112114,
  206.080886841, 10.4800195694,
  206.372848511, 11.0346975327,
  206.585006714, 6.8212685585,
  203.690856934, 1.47817611694,
  200.045852661, -0.645960509777,
  196.097229004, -2.90268015862,
  192.416931152, -4.96508407593,
  186.344284058, -4.74670314789,
  182.821640015, -2.42527389526,
  191.945541382, 13.8863124847,
  195.767318726, 12.0996456146,
  199.131652832, 7.03941392899,
  199.300445557, 2.82402420044,
  199.444107056, -1.72173130512,
  199.5418396, -5.93936681747,
  196.503707886, -11.2019357681,
  192.802398682, -13.22635746,
  193.1222229, -12.687251091,
  193.119766235, -16.9060173035,
  189.95753479, -22.094959259,
  186.209182739, -24.0309181213,
  182.150848389, -26.0838451385,
  178.370407104, -27.9563560486,
  172.316726685, -27.4293289185,
  168.916748047, -24.9317054749,
  176.874603271, -21.0756568909,
  176.727874756, -25.2918720245,
  173.390060425, -30.3696403503,
  169.577697754, -32.1762886047,
  165.451553345, -34.0892410278,
  161.609313965, -35.8313827515,
  155.577178955, -35.0976524353,
  152.264587402, -32.4852256775,
  152.885894775, -32.5682983398,
  149.003341675, -34.2187080383,
  142.990341187, -33.3419494629,
  139.740737915, -30.651594162,
  136.262054443, -27.7218475342,
  133.058258057, -24.9771060944,
  131.171676636, -19.2008094788,
  132.137756348, -15.0941467285,
  132.137786865, 15.0941267014,
  131.171707153, 19.2007904053,
  133.058303833, 24.9770889282,
  136.26210022, 27.7218284607,
  139.740768433, 30.6515731812,
  142.990386963, 33.3419265747,
  149.003387451, 34.2186813354,
  152.885940552, 32.5682678223,
  152.264633179, 32.4851989746,
  155.577224731, 35.0976295471,
  161.609344482, 35.8313560486,
  165.451583862, 34.089214325,
  169.57774353, 32.1762657166,
  173.390090942, 30.3696136475,
  176.727905273, 25.2918395996,
  176.874633789, 21.0756225586,
  168.916778564, 24.9316806793,
  172.316772461, 27.4293041229,
  178.370452881, 27.9563312531,
  182.150894165, 26.083820343,
  186.209228516, 24.0308914185,
  189.957565308, 22.0949325562,
  193.119812012, 16.9059906006,
  193.122268677, 12.6872224808,
  192.80241394, 13.2263278961,
  196.503723145, 11.2019042969,
  199.541854858, 5.93933391571,
  199.444137573, 1.72169852257,
  199.300476074, -2.82405471802,
  199.131668091, -7.03944396973,
  195.767333984, -12.0996751785,
  191.945571899, -13.8863430023,
  182.821655273, 2.42523956299,
  186.344299316, 4.74666881561,
  192.41696167, 4.96504688263,
  196.097244263, 2.90264344215,
  200.04586792, 0.645920753479,
  203.690887451, -1.47821724415,
  206.585021973, -6.82131099701,
  206.372879028, -11.0347394943,
  206.080917358, -10.4800634384,
  209.674438477, -12.6901569366,
  212.440917969, -18.1004676819,
  212.128768921, -22.3076705933,
  211.75402832, -26.8402309418,
  211.371002197, -31.0415725708,
  207.753570557, -35.9241027832,
  203.845855713, -37.5140380859,
  204.725036621, -28.7149715424,
  208.240905762, -31.0466537476,
  210.820755005, -36.5484046936,
  210.364929199, -40.7424697876,
  209.83543396, -45.2595672607,
  209.308944702, -49.4453620911,
  205.526702881, -54.2013320923,
  201.56690979, -55.6567077637,
  201.962875366, -55.1707725525,
  201.337173462, -59.342880249,
  197.443023682, -64.0076904297,
  193.449798584, -65.3686294556,
  189.132705688, -66.7994613647,
  185.117111206, -68.0929031372,
  179.207717896, -66.6772994995,
  176.214050293, -63.7047767639,
  180.113739014, -35.9213409424,
  183.810058594, -33.8878211975,
  189.880813599, -34.153755188,
  193.385162354, -36.502696991,
  197.141540527, -39.0667076111,
  200.605789185, -41.4743804932,
  203.065261841, -47.0309906006,
  202.518249512, -51.214138031,
  202.271362305, -50.6379699707,
  205.677459717, -53.1272201538,
  208.004272461, -58.7406692505,
  207.358078003, -62.90965271,
  206.623550415, -67.3979721069,
  205.907180786, -71.5554656982,
  201.912414551, -76.1344070435,
  197.89050293, -77.4080886841,
  199.467666626, -68.7070007324,
  202.786651611, -71.3112716675,
  204.920166016, -77.0009994507,
  204.131774902, -81.1454391479,
  203.24420166, -85.6060256958,
  202.386062622, -89.7365951538,
  198.237045288, -94.1762466431,
  194.173950195, -95.3116531372,
  194.607345581, -94.858795166,
  193.651382446, -98.9678192139,
  189.398101807, -103.307701111,
  185.309158325, -104.346305847,
  180.891845703, -105.428787231,
  176.786010742, -106.398323059,
  171.008132935, -104.516609192,
  168.260696411, -101.315109253,
  181.522567749, -88.1455917358,
  184.704772949, -90.9153366089,
  186.546081543, -96.7062301636,
  185.547866821, -100.805198669,
  184.434539795, -105.21484375,
  183.367401123, -109.296409607,
  178.99786377, -113.519264221,
  174.882263184, -114.446487427,
  175.338165283, -114.01625824,
  174.1743927, -118.071342468,
  169.705856323, -122.189224243,
  165.569381714, -123.018478394,
  161.102722168, -123.874824524,
  156.952880859, -124.634239197,
  151.278182983, -122.461036682,
  148.697158813, -119.123916626,
  157.409088135, -117.607627869,
  156.107315063, -121.620529175,
  151.500564575, -125.583213806,
  147.338180542, -126.270530701,
  142.844818115, -126.973648071,
  138.671447754, -127.590705872,
  133.074401855, -125.224716187,
  130.60899353, -121.80128479,
  131.183425903, -122.052192688,
  126.996551514, -122.569992065,
  121.457275391, -120.071746826,
  119.073860168, -116.590751648,
  116.536148071, -112.816558838,
  114.211746216, -109.295875549,
  113.988250732, -103.22341156,
  116.047554016, -99.5413818359,
  141.682189941, -77.3411331177,
  145.620620728, -75.828956604,
  151.598876953, -76.9176635742,
  154.75138855, -79.7211685181,
  158.124359131, -82.772026062,
  161.229171753, -85.6282653809,
  162.910461426, -91.467628479,
  161.799850464, -95.5375823975,
  161.633590698, -94.9332046509,
  164.669708252, -97.8623657227,
  166.211837769, -103.740005493,
  165.00491333, -107.782447815,
  163.66708374, -112.129257202,
  162.392196655, -116.150779724,
  157.812042236, -120.144165039,
  153.654327393, -120.859298706,
  156.399658203, -112.453361511,
  159.333831787, -115.484642029,
  160.674072266, -121.411575317,
  159.329605103, -125.410369873,
  157.843933105, -129.708908081,
  156.432250977, -133.684463501,
  151.71824646, -137.51890564,
  147.538528442, -138.091445923,
  148.029464722, -137.701721191,
  146.523773193, -141.64263916,
  141.720031738, -145.364059448,
  137.527877808, -145.837188721,
  133.004425049, -146.309127808,
  128.804901123, -146.711532593,
  123.336433411, -144.061859131,
  121.049697876, -140.516586304,
  135.97869873, -129.272109985,
  138.754867554, -132.448730469,
  139.791854858, -138.436172485,
  138.24571228, -142.361404419,
  136.543304443, -146.578781128,
  134.931228638, -150.477386475,
  130.02822876, -154.067047119,
  125.824783325, -154.426223755,
  126.334907532, -154.061935425,
  124.630706787, -157.921173096,
  119.643867493, -161.3934021,
  115.433067322, -161.652648926,
  110.891456604, -161.893875122,
  106.676887512, -162.082138062,
  101.35030365, -159.157669067,
  99.246887207, -155.500686646,
  108.08404541, -155.182723999,
  106.248855591, -158.981414795,
  101.146202087, -162.281097412,
  96.9290084839, -162.396209717,
  92.3817901611, -162.481994629,
  88.1632537842, -162.526000977,
  82.9397888184, -159.421142578,
  80.9626464844, -155.694366455,
  81.4976425171, -156.021011353,
  77.2792510986, -155.964828491,
  72.1309967041, -152.736785889,
  70.2429122925, -148.964096069,
  68.2418212891, -144.879974365,
  66.4175872803, -141.076004028,
  67.0216445923, -135.029525757,
  69.5623550415, -131.661621094,
  97.6179046631, -131.771011353,
  100.132270813, -135.158615112,
  100.689163208, -141.209625244,
  98.835319519, -144.999252319,
  96.8024520874, -149.067672729,
  94.8850097656, -152.825531006,
  89.711730957, -156.013305664,
  85.4930343628, -156.036590576,
  86.0305480957, -155.714111328,
  84.0244140625, -159.425354004,
  78.7769012451, -162.4894104,
  74.5588302612, -162.412506104,
  70.0124206543, -162.291275024,
  65.7962493896, -162.143295288,
  60.7194824219, -158.803924561,
  58.9139785767, -154.991027832,
  67.7483825684, -155.377883911,
  65.6165161133, -159.018356323,
  60.2672958374, -161.901199341,
  56.0543251038, -161.680084229,
  51.5147209167, -161.403442383,
  47.3060760498, -161.111358643,
  42.3464660645, -157.600341797,
  40.6724014282, -153.72795105,
  41.1796836853, -154.096176147,
  36.9791603088, -153.70425415,
  32.1043243408, -150.076477051,
  30.522687912, -146.165405273,
  28.8532066345, -141.934890747,
  27.3377132416, -137.997711182,
  28.4213752747, -132.018539429,
  31.2222270966, -128.86366272,
  46.0630950928, -140.224227905,
  43.7487869263, -143.751541138,
  38.2598342896, -146.358505249,
  34.0635643005, -145.923370361,
  29.5439109802, -145.416168213,
  25.3555755615, -144.910354614,
  20.580997467, -141.151580811,
  19.106098175, -137.199050903,
  19.5939865112, -137.592605591,
  15.4188404083, -136.987503052,
  10.7348670959, -133.116409302,
  9.35424232483, -129.129959106,
  7.90213680267, -124.819976807,
  6.58889484406, -120.810806274,
  7.97532653809, -114.894508362,
  10.9330472946, -111.886199951,
  13.6127138138, -120.313278198,
  9.46070384979, -119.565734863,
  4.91184425354, -115.536766052,
  3.66834664345, -111.50541687,
  2.36447024345, -107.1483078,
  1.18909263611, -103.096580505,
  2.77702474594, -97.2311477661,
  5.83588647842, -94.3257446289,
  5.66491222382, -94.928817749,
  4.58607721329, -90.8503265381,
  6.31284332275, -85.0242462158,
  9.43983364105, -82.1922912598,
  12.836482048, -79.1678543091,
  16.0107574463, -76.3890075684,
  21.9973239899, -75.3469619751,
  25.9238529205, -76.8898010254
]
